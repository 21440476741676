import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddonForUI, Cart, PaymentMethod, Product } from '@common-models';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { OrderService } from '@core/order/order.service';
import { AddonService } from '@core/addon/addon.service';
import { FinanceService } from '@core/finance/finance.service';
import { SwiftypeService } from '@core/swiftype/swiftype.service';
import { CartService } from '@core/cart/cart.service';
import { EMPTY, first, map, Observable, switchMap, tap } from 'rxjs';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EventListService {
    constructor(
        private addonService: AddonService,
        private analyticsService: AnalyticsService,
        private financeService: FinanceService,
        private orderService: OrderService,
        private swiftTypeService: SwiftypeService,
        private cartService: CartService,
    ) {}

    /**
     * TODO: Come up with something better:
     * https://enervee.atlassian.net/browse/APP-14506
     */
    private get cart$(): Observable<Cart> {
        return this.cartService.cart$.pipe(first());
    }

    sendPlaceOrderClickedEvent(cartId?: string, paymentMethod?: string): void {
        // TODO: pass swiftype cartItems
        this.analyticsService.reportEvent('Place Order Clicked', {
            cart_id: cartId,
            transaction_type: paymentMethod,
        });
    }

    /**
     * Determines which success event to send and formats the event data
     * params included with the event.
     */
    sendPlaceOrderSuccessEvent(cartId: string): void {
        const order = this.orderService.order;
        let eventName: string;
        if (order.payment_method === null) {
            eventName = 'Place Order Success';
        } else if (order.payment_method === PaymentMethod.finance) {
            eventName = 'Finance Place Order Success';
        } else {
            eventName = 'Credit Card Place Order Success';
        }
        const orderData = {
            cart_id: cartId,
            order_status: order.status,
            enervee_order_id: order.order_id,
            retailer_order_id: order.fulfillment_partner_id,
            purchase_date: order.purchase_date,
            transaction_type: order.payment_method,
            fees: order.fees,
            freight_cost: order.freight_cost,
            order_total: order.order_total,
            rebate_total: order.rebate_total,
            shipping_charge: order.shipping_charge,
            total_tax: order.total_tax,
        };
        // TODO: pass swiftype cartItems
        this.analyticsService.reportEvent(eventName, orderData);
    }

    /**
     * Sets the errorReason based on non_field_error or other response data,
     * provided with the event data params included in the event.
     */
    sendPlaceOrderFailedEvent(err: HttpErrorResponse, cartId: string): void {
        let errorReason: string;
        const EVENT_NAME = 'Place Order Failed';
        if (err.error?.non_field_errors) {
            errorReason = _.last(err.error.non_field_errors);
        } else if (err.error) {
            // Get the last value from the error object
            errorReason = _.last(_.values(err.error));
        } else {
            errorReason = 'undefined';
        }

        const eventData = {
            cart_id: cartId,
            reason_for_failure: errorReason,
        };
        // TODO: pass swiftype cartItems
        this.analyticsService.reportEvent(EVENT_NAME, eventData);
    }

    sendRetailerOrderLinkClickedEvent(link: string): void {
        this.analyticsService.reportEvent('Retailer Order Link Clicked', {
            retailer_name: this.orderService.order?.fulfillment_partner_name,
            retailer_link: link,
        });
    }

    /**
     * Reformats the addons from the UI data to provide them with the event
     * data params included with the event.
     */
    sendAddToCartEvent(products: Product[], addons?: AddonForUI[]) {
        const services = this.addonService.decoupleAddonsForUI(addons) ?? [];

        this.analyticsService.reportEvent(
            'Cart Engaged',
            {
                type_of_change: 'Add Product',
                services,
            },
            products,
        );
    }

    /**
     * Reformats the addons from the UI data to provide them with the event
     * data params included with the event.
     */
    sendOnAddonViewedEvent(addon: AddonForUI, product: Product): void {
        const services = this.addonService.decoupleAddonsForUI([addon]);

        this.analyticsService.reportEvent(
            'Additional Services Viewed',
            {
                services,
            },
            [product],
        );
    }

    /**
     * Formats and calculates the addon price data to provide it
     * with the event data params included with the event.
     */
    sendAdditionalServicesEngaged(
        addon: AddonForUI,
        checked: boolean,
        product: Product,
    ): void {
        this.analyticsService.reportEvent(
            'Additional Services Engaged',
            {
                checked,
                unchecked: !checked,
                service_name: addon.name,
                service_id: addon.service_id,
                price: {
                    monthly: this.financeService.calculateMonthlyPayment(
                        addon.price,
                    ),
                    total: addon.price,
                },
            },
            [product],
        );
    }

    sendContinueToPaymentClicked(
        paymentMethod: PaymentMethod,
        cartId: string,
    ): void {
        this.analyticsService.reportEvent(
            'Continue to Payment Clicked',
            {
                payment_option_name: paymentMethod,
                cart_id: cartId,
            },
            this.swiftTypeService.cartProducts,
        );
    }

    sendDeliveryDatetimesChosen(cartId: string): void {
        this.analyticsService.reportEvent(
            'Delivery Datetimes Chosen',
            {
                cart_id: cartId,
            },
            this.swiftTypeService.cartProducts,
        );
    }

    sendQuantitySelectorClicked(chosenValue: string, product: Product): void {
        this.analyticsService.reportEvent(
            'Quantity Selector Engaged',
            {
                opened: true,
                quantity_chosen: chosenValue,
            },
            [product],
        );
    }

    sendPurchaseWithoutRebates(): void {
        this.analyticsService.reportEvent(
            'Purchase Without Rebates',
            {
                location: 'cart',
            },
            this.swiftTypeService.cartProducts,
        );
    }

    sendPaymentMethodClicked(
        paymentMethod: PaymentMethod,
        cartId: string,
    ): void {
        this.analyticsService.reportEvent(
            'Payment Method Clicked',
            {
                location: 'cart',
                payment_method: paymentMethod,
                cart_id: cartId,
            },
            this.swiftTypeService.cartProducts,
        );
    }

    /**
     * Get cart id from cart and then send tracking event.
     */
    sendCheckRebateEligibilityClicked(): Observable<null> {
        return this.cart$.pipe(
            tap((cart: Cart) => {
                this.analyticsService.reportEvent(
                    'Check Rebate Eligibility',
                    {
                        location: 'cart',
                        cart_id: cart.id,
                    },
                    this.swiftTypeService.cartProducts,
                );
            }),
            // change to null value since nothing should be getting the cart data from here
            // TODO: Look into alternative approaches: https://enervee.atlassian.net/browse/APP-14506
            map(() => null),
        );
    }

    sendReturnPolicyClicked(): void {
        this.analyticsService.reportEvent('Return Policy Clicked', {});
    }

    sendCreditCardSubmitClicked(): Observable<null> {
        return this.cart$.pipe(
            tap((cart: Cart) => {
                this.analyticsService.reportEvent(
                    'Submit Payment Clicked',
                    {
                        cart_id: cart.id,
                    },
                    this.swiftTypeService.cartProducts,
                );
            }),
            switchMap(() => EMPTY),
        );
    }

    sendCreditCardSuccess(): Observable<null> {
        return this.cart$.pipe(
            tap((cart: Cart) => {
                this.analyticsService.reportEvent(
                    'Credit Card Success',
                    {
                        cart_id: cart.id,
                    },
                    this.swiftTypeService.cartProducts,
                );
            }),
            switchMap(() => EMPTY),
        );
    }

    sendCreditCardFailed(reason: string): Observable<null> {
        return this.cart$.pipe(
            tap((cart: Cart) => {
                this.analyticsService.reportEvent(
                    'Credit Card Failed',
                    {
                        cart_id: cart.id,
                        reason_for_failure: reason,
                    },
                    this.swiftTypeService.cartProducts,
                );
            }),
            switchMap(() => EMPTY),
        );
    }

    sendModalEvent(
        modal: string,
        metadata: unknown,
        products: Product[],
    ): void {
        this.analyticsService.reportEvent(
            `${modal} Engaged`,
            metadata,
            products,
        );
    }

    /**
     * Triggers event for when the payment options are viewed
     */
    sendPaymentOptionViewedEvent(
        paymentMethods: PaymentMethod[],
        isFinanceable: boolean,
        maxAmountReached: boolean,
        cartId: string,
    ): void {
        this.analyticsService.reportEvent(
            'Payment Options Viewed',
            {
                payment_option_types: paymentMethods,
                payment_option_conditions: {
                    max_amount_reached: maxAmountReached,
                    is_cart_financeable: isFinanceable,
                },
                cart_id: cartId,
            },
            this.swiftTypeService.cartProducts,
        );
    }
}
