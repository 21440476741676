import { NgModule } from '@angular/core';
import { CartDetailsComponent } from './cart-details/cart-details.component';
import { CartPageComponent } from './cart-page.component';
import { WebappCommonModule } from '@enervee/webapp-common';
import { SharedModule } from '@shared-module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [CartDetailsComponent, CartPageComponent],
    imports: [CommonModule, WebappCommonModule, SharedModule],
})
export class CartPageModule {}
