import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { GeoService } from '@core/geo/geo.service';
import { ActivatedRoute } from '@angular/router';
import { Product } from '@common-models';
import { Modal } from 'bootstrap';
import { CartService } from '@core/cart/cart.service';

@Component({
    selector: 'edit-zipcode-modal',
    templateUrl: './edit-zipcode-modal.component.html',
})
export class EditZipcodeModalComponent implements OnInit, AfterViewInit {
    @ViewChild('form') zipcodeFormElement: NgForm;

    product: Product;
    zipcodeMetadata: Record<string, unknown>;

    zipcodeForm = new FormGroup({
        zipcode: new FormControl('', [
            Validators.required,
            // accepts the following formats: "12345" or "12345-6789"
            Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
        ]),
    });

    constructor(
        private geoService: GeoService,
        private route: ActivatedRoute,
        private cartService: CartService,
    ) {}

    ngOnInit() {
        const data = this.route.parent.snapshot.data;
        this.product = data.product as Product;
        this.zipcodeMetadata = {
            oldZipcode: this.geoService.zipcode,
            newZipcode: '',
        };
    }

    ngAfterViewInit(): void {
        const modal = document.getElementById('editZipcodeModal');
        const input = document.getElementById('edit-zipcode-input');
        modal.addEventListener('shown.bs.modal', () => {
            input.focus();
        });
    }

    /**
     * Submits the zipcodeForm when user clicks the submit button in the modal
     */
    onFormSubmit = (): void => {
        this.zipcodeFormElement.onSubmit(undefined);
    };

    /**
     * Submits the new zipcode to the geoService if the form is valid
     */
    onSubmit(): void {
        if (this.zipcodeForm.invalid) {
            return;
        }
        const modal = Modal.getInstance(
            document.getElementById('editZipcodeModal'),
        );
        const backdrop = document.querySelector('.modal-backdrop');
        backdrop.remove();
        modal.hide();

        const newZipcode = this.zipcodeForm.get('zipcode').value;
        this.zipcodeMetadata = {
            ...this.zipcodeMetadata,
            newZipcode,
        };

        this.geoService.zipcode = newZipcode;
        this.cartService.updateCart().subscribe();
        this.zipcodeFormElement.resetForm();
    }
}
