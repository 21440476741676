<div
    class="incentiveForm p-0"
    *ngIf="{
        incentiveFormUrl: incentiveFormUrl$ | async,
        iframeHeight: iframeHeight$ | async
    } as viewStates">
    <iframe
        *ngIf="viewStates.incentiveFormUrl; else incentiveFormLoader"
        cartAppIframeTracker
        (formComplete)="onFormComplete($event)"
        (iframeSizeChange)="onIframeSizeChange($event)"
        class="border rounded"
        scrolling="no"
        id="incentiveIframe"
        [src]="viewStates.incentiveFormUrl | safe"
        [style.height]="viewStates.iframeHeight"></iframe>
    <ng-template #incentiveFormLoader>
        <div class="d-flex justify-content-center text-muted mt-4 mb-4">
            <div
                data-eid="incentive-form-loader"
                class="spinner-border"
                role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </ng-template>
</div>
