import { CustomerData, OrderCustomerData } from './customer.model';
import { OrderLine } from './order-line.model';
import { Charge } from './charge.model';
import { PaymentMethod } from './cart/cart.model';

export interface BaseOrder {
    order_id: string;
    order_number?: string;
    cart_id: string;
    cart_charge: Charge;
    fulfillment_partner_id: number;
    fulfillment_partner_name: string;
    provider_id: number;
    purchase_date: string;
    shipping_charge: Charge;
    fees: string;
    installation_total: string;
    installation_date: string;
    subtotal: string;
    rebate_total: string;
    total_tax: number;
    order_total: string;
    units: Array<object>;
    shipping_status: string;
    status: string;
    tracking_number: string;
    tracking_url: string;
    freight_cost: string;
    shipped_at: string;
    payment_method: PaymentMethod;
    product_count: string;
    payment_details?: PaymentDetails;
    loan_details?: LoanDetails;
    lines: Array<OrderLine>;
}

export interface Order extends BaseOrder {
    customer: CustomerData;
}

export interface OrderResponse extends BaseOrder {
    customer: OrderCustomerData;
}

export class PaymentDetails {
    pre_approval_term_months: number;
    pre_approval_apr: string;
}

export class LoanDetails {
    term: number;
    interest_rate: string;
}

export interface OrderApprovalResponse {
    cart_id: string;
    payment_method: string;
    payment_valid: boolean;
}

export interface OrderApprovalPayload {
    application_id?: string;
}

export interface OrderConfirmationErrorResponse {
    detail?: string;
    code?: OrderConfirmationErrorCodes;
    non_field_errors?: string[];
}

export enum OrderConfirmationErrorCodes {
    CREDIT_CARD_AUTHORIZATION_FAILED = 'credit_card_authorize_failed',
    SKU_ID_NOT_DISPLAYABLE = 'sku_id_not_displayable',
    SKU_NOT_FOUND = 'sku_not_found',
    FULFILLMENT_ELIGIBILITY_OUT_OF_STOCK = 'fulfillment_eligibility_out_of_stock',
    ITEM_NOT_SELLABLE = 'item_not_sellable',
    NO_DELIVERY_TIMES_AVAILABLE = 'no_delivery_times_available',
    GENERIC = 'generic',
}
