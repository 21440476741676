import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

/**
 * Generic error wrapper.  Logs the specified message and
 * throws whatever error is passed into the callback.
 */
export const handleGenericHttpError = (
    errorMessage = 'An error has occurred',
): ((err: HttpErrorResponse) => Observable<never>) => {
    return (error: HttpErrorResponse) => {
        console.error(errorMessage);
        return throwError(() => error);
    };
};
