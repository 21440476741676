import { Location, DOCUMENT } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationExtras,
    Router,
} from '@angular/router';
import { SettingsService } from '@core/settings/settings.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private history: string[] = [];
    private popState = false;

    constructor(
        private router: Router,
        private location: Location,
        private settingsService: SettingsService,
        private injector: Injector,
    ) {
        this.startRouteHistory();
        this.location.subscribe((event) => {
            if (event.type === 'popstate') {
                this.popState = true;
            }
        });
    }

    /**
     * Get the window object from the document default view.
     */
    private get window(): Document['defaultView'] {
        return this.injector.get<Document>(DOCUMENT).defaultView;
    }

    get marketplaceUrl(): string {
        return this.settingsService.getSiteSetting(
            'product_api_base_url',
            '',
        ) as string;
    }

    /**
     * Navigation helper to reduce boilerplate(mostly in tests)
     */
    navigate = (commands: any[], extras?: NavigationExtras) => {
        extras = extras || {};
        extras = {
            ...extras,
            ...{
                queryParamsHandling: 'merge',
            },
        };
        this.router.navigate(commands, extras);
    };

    /**
     * Store route history via events of route change, if event
     * is instanceof NavigationEnd and it is not a duplicate made
     * from cartBackButton activity
     */
    startRouteHistory() {
        this.router.events.subscribe((event) => {
            if (
                event instanceof NavigationEnd &&
                event.urlAfterRedirects !== this.history.slice(-1)[0]
            ) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    /**
     * Navigates back in the platform's history. Without history
     * navigates back to the determined route, otherwise defaults
     * back to Cart Page
     */
    goBack(
        route?: string,
        relativeTo?: ActivatedRoute,
        forceRoute?: boolean,
    ): void {
        this.history.pop();
        if (this.history.length && !forceRoute) {
            this.navigateHistory();
        } else {
            this.defaultToRoute(route, relativeTo);
        }
    }

    /**
     * With browser back button activity (popstate) remove that activities
     * resulting url duplicates from the end of the array, then navigate
     * using the last item
     */
    navigateHistory(): void {
        let previousUrl: string[];
        if (this.popState) {
            const history = this.history.slice(0, -2);
            previousUrl = history.slice(-1);
        }
        if (!previousUrl?.length) {
            previousUrl = this.history.slice(-1);
        }
        this.navigate(previousUrl);
    }

    /**
     * With a given route, use the determined ActivatedRoute passed from
     * the component for relativity
     */
    defaultToRoute(route: string, relativeTo?: ActivatedRoute): void {
        if (route?.length) {
            this.navigate([route], { relativeTo });
        } else {
            this.navigate(['']);
        }
    }

    /**
     * Updates the window location url to redirect to the marketplace url
     * from the site setting.
     */
    goToMarketplace(route = '') {
        const fullUrl = this.marketplaceUrl + route;
        this.window.location.href = fullUrl;
    }
}
