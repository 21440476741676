<page-title [cmsKeys]="pageTitleTexts.cmsKeys"></page-title>

<div class="container p-0">
    <incentive-form></incentive-form>
</div>
<div class="row">
    <div class="col d-flex justify-content-between mt-3">
        <wa-button
            [cartBackButton]="backRoute"
            [goToMarketplace]="true"
            data-eid="rebates-back-btn"
            [class]="'btn-link'"
            buttonStyle="simple">
            <wa-cms-content cmsKey="cart:back_button"></wa-cms-content>
        </wa-button>
    </div>
</div>
