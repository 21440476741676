import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import {
    AccentColors,
    FontFamily,
    HeaderStyling,
    StylesData,
} from '@server/utils/site-styles';
import { testSiteStylesFactory } from '@common-mocks';
import { SiteStyle } from '@common-models';

@Injectable({
    providedIn: 'root',
})
export class StylingService {
    siteStyles: StylesData['siteStyles'] | undefined;

    private defaultStyles: StylesData['siteStyles'];
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private settingsService: SettingsService,
    ) {
        this.siteStyles = this.settingsService.getSiteStyles();
        this.defaultStyles = testSiteStylesFactory();
    }

    /**
     * Gets utility specific colors from settings or set to default.
     */
    private get customStyleOrDefault(): SiteStyle {
        return {
            accentColors: (this.siteStyles.accent_colors ||
                this.defaultStyles.accent_colors) as AccentColors,
            headerStyling: (this.siteStyles.header_styling ||
                this.defaultStyles.header_styling) as HeaderStyling,
            fontFamily: (this.siteStyles.font_family ||
                this.defaultStyles.font_family) as FontFamily,
        };
    }

    /**
     * Gets utility specific colors and add as styles to document.
     */
    private getUtilityStyles(): string {
        const styles = this.customStyleOrDefault;
        return `
        ${styles.fontFamily.code}

        :root {
            --primary-color: ${styles.accentColors.primary_color};
            --primary-hover-color: ${styles.accentColors.primary_hover_color};
            --primary-btn-text-color: ${styles.accentColors.primary_button_text_color};
            --discount-color: ${styles.accentColors.discount_color};
            --discount-hover-color: ${styles.accentColors.discount_hover_color};
            --discount-btn-text-color: ${styles.accentColors.discount_button_text_color};
            --link-color: ${styles.accentColors.link_color};
            --link-hover-color: ${styles.accentColors.link_hover_color};
            --header-color: ${styles.headerStyling.header_text_color};
            --header-search-field-border-color: ${styles.headerStyling.header_search_field_border_color};
            --header-hover-color: ${styles.headerStyling.header_hover_color};
            --header-background-color: ${styles.headerStyling.header_background_color};

            --font-family: ${styles.fontFamily.name};
        }
    `;
    }

    /**
     * Load utility specific styles from settings and utility specific stylesheet.
     */
    appendCSS(): void {
        const style = this.document.createElement('style');
        style.innerHTML = this.getUtilityStyles();
        this.document.head?.appendChild(style);

        this.loadUtilityStyleSheet();
    }

    /**
     * Gets name stylesheet with utility specific styling and load it.
     */
    private loadUtilityStyleSheet(): void {
        const styleSheetName = this.settingsService.getSiteSetting(
            'cart_utility_styles',
        );
        if (styleSheetName) {
            const style = this.document.createElement('link');
            style.rel = 'stylesheet';
            style.href = `${styleSheetName}`;
            this.document.head?.appendChild(style);
        }
    }
}
