import { Component, OnInit } from '@angular/core';
import { BootstrapStatus } from '@common-models';
import { OrderService } from '@core/order/order.service';
import { SettingsService } from '@core/settings/settings.service';
import { Order, NextBestAction } from '@common-models';

@Component({
    selector: 'order-summary-page',
    templateUrl: './order-summary-page.component.html',
})
export class OrderSummaryPageComponent implements OnInit {
    BootstrapStatus = BootstrapStatus;

    constructor(
        public orderService: OrderService,
        private settingsService: SettingsService,
    ) {}

    get nextBestActionConfigs() {
        return this.settingsService.getSiteSetting(
            'next_best_action_order_summary_v2',
        ) as NextBestAction[];
    }

    get order(): Order {
        return this.orderService.order;
    }

    ngOnInit(): void {}
}
