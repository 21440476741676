<ng-container
    *ngIf="{
        cart: cart$ | async,
        cartRebateEligibilityStatus: cartRebateEligibilityStatus$ | async,
        isFetchingScheduleWindows: isFetchingScheduleWindows$ | async,
        isUpdatingCart: isUpdatingCart$ | async,
        scheduleWindows: scheduleWindows$ | async,
        unavailableProducts: unavailableProducts$ | async,
        isFetchingAddons: isFetchingAddons$ | async,
        addons: addons$ | async
    } as states">
    <div class="small">
        <wa-cms-content
            cmsKey="checkout:cart_expiration_notice_text"></wa-cms-content>
    </div>
    <rebate-eligibility-banner
        *ngIf="states.cartRebateEligibilityStatus"
        [eligibilityStatus]="
            states.cartRebateEligibilityStatus
        "></rebate-eligibility-banner>
    <alert
        *ngIf="states.unavailableProducts?.length"
        cssClasses="py-2"
        display="block"
        cmsKey="cart:general_item_issue"
        [status]="BootstrapStatus.danger"
        data-eid="general_item_issue"></alert>
    <item-details
        *ngFor="let item of states.cart.products"
        [cartItem]="item"
        [isFetchingScheduleWindows]="states.isFetchingScheduleWindows"
        [isUpdatingCart]="states.isUpdatingCart"
        [isEditable]="true"
        [scheduleWindows]="states.scheduleWindows"
        [cartRebateEligibilityStatus]="states.cartRebateEligibilityStatus"
        [addons]="states.addons?.[item.id]"
        [isFetchingAddons]="states.isFetchingAddons"
        (updateItemAddons)="updateItemAddons($event)">
    </item-details>
    <total-details [scheduleWindows]="states.scheduleWindows"></total-details>
    <payment-method-section
        [hasUnavailableProduct]="states.unavailableProducts?.length > 0">
    </payment-method-section>
    <div class="small">
        <wa-cms-content
            cmsKey="compliance:checkout_cart"
            [context]="{
                partner: states.cart.fulfillment_partner_name
            }"></wa-cms-content>
    </div>
</ng-container>
