import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { CartService } from '@core/cart/cart.service';
import { SwiftypeService } from '@core/swiftype/swiftype.service';

@Injectable({
    providedIn: 'root',
})
export class CartResolverService implements Resolve<boolean> {
    constructor(
        private cartService: CartService,
        private swiftTypeService: SwiftypeService,
    ) {}

    /**
     * Get or creates a cart then fetches the swiftype data for the cart products
     * Avoids making call to get swiftype if there are no products in the cart
     * or on the add to cart page.
     */
    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.cartService.getOrCreateCart().pipe(
            switchMap((cart): Observable<boolean> => {
                if (
                    cart.products.length &&
                    route.routeConfig.path !== ':productId'
                ) {
                    return this.swiftTypeService
                        .getProductsForCartItems(cart.products)
                        .pipe(map(() => true));
                } else {
                    return of(true);
                }
            }),
            take(1),
        );
    }
}
