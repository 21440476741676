import { CartItem } from '../cart-item/cart-item.model';

export class Cart {
    id: string;
    product_count?: number;
    provider_id?: number;
    initial_params?: object;
    status?: CartStatus;
    address?: string;
    subtotal?: number;
    shipping_total?: number;
    shipping_zip?: string;
    installation_total?: number;
    installation_date?: string;
    is_finalized: boolean;
    is_financeable: boolean;
    tax?: number;
    total?: number;
    cart_hash?: string;
    fulfillment_partner_id?: number;
    fulfillment_partner_name?: string;
    fulfillment_partner_checkout_redirect_url?: string;
    payment_method?: PaymentMethod;
    products?: Array<CartItem>;
    total_discount?: number;

    /**
     * When the cart has gone through finalization then further modified,
     * any shipping_total that was previously calculated is now stale but
     * is still affecting the cart total.
     *
     * If the cart has been finalized then we should use the total field,
     * otherwise, the total to display to the user should just be factoring
     * the subtotal and totalSavings.
     */
    get displayTotal(): number {
        return this.is_finalized
            ? this.total
            : this.subtotal - this.totalSavings;
    }

    /**
     * This field is the total savings amount on the cart that includes all
     * savings such as sale discounts, eligible rebates, total_additional_discounts
     */
    get totalSavings(): number {
        return Number(this.total_discount);
    }
}

export type CartData = Omit<Cart, 'displayTotal' | 'totalSavings'>;

export enum CartStatus {
    open = 'open',
    frozen = 'frozen',
}

export enum PaymentMethod {
    finance = 'finance',
    creditCard = 'credit_card',
}

export enum CheckoutRedirect {
    payment = 'payment',
    delivery = 'delivery-selection',
}

export interface FinanceEligibilityParameters {
    max_financeable_amount: number;
    min_financeable_percentage: number;
}
