export type EcoFinancingText = {
    as_low_as: string;
    footer_term: string;
    learn_more: string;
    month: string;
    or: string;
    term_length: string;
    terms: string;
    with: string;
};

export type EcoFinancingCmsAprContext = {
    monthlyTotal: string;
    termLength: number;
    aprRate: string;
};

export const EcoFinancingModalProList = [
    { icon: 'payment-cards', cmsKey: 'payment_cards' },
    { icon: 'clock', cmsKey: 'clock' },
    { icon: 'dollars', cmsKey: 'dollars' },
];
