import { utilsFactory } from './utils';

const utils = utilsFactory();

// StorageService
const getStoredData = (obj: object, path: string, options: any): any => {
    options = options || {};
    return getOrSetNestedProperty(obj, path, {
        defaultValue: options.defaultValue,
    });
};

const getOrSetNestedProperty = (
    obj: object,
    path: string | string[],
    options: any,
): any => {
    const defaultOptions = {
        returnDefaultInsteadOfNull: true,
    };
    options = Object.assign(defaultOptions, options || {});
    path = utils.normalizePath(path) as string[];
    const isSet = options.hasOwnProperty('newValue');
    options.makeMissingProps = isSet;
    const pathResolution = utils.resolvePath(obj, path, options);
    // If a new value is passed, set it
    if (isSet) {
        pathResolution.parent[pathResolution.prop] = options.newValue;
        pathResolution.value = options.newValue;
    }
    /**
     * If the stored value is undefined or if the value is null and the options specify
     * returning the defaultVal instead of null, return the defaultVal (if one has been
     * passed)
     */
    if (pathResolution.value === null && options.returnDefaultInsteadOfNull) {
        return options.defaultValue || pathResolution.value;
    } else if (pathResolution.value === undefined) {
        return options.defaultValue;
    } else {
        return pathResolution.value;
    }
};

export const storageFactory = (overrides: Partial<any> = {}): any => {
    return {
        getStoredData,
        getOrSetNestedProperty,
        ...overrides,
    };
};
