import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-module';
import { WebappCommonModule } from '@enervee/webapp-common';
import { ShippingPageComponent } from './shipping-page/shipping-page.component';
import { CustomerDetailsPageRoutingModule } from './customer-details-page-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RebatesPageComponent } from './rebates-page/rebates-page.component';
//import { AccountPageComponent } from './account-page/account-page.component';
import { CustomerDetailsPageComponent } from './customer-details-page.component';
import { IncentiveFormComponent } from './rebates-page/incentive-form/incentive-form.component';
import { NO_ERRORS_SCHEMA } from '@angular/compiler';
import { ReportingPageComponent } from './reporting-page/reporting-page.component';

@NgModule({
    declarations: [
        CustomerDetailsPageComponent,
        ShippingPageComponent,
        // AccountPageComponent,
        RebatesPageComponent,
        IncentiveFormComponent,
        ReportingPageComponent,
    ],
    imports: [
        CommonModule,
        CustomerDetailsPageRoutingModule,
        SharedModule,
        WebappCommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
})
export class CustomerDetailsPageModule {}
