import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
    CartItem,
    CodeToIds,
    Product,
    ProductData,
    ProductDetail,
    ProductFeature,
    ProductImage,
    ProductResponse,
    SwiftypeOptions,
} from '@common-models';
import {
    API_V1,
    FEATURES_ENDPOINT,
    PRODUCT_API_URL_SETTING,
    PRODUCT_DETAILS_ENDPOINT,
    PRODUCT_ENDPOINT,
    PRODUCT_IMAGES_ENDPOINT,
    RESULTS_ENDPOINT,
} from '@server/constants';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { SettingsService } from '../settings/settings.service';
import { SimpleStore } from '../simple-store/simple-store.service';
import { CategoriesService } from '@core/categories/categories.service';
import { handleGenericHttpError } from '@utils/handle-http-error';

/**
 * @description
 *
 * Service to handle Swiftype product data for current site. Gets the products.
 */
@Injectable({
    providedIn: 'root',
})
export class SwiftypeService {
    private baseSwiftypeEndpoint: string;
    private baseProductApiUrl: string;
    private baseResultsApiUrl: string;
    private baseProductFeaturesApiUrl: string;
    private baseProductImagesApiUrl: string;
    private baseProductDetailsApiUrl: string;

    private products: ProductResponse = null;
    private _cartProducts: Product[];

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        private categoriesService: CategoriesService,
        private simpleStore: SimpleStore,
    ) {
        this.baseSwiftypeEndpoint = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.baseProductApiUrl =
            this.baseSwiftypeEndpoint + API_V1 + PRODUCT_ENDPOINT;
        this.baseResultsApiUrl =
            this.baseSwiftypeEndpoint + API_V1 + RESULTS_ENDPOINT;
        this.baseProductFeaturesApiUrl =
            this.baseSwiftypeEndpoint + API_V1 + FEATURES_ENDPOINT;
        this.baseProductImagesApiUrl =
            this.baseSwiftypeEndpoint + API_V1 + PRODUCT_IMAGES_ENDPOINT;
        this.baseProductDetailsApiUrl =
            this.baseSwiftypeEndpoint + API_V1 + PRODUCT_DETAILS_ENDPOINT;
    }

    /**
     * Getter for the swiftype data of products within a cart
     */
    get cartProducts(): Product[] {
        return this._cartProducts;
    }

    /**
     * Setter for setting the swiftype data for the products within a cart
     */
    set cartProducts(products: Product[]) {
        this._cartProducts = products;
    }

    /**
     * Get individual product data
     *
     * @param productId
     * @param categoryId
     * @param categoryCode
     */
    getProduct(
        productId: string,
        categoryId: string,
        categoryCode: string,
    ): Observable<ProductData> {
        return this.simpleStore.get<ProductData>(['products', productId], () =>
            this.requestProduct(productId, categoryId).pipe(
                map((productResponse: ProductResponse): ProductData | null => {
                    const { records } = productResponse;
                    return _.get(records[categoryCode], '0', null);
                }),
            ),
        );
    }

    /**
     * Create a Product instance from the product response data.
     */
    getSerializedProduct(
        productId: string,
        categoryId: string,
        categoryCode: string,
    ): Observable<Product | null> {
        return this.getProduct(productId, categoryId, categoryCode).pipe(
            map((productData: ProductData): Product | null => {
                if (!productData) {
                    return null;
                }
                return this.createProduct(productData);
            }),
        );
    }

    /**
     * Get an individual product's details data
     *
     * @param productId
     */
    getProductDetail(productId: string): Observable<ProductDetail> {
        return this.simpleStore.get<ProductDetail>(
            ['products', productId, 'details'],
            () => this.requestProductDetail(productId),
        );
    }

    /**
     * Get an individual product's feature data
     *
     * @param productId
     */
    getProductFeatures(productId: string): Observable<ProductFeature[]> {
        return this.simpleStore.get<ProductFeature[]>(
            ['products', productId, 'features'],
            () => this.requestProductFeatures(productId),
        );
    }

    /**
     * Get an individual product's image data
     *
     * @param productId
     */
    getProductImages(productId: string): Observable<ProductImage[]> {
        return this.simpleStore.get<ProductImage[]>(
            ['products', productId, 'images'],
            () => this.requestProductImages(productId),
        );
    }

    /**
     * GET request to swiftype product endpoint to retrieve product data
     *
     * @param productId
     * @param categoryId
     * @private
     */
    private requestProduct(
        productId: string,
        categoryId: string,
    ): Observable<ProductResponse> {
        const url = `${this.baseProductApiUrl}${productId}/?categoryId=${categoryId}`;
        return this.http
            .get<ProductResponse>(url)
            .pipe(
                catchError(
                    handleGenericHttpError(
                        `Error fetching product ${productId}`,
                    ),
                ),
            );
    }

    /**
     * GET request to swiftype product endpoint to retrieve features data
     *
     * @param productId
     * @private
     */
    private requestProductDetail(
        productId: string,
    ): Observable<ProductDetail> {
        const url = `${this.baseProductDetailsApiUrl}${productId}/`;
        return this.http
            .get<ProductDetail>(url)
            .pipe(
                catchError(
                    handleGenericHttpError(
                        `Error fetching product details ${productId}`,
                    ),
                ),
            );
    }

    /**
     * GET request to swiftype product endpoint to retrieve features data
     *
     * @param productId
     * @private
     */
    private requestProductFeatures(
        productId: string,
    ): Observable<ProductFeature[]> {
        const url = `${this.baseProductFeaturesApiUrl}${productId}/`;
        return this.http
            .get<ProductFeature[]>(url)
            .pipe(
                catchError(
                    handleGenericHttpError(
                        `Error fetching product ${productId}`,
                    ),
                ),
            );
    }

    private requestProductImages(
        productId: string,
    ): Observable<ProductImage[]> {
        const url = `${this.baseProductImagesApiUrl}${productId}/`;
        return this.http
            .get<ProductImage[]>(url)
            .pipe(
                catchError(
                    handleGenericHttpError(
                        `Error fetching product images ${productId}`,
                    ),
                ),
            );
    }

    /**
     * Returns the swiftype data for a given product of the cart that
     * has already been fetched
     */
    getProductForCartItem(id: number): Product | undefined {
        return this.cartProducts?.find((product) => {
            return id === product.id;
        });
    }

    /**
     * Returns the Swiftype data of products within the cart from a cache
     * or make a request if result has not been cached.
     */
    getProductsForCartItems(cartItems: CartItem[]): Observable<Product[]> {
        const categoryCodesToProductIds =
            this.categoriesService.createCategoryMapFromItems(cartItems);
        return this.simpleStore.get<Product[]>(
            ['products', _.map(cartItems, 'id'), 'cart'],
            () => this.requestProductsForCartItems(categoryCodesToProductIds),
        );
    }

    /**
     * Gets the Swiftype data for the products within a cart.
     */
    requestProductsForCartItems(
        categoryCodesToProductIds: CodeToIds,
    ): Observable<Product[]> {
        return this.getProducts(categoryCodesToProductIds, false).pipe(
            map((response: ProductResponse) => {
                const productDataArr: ProductData[] = _.values(
                    response?.records,
                ).flat();
                const products = productDataArr.map(this.createProduct);
                this.cartProducts = products;
                return products;
            }),
        );
    }

    /**
     * Creates a new instance of Product from ProductData.
     */
    createProduct(productData: ProductData): Product {
        return Object.assign(new Product(), productData);
    }

    /**
     * Get data for multiple products
     *
     * @param categoryCodesToProductIds
     * @param applyDefaultFilters
     */
    getProducts(
        categoryCodesToProductIds: CodeToIds,
        applyDefaultFilters: boolean,
    ): Observable<ProductResponse> {
        const options: SwiftypeOptions = {
            filters: {},
            document_types: [],
            apply_results_filters: true,
        };

        options.apply_results_filters = applyDefaultFilters;

        for (const categoryCode in categoryCodesToProductIds) {
            if (categoryCodesToProductIds.hasOwnProperty(categoryCode)) {
                options.filters[categoryCode] = {
                    external_id: categoryCodesToProductIds[categoryCode],
                };
                options.document_types.push(categoryCode);
            }
        }

        return this.simpleStore.get<ProductResponse>(
            ['getProducts', _.values(categoryCodesToProductIds)],
            () =>
                this.requestProducts(options).pipe(
                    map((response) => {
                        return response;
                    }),
                ),
        );
    }

    /**
     * Post request to swiftype search endpoint to retrieve multiple products
     *
     * @param options
     */
    private requestProducts(
        options: SwiftypeOptions,
    ): Observable<ProductResponse> {
        return this.http
            .post<ProductResponse>(this.baseResultsApiUrl, options)
            .pipe(
                tap((res: ProductResponse) => {
                    this.products = res;
                }),
                catchError(handleGenericHttpError(`Error fetching products`)),
                share(),
            );
    }

    /**
     * Get results page product data
     *
     * @param options
     */
    getResults(options: SwiftypeOptions): Observable<ProductResponse> {
        return this.simpleStore.get<ProductResponse>(
            ['results', options],
            () => this.requestResults(options),
        );
    }

    /**
     * Post request to swiftype search endpoint to retrieve multiple products
     *
     * @param options
     */
    requestResults(options: SwiftypeOptions): Observable<ProductResponse> {
        return this.http
            .post<ProductResponse>(this.baseResultsApiUrl, options)
            .pipe(
                catchError(handleGenericHttpError(`Error fetching products`)),
            );
    }
}
