import { ProductResponse } from '@common-models';
import { testProductDataFactory } from './product';

export const baseProductResponse: ProductResponse = {
    info: {
        thermostats: {
            query: '',
            current_page: 1,
            num_pages: 1,
            per_page: 20,
            total_result_count: 1,
            facets: {},
        },
    },
    records: {
        thermostats: [testProductDataFactory()],
    },
    errors: {},
    record_count: 1,
};

export const testProductResponseFactory = (
    overrides: Partial<ProductResponse> = {},
): ProductResponse => {
    return {
        ...baseProductResponse,
        ...overrides,
    };
};
