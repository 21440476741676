import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CartService } from '@core/cart/cart.service';
import { Cart } from '@common-models';
import { MARKETPLACE_PATH } from '@server/constants';
import { CookiesService } from '@enervee/webapp-common';

@Component({
    selector: 'cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartPageComponent implements OnInit {
    cart$: Observable<Cart>;

    constructor(
        private cartService: CartService,
        private cookiesService: CookiesService,
    ) {}

    ngOnInit(): void {
        this.cart$ = this.cartService.cart$;
    }

    /**
     * Route user to marketplace
     */
    get backRoute(): string {
        return this.cookiesService.get(MARKETPLACE_PATH);
    }
}
