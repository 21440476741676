import { Link } from './link.model';
import { Logo } from './logo.model';

export class ExternalOrderLink {
    helper_text?: string;
    label: string;
    link?: string;
}

export class Partner {
    display_name: string;
    external_order_link?: ExternalOrderLink;
    hide_shipping_availability: boolean;
    logo: Logo;
    privacy_policy?: Link;
    reschedule_url?: string;
    return_policy?: Link;
    support?: Link;
}

export class FulfillmentPartners {
    'Best Buy': Partner;
    'Lightbulbs.com': Partner;
    preferred_partners: Array<string>;
    sort_by: string;
}

export enum FulfillmentPartnerCodes {
    best_buy = 1,
    lightbulbs = 2,
    water_heater_warehouse = 5,
}
