import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { CartService } from '@core/cart/cart.service';
import { CustomerDetailService } from '@core/customer-detail/customer-detail.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RebateForm, FrameHeight } from '@common-models';
import { RebateService } from '@core/rebate/rebate.service';

/**
 * @description
 *
 * An auto-unsubscribe package.
 * Use with `.pipe(untilDestroyed(this)) before subscribing.
 */
@UntilDestroy()
@Component({
    selector: 'incentive-form',
    templateUrl: './incentive-form.component.html',
    styleUrls: ['./incentive-form.component.scss'],
})
export class IncentiveFormComponent implements OnInit {
    incentiveFormUrl$: Observable<SafeResourceUrl>;
    iframeHeight$ = new BehaviorSubject<string>('845px');
    onSuccessNavigatePath = '';

    constructor(
        private cartService: CartService,
        private customerDetailService: CustomerDetailService,
        private router: Router,
        private rebateService: RebateService,
    ) {}

    ngOnInit(): void {
        this.fetchIncentiveFormUrl();
        if (this.router.url.includes('finance')) {
            this.onSuccessNavigatePath = '/checkout/finance/apply';
        }
    }

    /**
     * Gets the incentive form URL
     */
    fetchIncentiveFormUrl(): void {
        this.incentiveFormUrl$ = this.cartService.cart$.pipe(
            switchMap((cart) =>
                this.customerDetailService.generateIframeUrl(cart.id),
            ),
            untilDestroyed(this),
            take(1),
        );
    }

    /**
     * Once the iframe form is complete, navigates to Cart Page
     */
    onFormComplete(data: RebateForm): void {
        if (!data.is_valid) {
            return;
        }
        this.rebateService
            .getBestRebates()
            .pipe(
                switchMap(() => this.cartService.requestCart().pipe(take(1))),
                untilDestroyed(this),
            )
            .subscribe(() =>
                this.router.navigate([this.onSuccessNavigatePath], {
                    queryParamsHandling: 'merge',
                }),
            );
    }

    /**
     * Update the iframeHeight$ when the height of the content inside the iframe changes.
     * iframeHeight$ is going to change the iframe height in the template.
     */
    onIframeSizeChange(data: FrameHeight): void {
        const frameHeight = data.frameHeight;
        if (typeof frameHeight == 'number') {
            this.iframeHeight$.next(`${frameHeight}px`);
        }
    }
}
