<div
    *ngIf="{
        cart: cart$ | async,
        totalCMSKeys: totalCMSKeys$ | async,
        isShippableCart: isShippableCart$ | async,
    } as viewStates"
    class="bg-white d-grid gap-2 mb-3">
    <div class="order-summary-label p-2 fw-bold small">
        <wa-cms-content cmsKey="cart:summary:title"></wa-cms-content>
    </div>
    <div class="d-grid gap-2 px-2">
        <text-label>
            <div label>
                <wa-cms-content
                    *ngIf="viewStates.cart.totalSavings"
                    data-eid="total-details-subtotal-before-savings-label"
                    cmsKey="cart:summary:original_price">
                </wa-cms-content>
            </div>
            <span value data-eid="total-details-subtotal-value">
                {{ viewStates.cart.subtotal | currency }}
            </span>
        </text-label>
        <text-label
            data-eid="total-details-savings"
            *ngIf="viewStates.cart.totalSavings"
            [cmsKeys]="{ label: 'cart:summary:savings' }">
            <span value>
                -&nbsp;{{ viewStates.cart.totalSavings | currency }}</span
            >
        </text-label>
        <text-label
            *ngIf="isCartDeliverable(viewStates.cart)"
            data-eid="total-details-delivery"
            [cmsKeys]="{ label: 'cart:summary:delivery' }">
            <wa-cms-content value cmsKey="cart:summary:free"></wa-cms-content>
        </text-label>
        <text-label
            *ngIf="viewStates.isShippableCart"
            data-eid="total-details-shipping"
            [cmsKeys]="{
                label: 'cart:summary:shipping',
                helper: !viewStates.cart.is_finalized
                    ? 'commerce:total_shipping_helper'
                    : undefined
            }"
            [context]="{
                fulfillmentPartner: viewStates.cart.fulfillment_partner_name
            }">
            <span
                *ngIf="viewStates.cart.is_finalized; else showShippingTBD"
                value
                >{{ viewStates.cart.shipping_total | currency }}</span
            >
            <ng-template #showShippingTBD>
                <wa-cms-content
                    class="text-muted"
                    data-eid="total-details-shipping-tbd"
                    cmsKey="commerce:shipping-tbd">
                </wa-cms-content>
            </ng-template>
        </text-label>
        <text-label
            data-eid="total-details-taxes"
            [cmsKeys]="{ label: 'cart:summary:taxes' }">
            <span
                *ngIf="viewStates.cart.is_finalized; else showTaxTBD"
                value
                >{{ viewStates.cart.tax | currency }}</span
            >
            <ng-template #showTaxTBD>
                <wa-cms-content
                    class="text-muted"
                    data-eid="total-details-taxes-tbd"
                    cmsKey="commerce:taxes-tbd">
                </wa-cms-content>
            </ng-template>
        </text-label>
        <text-label
            data-eid="total-details-total"
            class="border-top border-dark fw-bold pt-2"
            [cmsKeys]="{ label: 'cart:summary:total' }">
            <div value data-eid="total-details-credit-card-total">
                {{ viewStates.cart.displayTotal | currency }}
            </div>
        </text-label>
    </div>
</div>
