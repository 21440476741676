import { Injectable } from '@angular/core';
import { PaymentMethod } from '@common-models';
import { RebateService } from '@core/rebate/rebate.service';
import { Observable, map, switchMap } from 'rxjs';
import { TermsAndConditions } from 'src/app/models/terms-and-conditions.model';

@Injectable({
    providedIn: 'root',
})
export class ShippingService {
    private _paymentMethod: PaymentMethod;
    private _successRedirectPath: string;

    constructor() {}

    set paymentMethod(method: PaymentMethod) {
        this._paymentMethod = method;
    }

    get paymentMethod(): PaymentMethod {
        return this._paymentMethod;
    }

    set successRedirectPath(path: string) {
        this._successRedirectPath = path;
    }

    get successRedirectPath(): string {
        return this._successRedirectPath;
    }
}
