import { Injectable } from '@angular/core';
import _ from 'lodash';

import { SettingsService } from '../settings/settings.service';
import { CartItem, CategoryWithFacets } from '@common-models';

/**
 * @description
 *
 * Service to handle category data for the current site. Gets the categories
 * and category facet data on startup for components and routes to use.
 */

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    categories: CategoryWithFacets[] = [];

    constructor(private settingsService: SettingsService) {
        this.getSettings();
    }

    /**
     * Gets the category which matches the passed category code. If the code
     * doesn't exist, undefined is returned. The category code should be what
     * is displayed in the url on the results page for example.
     */
    getCategory(code: string): CategoryWithFacets | undefined {
        const category = this.categories.find(
            (cat) => cat.category_code === code,
        );
        if (!category) {
            console.warn(`Category ${code} does not exist.`);
        }
        return category;
    }

    /**
     * Get category through category ID
     *
     * @returns category
     */
    getCategoryById(categoryId: number): CategoryWithFacets | undefined {
        const category = this.categories.find((cat) => cat.id === categoryId);
        if (!category) {
            console.warn(`Category ${categoryId} does not exist.`);
        }
        return category;
    }

    /**
     * Get category_code (ex: 'washers') through category ID
     *
     * @returns category_code
     */
    getCategoryCodeById(categoryId: number): string | undefined {
        return this.getCategoryById(categoryId)?.category_code;
    }

    /**
     * Check to see if category is 'simplified'
     */
    isCategorySimplified(categoryId: number): boolean {
        return (
            this.getCategoryById(categoryId)?.category_type === 'simplified'
        );
    }

    /**
     * Creates a category map for a list of cart items.
     * The keys for the maps are category codes and the values
     * are an array of product ids
     * First it groups all of the cart items by their category_id
     * and then it maps the cart items to just their ids.
     * Then lastly, it will convert the category id to their
     * respective category codes.
     */
    createCategoryMapFromItems(cartItems: CartItem[]): {
        [id: string]: number[];
    } {
        return _.chain(cartItems)
            .groupBy('category_id')
            .mapValues((itemArray: CartItem[]) => {
                return _.map(itemArray, 'id');
            })
            .mapKeys((value: number[], key: string) => {
                return this.getCategoryCodeById(Number(key));
            })
            .value();
    }

    /**
     * Pulls category and facet data from SettingsService and assigns
     * them to the "categories" property.
     */
    private getSettings() {
        const categories = this.settingsService.getCategories();
        const facets = this.settingsService.getCategoryFacets();
        this.categories = categories?.map((category) => {
            return {
                ...category,
                facets: facets[category.category_code],
            };
        });
    }
}
