import {
    Product,
    TrackingParams,
    PageData,
    SegmentIdentificationData,
    LogSegmentResponse,
} from '@common-models';
import { Analytics } from '@segment/analytics-next';
import { testProductFactory } from './product';

type updatedProduct = { id: string; testKey: string };

export interface SegmentTestTracking {
    eventData: { [key: string]: string };
    eventName: string;
    fullApiUrl: string;
    products: Product[];
    trackingParams: TrackingParams;
    defaultParams: { [key: string]: string };
    userData: { [key: string]: string };
    userId: string;
    pageData: PageData;
    updatedProducts: updatedProduct[];
}

const testUserDetails = {
    activated: false,
    email: 'brandon@enervee.com',
    first_name: '',
    id: 30778,
    last_name: '',
};

const testLogSegmentPayload: SegmentIdentificationData = {
    data: {},
    segment_anon_user_id: '1e32bd10-02b4-488f-8034-ef4017fe2420',
    type: 'identify',
    user_id: 30778,
};

const testLogSegmentResponse: LogSegmentResponse = {};

const testSegmentIdentification: SegmentIdentificationData = {
    type: 'identify',
    user_id: 'test_id',
    data: { someData: 'some_data' },
    segment_anon_user_id: null,
};

const testSegmentTracking: SegmentTestTracking = {
    eventData: { someData: 'event_data' },
    eventName: 'test event name',
    fullApiUrl: 'https://test.enervee.com/api/1.0/log-segment/',
    products: [testProductFactory()],
    trackingParams: {
        params: {},
        attributes: {
            pageSection: 'test_attribute',
        },
        product_ids: [],
        _eventId: '1234',
        category_id: '5678',
        key: 'test_key',
    },
    defaultParams: { key: 'some_key', defaultParam: 'some_default_params' },
    userData: { data: 'this is some data' },
    userId: 'test_id',
    pageData: {
        search: '',
        type: 'other',
        category_id: 'test_id',
    },
    updatedProducts: [{ id: '0000', testKey: 'test_value' }],
};

const testAnalytics = {
    user: () => ({
        anonymousId: () => '234',
        id: () => undefined,
    }),
    track: (
        name: string,
        props: object,
        config: Analytics,
        resolve?: () => null,
    ) => resolve && resolve(),
    page: () => null,
    reset: () => null,
    setAnonymousId: () => null,
};

export const testLogSegmentFactory = (overrides: Partial<any> = {}): any => {
    return {
        payload: { ...testLogSegmentPayload },
        response: { ...testLogSegmentResponse },
        ...overrides,
    };
};

export const testSegmentTrackingFactory = (
    overrides: Partial<any> = {},
): any => {
    return {
        ...testSegmentTracking,
        ...overrides,
    };
};

export const testSegmentIdentificationFactory = (
    overrides: Partial<any> = {},
): any => {
    return {
        ...testSegmentIdentification,
        ...overrides,
    };
};

export const testSegmentAnalyticsFactory = (
    overrides: Partial<any> = {},
): any => {
    return {
        ...testAnalytics,
        ...overrides,
    };
};

export const testUserDetailsFactory = (overrides: Partial<any> = {}): any => {
    return {
        ...testUserDetails,
        ...overrides,
    };
};
