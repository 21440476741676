import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { MarketplaceTextResponse } from '@enervee/webapp-common';
import { handleGenericHttpError } from '@utils/handle-http-error';
import _ from 'lodash';
import { map, Observable, of } from 'rxjs';
import { catchError, finalize, share, take, tap } from 'rxjs/operators';

/**
 * @description
 *
 * Service to handle calls to CMS to get marketplace texts and replace variables
 * with context objects.
 */

@Injectable({
    providedIn: 'root',
})
export class CmsService {
    private baseApiUrl = this.settingsService.getSiteSetting('cms_base_url');
    private cmsTexts: MarketplaceTextResponse = {};
    private cmsTextsObservable: Record<
        string,
        Observable<MarketplaceTextResponse>
    > = {};
    private defaultTextContext: { br: '<br>' };
    private catchAllRegex = this.getVariableRegex('\\w+');

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
    ) {}

    /**
     * Returns an observable that resolves with a specific retrieved
     * marketplace text value based on the given key.
     * If the content for the root key of the group (i.e. 'index_page'
     * in 'index_page:main_content') has not been requested yet, it is
     * requested now.
     *
     * If a context object is passed, each instance of `{key}` in the
     * retrieved CMS content will be replaced with the value of that
     * `key` in the context object.
     *
     * @example
     * // Requesting text with only a key
     * CmsContentService.getMarketPlaceText('index_page:main_content').subscribe(function(text) {
     *   vm.doSomethingWith = text;
     * });
     * @example
     * // Requesting text with a key and a category id
     * CmsContentService.getMarketPlaceText('index_page:main_content', 94).subscribe(function(text) {
     *   vm.doSomethingWith = text;
     * });
     * @example
     * // Requesting text with a key and simple context to be rendered
     * CmsContentService.getMarketPlaceText(
     *   'index_page:main_content',
     *   {
     *     'context_key': 'Context Value',
     *   }
     * ).subscribe(function(text) {
     *   vm.doSomethingWith = text;
     * });
     *
     */
    getMarketPlaceText(
        key: string,
        context: Record<string, string | number | boolean> = {},
        categoryId: string | number = 'default',
    ): Observable<string> {
        const textPath = key.split(':');
        textPath.push(String(categoryId));
        const baseGroup = textPath[0];

        return this.requestCMSGroup(baseGroup).pipe(
            map((group: MarketplaceTextResponse) => {
                const cmsText = this.getCmsText(textPath, group);
                return this.renderContext(cmsText, context);
            }),
            take(1),
        );
    }

    /**
     * Grabs the Marketplace Text object from the requested paths
     */
    protected getCmsText(
        path: string[],
        texts: MarketplaceTextResponse,
    ): string {
        const cmsKey = path.slice(0, -1).join(':');
        if (!_.has(texts, path)) {
            console.warn(
                `Marketplace text key ${cmsKey} for category ${path.pop()} does not exist.`,
            );
            return '';
        }
        const text = _.get(texts, path);
        if (!text.enabled) {
            console.warn(
                `Marketplace text key ${cmsKey} for category ${path.pop()} is not enabled.`,
            );
            return '';
        }
        return text.value;
    }

    /**
     * Renders a chunk of marketplace text with a context object.
     *
     * @example
     * renderContext(
     *   "This is {place}! Did you think {place} would fall that easily?",
     *   {
     *     place: "Sparta"
     *   }
     * );
     * // Resolves to: "This is Sparta! Did you think Sparta would fall that easily?"
     */
    protected renderContext(
        text: string,
        context: Record<string, string | number | boolean>,
    ): string {
        context = Object.assign({}, this.defaultTextContext, context || {});
        let finalRender = Object.keys(context).reduce((renderedText, key) => {
            const contextValue = String(context[key]);
            const re = this.getVariableRegex(key);
            return renderedText.replace(re, contextValue);
        }, text);

        const unrenderedVars = finalRender.match(this.catchAllRegex);
        if (unrenderedVars) {
            console.warn(
                `Removing the following unrendered variables from text: '${text}'`,
                unrenderedVars,
            );
            finalRender = finalRender.replace(this.catchAllRegex, '');
        }

        return finalRender;
    }

    /**
     * Helper function to get the regular expression for finding a specific
     * variable in a chunk of text
     */
    private getVariableRegex(key: string): RegExp {
        // eslint-disable-next-line security/detect-non-literal-regexp
        return new RegExp('\\{' + key + '\\}', 'g');
    }

    /**
     * Helper methods that sends the request to the CMS to get text for the specified
     * group. In flight calls are kept track of so we don't make requests more
     * than once.
     */
    protected requestCMSGroup(
        cmsGroup: string,
    ): Observable<MarketplaceTextResponse> {
        const url = `api/cms`;
        let texts: Observable<MarketplaceTextResponse>;
        if (cmsGroup in this.cmsTexts) {
            texts = of({ [cmsGroup]: this.cmsTexts[cmsGroup] });
        } else if (cmsGroup in this.cmsTextsObservable) {
            texts = this.cmsTextsObservable[cmsGroup];
        } else {
            this.cmsTextsObservable[cmsGroup] = this.http
                .get<MarketplaceTextResponse>(url, {
                    params: { group: cmsGroup },
                })
                .pipe(
                    tap((res: MarketplaceTextResponse) => {
                        this.cmsTexts = Object.assign({}, this.cmsTexts, res);
                    }),
                    catchError(
                        handleGenericHttpError(
                            `Error fetching Marketplace Text Group ${cmsGroup}`,
                        ),
                    ),
                    share(),
                    finalize(() => {
                        delete this.cmsTextsObservable[cmsGroup];
                    }),
                );
            texts = this.cmsTextsObservable[cmsGroup];
        }
        return texts;
    }
}
