import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { BehaviorSubject, first } from 'rxjs';

import { BootstrapStatus } from '@common-models';
import { RebateEligibilityStatus } from '@core/rebate/rebate-eligibility-status.model';
import { EligibilityStatusDetails } from '@shared-components/rebate-eligibility-banner/eligibility-status-details.model';
import { EventListService } from '@core/event-list/event-list.service';
import { NavigationService } from '@core/navigation/navigation.service';

@Component({
    selector: 'rebate-eligibility-banner',
    templateUrl: './rebate-eligibility-banner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RebateEligibilityBannerComponent implements OnInit, OnChanges {
    @Input() eligibilityStatus: RebateEligibilityStatus;

    alertStatus: BootstrapStatus;
    BootstrapStatus = BootstrapStatus;
    cmsKey: string;
    eligibilityStatusDetails$ = new BehaviorSubject<EligibilityStatusDetails>(
        null,
    );
    RebateEligibilityStatus = RebateEligibilityStatus;

    constructor(
        private eventListService: EventListService,
        private navigationService: NavigationService,
    ) {}

    ngOnInit(): void {
        this.eligibilityStatusDetails$.next(
            this.getEligibilityStatusDetails(this.eligibilityStatus),
        );
    }

    ngOnChanges(changes) {
        this.eligibilityStatusDetails$.next(
            this.getEligibilityStatusDetails(
                changes.eligibilityStatus.currentValue,
            ),
        );
    }

    /**
     * Check if the event target is the link for checking rebate
     * eligibility status.  If it is, then send the tracking event
     * and redirect to the rebate page.
     */
    handleAlertClick(event: MouseEvent) {
        if (event.target instanceof HTMLAnchorElement) {
            event.preventDefault();
            this.sendCheckRebateEligibilityClicked();
            this.redirectToRebates();
        }
    }

    redirectToRebates() {
        this.navigationService.navigate(['customer-details/rebates']);
    }

    sendCheckRebateEligibilityClicked() {
        this.eventListService
            .sendCheckRebateEligibilityClicked()
            .pipe(first())
            .subscribe();
    }

    getEligibilityStatusDetails(
        eligibilityStatus: RebateEligibilityStatus,
    ): EligibilityStatusDetails {
        const statusCMSMap = {
            [RebateEligibilityStatus.NO_REBATES]: {
                status: null,
            },
            [RebateEligibilityStatus.MISSING_DETAILS]: {
                status: BootstrapStatus.warning,
            },
            [RebateEligibilityStatus.NOT_ELIGIBLE]: {
                status: BootstrapStatus.warning,
            },
            [RebateEligibilityStatus.PARTIALLY_ELIGIBLE]: {
                status: BootstrapStatus.success,
            },
            [RebateEligibilityStatus.FULLY_ELIGIBLE]: {
                status: BootstrapStatus.success,
            },
        };

        return {
            cmsKey: `cart:rebate_eligibility:${eligibilityStatus}`,
            alertStatus: statusCMSMap[eligibilityStatus]?.status,
            eligibilityStatus,
        };
    }
}
