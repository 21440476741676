<div class="mt-3">
    <wa-cms-content
        class="h3"
        cmsKey="next_best_actions:whats_next_section_title"></wa-cms-content>
</div>

<div class="container-fluid py-2">
    <ng-container *ngFor="let action of nextBestActionConfigs">
        <div *ngIf="action.isVisible">
            <div class="row mt-3 pb-2 border-bottom">
                <div *ngIf="action.icon" class="col-1 p-0 px-md-3 text-muted">
                    <i
                        class="v2 fs-1 p-1 py-3"
                        [ngClass]="action.icon"
                        aria-hidden="true"></i>
                </div>
                <div class="col-11">
                    <div class="fw-bold">
                        <wa-cms-content
                            [attr.data-eid]="
                                'next_best_action_' +
                                action.key +
                                '_card_title'
                            "
                            [cmsKey]="
                                'next_best_actions:' +
                                action.key +
                                '_card_title'
                            "></wa-cms-content>
                    </div>
                    <wa-cms-content
                        [attr.data-eid]="
                            'next_best_action_' + action.key + '_card_text'
                        "
                        [cmsKey]="
                            'next_best_actions:' + action.key + '_card_text'
                        "></wa-cms-content>
                    <!-- TODO: add content case for unauthenticated user: next_best_actions:create_account_card_track_order_text -->
                    <div class="mt-2 mb-2">
                        <wa-button
                            [attr.data-eid]="action.key + '_button'"
                            (click)="onNextBestAction(action)"
                            [class]="'btn-primary'">
                            <wa-cms-content
                                [cmsKey]="
                                    'next_best_actions:' +
                                    action.key +
                                    '_button_text'
                                "></wa-cms-content
                        ></wa-button>
                        <!-- TODO: add content-btn case for unauthenticated user: next_best_actions:create_account_button_textt -->
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
