<ng-container *ngIf="eligibilityStatusDetails$ | async as statusDetails">
    <alert
        (click)="handleAlertClick($event)"
        data-eid="rebate-eligibility-banner"
        *ngIf="
            statusDetails.eligibilityStatus !==
            RebateEligibilityStatus.NO_REBATES
        "
        [cmsKey]="statusDetails.cmsKey"
        [status]="statusDetails.alertStatus"></alert>
</ng-container>
