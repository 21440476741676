import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'navbar-header',
    templateUrl: './navbar-header.component.html',
    styleUrls: ['./navbar-header.component.scss'],
})
export class NavbarHeaderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
