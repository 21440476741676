import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@core/navigation/navigation.service';

/**
 * Back button directive
 * This can be set on a button element which will navigate back in location history.
 *
 * Ex:
 *  <wa-button cartBackButton></wa-button>
 *  <wa-button
 *      [cartBackButton]="'../shipping'"
 *      [relativeTo]="route"
 *  ></wa-button>
 */
@Directive({
    selector: '[cartBackButton]',
})
export class BackButtonDirective {
    @Input('cartBackButton') routeTo?: string = '';
    @Input() relativeTo?: ActivatedRoute;
    @Input() forceRoute?: boolean;
    @Input() goToMarketplace?: boolean;

    constructor(private navigation: NavigationService) {}

    @HostListener('click')
    onClick(): void {
        if (this.goToMarketplace) {
            this.navigation.goToMarketplace(this.routeTo);
        } else {
            this.navigation.goBack(
                this.routeTo,
                this.relativeTo,
                this.forceRoute,
            );
        }
    }
}
