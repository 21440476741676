<div class="d-flex justify-content-between">
    <div class="d-flex gap-3 flex-fill">
        <div class="text-sm-center">
            <product-image
                [imagePublicId]="imageId"
                [alt]="title"></product-image>
        </div>
        <div
            class="header"
            [ngClass]="cartItem?.quantity ? 'col-7 col-sm-9' : 'col'">
            <div data-eid="product-model" class="fw-bold small">
                {{ title }}
            </div>
            <div data-eid="product-name">
                {{ description }}
            </div>
            <div class="d-flex mt-2 small">
                <div
                    [ngClass]="{
                        'text-decoration-line-through':
                            cartItem?.totalAppliedDiscountAmount > 0
                    }">
                    {{ cartItem?.totalFullPrice | currency }}
                </div>
                <div *ngIf="cartItem?.totalAppliedDiscountAmount > 0">
                    &nbsp;
                    {{
                        cartItem?.totalAppliedDiscountAmount
                            | currency : 'USD' : 'symbol' : '1.0-2'
                    }}
                    <wa-cms-content
                        [cmsKey]="'cart:item:off'"></wa-cms-content>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-between">
        <div
            class="text-end"
            [ngClass]="{ 'd-flex gap-2': !hasEditableQuantity }"
            data-eid="product-quantity"
            *ngIf="cartItem?.quantity && !hasHiddenQuantity">
            <select
                *ngIf="hasEditableQuantity; else readable"
                #quantityDropdown
                class="form-select w-auto"
                [ngModel]="cartItem.quantity"
                (change)="dropdownSelected(quantityDropdown.value)"
                [disabled]="isUpdatingCart">
                <option
                    *ngFor="
                        let count of [].constructor(maxQuantity + 1);
                        let index = index
                    "
                    [value]="index">
                    {{ index }}
                </option>
            </select>
            <ng-template #readable>
                <wa-cms-content cmsKey="cart:item_quantity"></wa-cms-content>
                {{ cartItem.quantity }}
            </ng-template>
        </div>
        <div class="mt-2 text-end small">
            {{ cartItem?.priceAfterSavings | currency }}
        </div>
    </div>
</div>
