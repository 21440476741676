import {
    LoanDetails,
    Order,
    PaymentMethod,
    OrderResponse,
    OrderApprovalResponse,
} from '@common-models';

import {
    testCustomerDataFactory,
    testCustomerFromOrderFactory,
} from './customer';
import { testChargeFactory } from './charge';
import { testOrderLineFactory } from './order-line';

const testVerifyApprovalResponseData: OrderApprovalResponse = {
    cart_id: '1234',
    payment_method: 'credit_card',
    payment_valid: true,
};

export const baseOrder: Order = {
    order_id: '107939-001',
    order_number: 'BBY01-806635374760',
    cart_id: 'aPYDDaog',
    cart_charge: testChargeFactory(),
    fulfillment_partner_id: 1,
    fulfillment_partner_name: 'Best Buy',
    provider_id: 103325,
    purchase_date: '2022-03-30T16:33:48.754157Z',
    shipping_charge: testChargeFactory(),
    fees: '0.00',
    installation_total: '0.00',
    installation_date: null,
    subtotal: '1249.99',
    rebate_total: '0.00',
    total_tax: 118.75,
    order_total: '1368.74',
    customer: testCustomerDataFactory(),
    units: [],
    shipping_status: 'fulfillment_pending',
    status: 'in_progress',
    tracking_number: '',
    tracking_url: '',
    freight_cost: '0.00',
    shipped_at: null,
    payment_method: PaymentMethod.finance,
    product_count: '1',
    payment_details: {
        pre_approval_term_months: 60,
        pre_approval_apr: '18.00',
    },
    loan_details: {} as LoanDetails,
    lines: [testOrderLineFactory()],
};

export const testOrderFactory = (overrides: Partial<Order> = {}): Order => {
    return {
        ...baseOrder,
        ...overrides,
    };
};

export const testOrderResponseFactory = (
    overrides: Partial<OrderResponse> = {},
): OrderResponse => {
    return {
        ...baseOrder,
        customer: testCustomerFromOrderFactory(),
        ...overrides,
    };
};

export const testVerifyApproveResponseDataFactory = (
    overrides: Partial<OrderApprovalResponse> = {},
): OrderApprovalResponse => ({
    ...testVerifyApprovalResponseData,
    ...overrides,
});
