import { Discounts } from '../discounts.model';
import { Rebate } from '../rebate.model';

export abstract class ProductItem {
    abstract get fullPrice(): number;

    abstract get rebatesList(): Rebate[];

    abstract get salePrice(): number;

    abstract get fulfillmentPartnerId(): number;

    abstract get fulfillmentPartnerName(): string;

    protected abstract get totalAdditionalDiscounts(): number;

    /**
     * Determine if the product is on sale based on the full price
     * and regular price.
     */
    get isOnSale(): boolean {
        return this.fullPrice !== this.salePrice;
    }

    /**
     * Get the sale discounted amount.
     */
    get saleDiscountAmount(): number {
        return this.fullPrice - this.salePrice;
    }

    /**
     * Create an object of discounts. Does not include rebate discounts
     */
    get discountsWithoutRebates(): Discounts {
        return {
            additional: this.totalAdditionalDiscounts,
            sales: this.saleDiscountAmount,
        };
    }
}
