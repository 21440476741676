<footer class="bg-black text-white">
    <div class="container py-4">
        <div class="large fw-bold pb-1">
            <wa-cms-content cmsKey="cart:footer:legalese"></wa-cms-content>
        </div>
        <div class="py-1">
            <a
                href="https://enervee.com/terms"
                class="text-white text-decoration-underline"
                target="”_blank”">
                <wa-cms-content
                    cmsKey="cart:footer:terms_and_conditions"></wa-cms-content>
            </a>
        </div>
        <div class="py-1">
            <a
                href="https://enervee.com/privacy"
                class="text-white text-decoration-underline"
                target="”_blank”">
                <wa-cms-content
                    cmsKey="cart:footer:privacy_policy"></wa-cms-content>
            </a>
        </div>
        <div class="small py-1">
            <wa-cms-content cmsKey="cart:footer:copyright"></wa-cms-content>
        </div>
    </div>
</footer>
