import { Pipe, PipeTransform } from '@angular/core';

/**
 * Accepts a military time with a colon, eg 13:30, and converts
 * it to standard time with period denotation, eg 1:30 pm.
 * Removes any leading zeros from the hour.
 *
 * eg: {{ "17:30" | standardTime }}  // "5:30 pm"
 * eg: {{ "02:45" | standardTime }}  // "2:45 am"
 */
@Pipe({ name: 'standardTime' })
export class StandardTime implements PipeTransform {
    transform(time: string): string {
        const [hour, min] = time.split(':');
        let period = 'am';
        let correctedHour = Number(hour);
        if (correctedHour >= 12) {
            period = 'pm';
            correctedHour = correctedHour > 12 ? correctedHour - 12 : 12;
        }

        return `${correctedHour}:${min} ${period}`;
    }
}
