import { Component, OnInit, Renderer2 } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import {
    testCartFactory,
    testCartItemFactory,
    testCustomerDataFactory,
    testOrderFactory,
} from '@common-mocks';
import { PaymentMethod } from '@common-models';
import { CartService } from '@core/cart/cart.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { GeoService } from '@core/geo/geo.service';
import { ScheduleWindowService } from '@core/schedule-window/schedule-window.service';
import { CustomerDetailService } from '@core/customer-detail/customer-detail.service';
import { SkepsService } from '@core/skeps/skeps.service';
import { FinanceService } from '@core/finance/finance.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'debugger',
    templateUrl: './debugger.component.html',
    styleUrls: ['./debugger.component.scss'],
})

/**
 * @description
 *
 * This component is for devemplent use.
 * Calling endpoints thats needed to get real data for Cart and Checkout.
 *
 * At the bottom of the page you will see the debugger component,
 * you can click the buttons from left to right to fire endpoints,
 * wait until each endpoint returns then click the next one.
 * These are the minimum amount of endpoint calls we need to make to get Cart and Checkout data.
 * In the input box on the far right you can enter a product id, and use `addProduct` to add to cart.
 */
export class DebuggerComponent implements OnInit {
    incentiveForm = false;
    incentiveFormUrl: SafeResourceUrl;
    productId = 165180961;
    deliveryDatetime: string;
    deliveryDate: string;
    customerId: string;
    applicationId: string;
    loading = false;
    zipcode: string;
    isCartFinanceable$: Observable<boolean>;

    constructor(
        private cartService: CartService,
        private geoService: GeoService,
        private scheduleWindowService: ScheduleWindowService,
        private customerDetailService: CustomerDetailService,
        private skepsService: SkepsService,
        private renderer: Renderer2,
        private financeService: FinanceService,
    ) {}

    ngOnInit(): void {
        this.isCartFinanceable$ = this.financeService.isCartFinanceable$;
    }

    getZipcode(): void {
        this.geoService.getZipcode().subscribe((data) => {
            this.zipcode = data;
            console.log('getZipcode', this.zipcode);
        });
    }

    createCart() {
        this.loading = true;
        this.cartService.createCart().subscribe((data) => {
            console.log('createCart', data);
            this.loading = false;
        });
    }

    addProduct(): void {
        this.loading = true;
        const payload = testCartItemFactory({
            addons: [],
            id: this.productId,
            quantity: 1,
        });
        this.cartService.addProduct(payload).subscribe((data) => {
            console.log('addProduct', data);
            this.loading = false;
        });
    }

    getCustomerId(): void {
        this.loading = true;
        this.customerDetailService.getCustomerId().subscribe((data) => {
            console.log('getCustomerId', data);
            this.customerId = data;
            this.loading = false;
        });
    }

    applicationCreateOrUpdate(): void {
        this.loading = true;
        this.customerDetailService
            .applicationCreateOrUpdate()
            .subscribe((data) => {
                console.log('applicationCreateOrUpdate', data);
                this.applicationId = data.application_id;
                this.loading = false;
            });
    }

    openIncentiveForm(): void {
        this.cartService.cart$
            .pipe(
                switchMap((cart) =>
                    this.customerDetailService.generateIframeUrl(
                        cart.id,
                        false,
                    ),
                ),
                take(1),
            )
            .subscribe((res) => {
                this.incentiveFormUrl = res;
            });
        this.incentiveForm = true;
    }

    closeIncentiveForm(): void {
        this.incentiveForm = false;
    }

    getScheduleWindows(): void {
        this.loading = true;
        this.scheduleWindowService
            .getScheduleWindows([this.productId], 1)
            .subscribe((data) => {
                console.log('getScheduleWindows', data);
                const deliveryOptions = data[this.productId].delivery_options;
                const date = Object.keys(deliveryOptions)[0];
                const time = deliveryOptions[date][0].from;
                const dateArr = date.split('/');
                const formattedDate = [
                    dateArr[2],
                    dateArr[0],
                    dateArr[1],
                ].join('-');
                this.deliveryDatetime = `${formattedDate}T${time}`;
                this.deliveryDate = formattedDate;
                this.loading = false;
            });
    }

    updateCart(): void {
        this.loading = true;
        this.cartService
            .updateCart([
                testCartItemFactory({
                    addons: [],
                    id: this.productId,
                    quantity: 1,
                    delivery_datetime: this.deliveryDatetime,
                    shipping_date: this.deliveryDate,
                }),
            ])
            .subscribe((data) => {
                console.log('updateCart', data);
                this.loading = false;
            });
    }

    getCart() {
        this.loading = true;
        this.cartService.requestCart().subscribe((data) => {
            console.log('requestCart', data);
            this.loading = false;
        });
    }

    checkout(): void {
        this.loading = true;
        this.cartService
            .createOrder(PaymentMethod.finance)
            .subscribe((data) => {
                console.log('checkout', data);
                this.loading = false;
            });
    }

    getFinalBill(): void {
        this.loading = true;
        this.cartService.getCartFinalBill().subscribe((data) => {
            console.log('getFinalBill', data);
            this.loading = false;
        });
    }

    initSkepsFlow(): void {
        this.skepsService
            .startApplication(
                testCartFactory(),
                testCustomerDataFactory(),
                testOrderFactory().order_id,
                this.renderer,
                console.log,
            )
            .subscribe((script) => {
                console.log('initSkepsFlow', script);
            });
    }
}
