import { cloneDeep } from 'lodash';
import { Addon, AddonForUI, RequiredAddons } from '@common-models';
import { AddonResponse } from '@core/addon/addon-response.model';
import { testProductFactory } from './product';

const baseAddon: Addon = {
    name: 'installation',
    price: 129.99,
    service_id: '9525109',
};

export const testAddonFactory = (overrides: Partial<Addon> = {}): Addon => {
    return cloneDeep({
        ...baseAddon,
        ...overrides,
    });
};

export const UIAddon: AddonForUI = {
    ...baseAddon,
    selectorName: 'installation',
};

export const testUIAddonFactory = (
    overrides: Partial<AddonForUI> = {},
): AddonForUI => {
    return cloneDeep({
        ...UIAddon,
        ...overrides,
    });
};

const requiredAddons: RequiredAddons = {
    installation: 'Installation is required for this category',
};

export const testRequiredAddons = (
    overrides: Partial<RequiredAddons> = {},
): RequiredAddons => {
    return cloneDeep({
        ...requiredAddons,
        ...overrides,
    });
};

export const testAddonResponseFactory = (
    overrides: Partial<AddonResponse> = {},
): AddonResponse => {
    return cloneDeep({
        [testProductFactory().id]: [
            testAddonFactory(),
            testAddonFactory({ ...testAddonFactory(), name: 'haulaway' }),
        ],
        ...overrides,
    });
};
