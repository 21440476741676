import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CartPageComponent } from './pages/cart-page/cart-page.component';
import { OrderSummaryPageComponent } from './pages/order-summary-page/order-summary-page.component';

import { CategoriesResolverService } from '@core/categories/categories-resolver.service';
import { ProductResolverService } from '@core/product/product-resolver.service';
import { CartResolverService } from '@core/cart/cart-resolver.service';
import { AnalyticsResolverService } from '@core/analytics/analytics-resolver.service';
import { blockUrlGuard } from '@core/guards/blocked-url.guard';
import { GeneralErrorPageComponent } from '@shared-components/general-error-page/general-error-page.component';

export const routes: Routes = [
    {
        path: '',
        canActivate: [blockUrlGuard],
        children: [
            {
                path: '',
                resolve: {
                    analytics: AnalyticsResolverService,
                    cartInitialized: CartResolverService,
                },
                component: CartPageComponent,
                pathMatch: 'full',
            },
            {
                path: 'checkout',
                resolve: {
                    analytics: AnalyticsResolverService,
                    cartInitialized: CartResolverService,
                },
                loadChildren: () =>
                    import('./pages/checkout-page/checkout-page.module').then(
                        (m) => m.CheckoutPageModule,
                    ),
            },
            {
                path: 'customer-details',
                resolve: {
                    analytics: AnalyticsResolverService,
                    cartInitialized: CartResolverService,
                },
                loadChildren: () =>
                    import(
                        './pages/customer-details-page/customer-details-page.module'
                    ).then((m) => m.CustomerDetailsPageModule),
            },
            {
                path: 'order-summary/:orderId',
                resolve: { analytics: AnalyticsResolverService },
                component: OrderSummaryPageComponent,
            },
            { path: '404', component: GeneralErrorPageComponent },
            { path: '**', redirectTo: '404' },
            {
                path: ':categoryCode',
                resolve: { category: CategoriesResolverService },
                children: [
                    {
                        path: ':productId',
                        resolve: {
                            analytics: AnalyticsResolverService,
                            cartInitialized: CartResolverService,
                            product: ProductResolverService,
                        },
                        loadChildren: () =>
                            import(
                                './pages/add-product-page/add-product-page.module'
                            ).then((m) => m.AddProductPageModule),
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
