<div
    [ngClass]="cssClasses"
    class="alert alert-{{ status }} text-body d-{{
        display ?? 'flex'
    }} align-items-baseline"
    role="alert">
    <i class="v2 me-2" [ngClass]="getAlertClasses()"> </i>
    <wa-cms-content
        *ngIf="cmsKey; else elseBlock"
        cmsKey="{{ cmsKey }}"
        [context]="cmsContext"></wa-cms-content>
    <ng-template #elseBlock><ng-content></ng-content></ng-template>
</div>
