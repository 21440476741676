import { Rebate } from '@common-models';

export const baseRebate: Rebate = {
    id: 5314,
    amount: '4.00',
    start_date: '2021-01-01',
    end_date: '2021-12-31',
    category_id: 54,
    provider_id: 100819,
    provider_name: 'Con Edison',
    provider_long_name: 'Consolidated Edison Co-NY Inc',
    link: 'https://marketplace.coned.com/lightbulbs/',
    logo: '',
    quantity_limit: 25,
    kit: null,
    is_instant: true,
    link_text: null,
    title: 'rebate',
    description: '',
    marketplace_tag_text: null,
    business_rule_codes: ['EE'],
    hidden: false,
    highestInType: true,
    eligible: true,
};

export const testRebateFactory = (overrides: Partial<Rebate> = {}): Rebate => {
    return {
        ...baseRebate,
        ...overrides,
    };
};
