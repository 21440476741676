<div class="mt-3">
    <wa-cms-content
        cmsKey="cart:item:shipping_option"
        class="fw-bold"></wa-cms-content>

    <div *ngIf="radioOptions$ | async as radioOptions">
        <wa-radio-selector
            [cssClasses]="'mt-2'"
            [id]="cartItem.id"
            [options]="radioOptions"
            (selectOption)="selectOption($event)"
            [selected]="selected"
            [disabled]="isUpdatingCart">
        </wa-radio-selector>
    </div>
</div>
