import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralErrorPageViewState, PageNotFoundState } from '@common-models';
import { catchError, map, Observable, of } from 'rxjs';

@Component({
    selector: 'general-error-page',
    templateUrl: './general-error-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralErrorPageComponent implements OnInit {
    state$: Observable<GeneralErrorPageViewState>;

    constructor(
        public route: ActivatedRoute,
        private router: Router,
        private location: Location,
    ) {}

    ngOnInit() {
        this.state$ = this.route.paramMap.pipe(
            map(() => this.location.getState() as GeneralErrorPageViewState),
            map((state: GeneralErrorPageViewState) => {
                return state.pageTitleTextsConfig ? state : PageNotFoundState;
            }),
            catchError((error: Error) => {
                return of(PageNotFoundState);
            }),
        );
    }

    redirectTo(url: string): void {
        this.router.navigate([url], { queryParamsHandling: 'merge' });
    }
}
