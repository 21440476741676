import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    Cart,
    EcoFinancingCmsAprContext,
    EcoFinancingLogo,
    EcoFinancingModalProList,
} from '@common-models';
import { Modal } from 'bootstrap';
import { FinanceService } from '@core/finance/finance.service';
import { CartService } from '@core/cart/cart.service';
import { CmsService } from '@core/cms/cms.service';
import { map, Observable } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'eco-financing-modal',
    templateUrl: './eco-financing-modal.component.html',
    styleUrls: ['./eco-financing-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcoFinancingModalComponent implements OnInit {
    cart$: Observable<Cart>;
    ecoFinanceLogo: EcoFinancingLogo;
    cmsAprContexts$: Observable<EcoFinancingCmsAprContext[]>;
    learnMoreHref$: Observable<string>;
    ecoFinancingModalProList = EcoFinancingModalProList;

    constructor(
        private currencyPipe: CurrencyPipe,
        private financeService: FinanceService,
        private cartService: CartService,
        private cmsService: CmsService,
    ) {}

    ngOnInit(): void {
        this.cart$ = this.cartService.cart$;
        this.ecoFinanceLogo = this.financeService.ecoFinancingLogo;
        this.setupViewTexts();
    }

    private setupViewTexts() {
        this.cmsAprContexts$ = this.cart$.pipe(
            map((cart: Cart) => {
                // Calculate monthly payment for 2 loan duration, 60 month and 12 month
                const durations = [60, 12];
                return durations.map((duration) => {
                    const monthlyTotal =
                        this.financeService.calculateMonthlyPayment(
                            cart.displayTotal,
                            duration,
                        );
                    return {
                        monthlyTotal:
                            this.currencyPipe.transform(monthlyTotal),
                        termLength: duration,
                        aprRate: `${this.financeService.APR}%`,
                    };
                });
            }),
        );
        this.learnMoreHref$ = this.cmsService.getMarketPlaceText(
            'base:modals:financing_learn_more_href',
        );
    }

    /**
     * Close modal.
     */
    continueShopping = () => {
        const modal = Modal.getInstance(
            document.getElementById('ecoFinancingModal'),
        );
        modal.hide();
    };

    /**
     * Return a function that opens learn more link on new browser tab.
     */
    createLearnMoreLinkCallback(href: string): () => void {
        return () => {
            const url = href || '/support/';
            window.open(url, '_blank');
        };
    }
}
