<ng-container
    *ngIf="{
        cart: cart$ | async
    } as states">
    <div class="container-fluid border rounded bg-white p-4 mb-4 d-grid gap-3">
        <div class="d-grid gap-3">
            <text-label
                data-eid="order-details-marketplace-order-id"
                [cmsKeys]="{ label: 'orders:enervee_order_id' }"
                [displayConfig]="{
                    isHorizontal: false,
                    labelStyles: 'fw-bold'
                }"
                [directTexts]="{ value: order.order_id }">
            </text-label>
            <text-label
                data-eid="order-details-fulfillment-id"
                *ngIf="externalPartnerOrderLink"
                [displayConfig]="{
                    isHorizontal: false,
                    labelStyles: 'fw-bold'
                }"
                [directTexts]="{ label: this.externalPartnerDetails.label }">
                <div value class="d-grid gap-0">
                    <a
                        *ngIf="externalPartnerOrderLink"
                        [href]="externalPartnerOrderLink"
                        (click)="onExternalOrderClick()"
                        target="_blank">
                        {{ this.order.order_number }}
                    </a>
                    <span
                        class="small text-muted"
                        *ngIf="this.externalPartnerDetails.helper_text">
                        {{ this.externalPartnerDetails.helper_text }}
                    </span>
                </div>
            </text-label>
            <item-details
                *ngFor="let cartItem of states.cart?.products"
                [cartItem]="cartItem"
                [isEditable]="false">
            </item-details>
            <total-details></total-details>
            <text-label
                data-eid="order-details-purchase-date"
                [cmsKeys]="{ label: 'orders:purchase_date' }"
                [displayConfig]="{
                    isHorizontal: false,
                    labelStyles: 'fw-bold'
                }"
                [directTexts]="{
                    value: order.purchase_date | date : 'longDate'
                }">
            </text-label>
            <text-label
                data-eid="order-details-shipping-address"
                [cmsKeys]="{ label: 'orders:shipping_address' }"
                [displayConfig]="{
                    isHorizontal: false,
                    labelStyles: 'fw-bold'
                }">
                <div value class="d-grid gap-0">
                    <span>
                        {{ order.customer.first_name | titlecase }}
                        {{ order.customer.last_name | titlecase }}
                    </span>
                    <span>{{ order.customer.address_line_1 }}</span>
                    <span>{{ order.customer.address_line_2 }}</span>
                    <span>
                        {{ order.customer.city }},
                        {{ order.customer.state }}
                        {{ order.customer.zip_code }}
                    </span>
                </div>
            </text-label>
        </div>
        <hr class="m-0" />
        <print-button
            [fileName]="orderReceiptFileName"
            [cmsKey]="'cart:print_order_receipt_button'"></print-button>
    </div>
</ng-container>
