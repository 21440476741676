<div
    *ngIf="{
        deliveryTimes: deliveryTimes$ | async,
        datepickerPlaceholderText: datepickerPlaceholderText$ | async,
        timepickerPlaceholderText: timepickerPlaceholderText$ | async
    } as viewStates"
    [attr.data-eid]="'delivery-option-' + cartItem.id">
    <div class="mt-3 mb-2">
        <div data-eid="page-title-description" class="fw-bold">
            <wa-cms-content
                cmsKey="cart:delivery_installation:delivery_text"></wa-cms-content>
            <zipcode></zipcode>
        </div>
    </div>
    <div class="d-flex">
        <form class="w-50 me-1" data-eid="delivery-option-datepicker">
            <label for="date-selector" class="small">
                <wa-cms-content
                    cmsKey="cart:delivery_installation:date"></wa-cms-content>
            </label>
            <div class="input-group">
                <input
                    readonly
                    ngbDatepicker
                    name="dp"
                    id="date-selector"
                    #d="ngbDatepicker"
                    class="form-control text-small datepicker border-end-0"
                    [(ngModel)]="dateModel"
                    [placeholder]="viewStates.datepickerPlaceholderText"
                    [markDisabled]="isDateDisabled"
                    [disabled]="isLoading"
                    (dateSelect)="dateSelected()"
                    (click)="d.toggle()" />
                <button
                    class="btn border-start-0 py-0 px-2 text-body"
                    [ngClass]="
                        isLoading
                            ? 'border-secondary bg-light opacity-100'
                            : 'border-dark'
                    "
                    [disabled]="isLoading"
                    (click)="d.toggle()"
                    type="button">
                    <i class="v2 v2-calendar"></i>
                </button>
            </div>
        </form>
        <div class="w-50">
            <label for="delivery-time" class="small">
                <wa-cms-content
                    cmsKey="cart:delivery_installation:time"></wa-cms-content>
            </label>
            <select
                id="delivery-time"
                data-eid="delivery-option-timepicker"
                #timeDropdown
                class="form-select text-small"
                [ngClass]="{ 'text-muted': !timeFrom }"
                [(ngModel)]="timeFrom"
                [disabled]="isTimeDisabled"
                (change)="saveDeliverySelection()">
                <option [ngValue]="undefined" [selected]="!timeFrom" disabled>
                    {{ viewStates.timepickerPlaceholderText }}
                </option>
                <option
                    *ngFor="let time of viewStates.deliveryTimes"
                    [ngValue]="time.from">
                    {{ time.from | standardTime }} -
                    {{ time.to | standardTime }}
                </option>
            </select>
        </div>
    </div>
</div>
