import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { StylingService } from '@core/styling/styling.service';
import { SettingsService } from '@core/settings/settings.service';
import { BehaviorSubject } from 'rxjs';
import { testUserDetailsFactory } from './mocks/analytics';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CmsService } from '@core/cms/cms.service';

/**
 * @description
 *
 * The main entry point for the application.
 */
/**
 * @description
 *
 * An auto-unsubscribe package.
 * Use with `.pipe(untilDestroyed(this)) before subscribing.
 */
@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    isDebuggerEnabled: boolean;
    isDataLoading = false;

    constructor(
        private analyticsService: AnalyticsService,
        private cmsService: CmsService,
        private settingsService: SettingsService,
        private stylingService: StylingService,
        private titleService: Title,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.cmsService
            .getMarketPlaceText('cart:title')
            .pipe(untilDestroyed(this))
            .subscribe((title) => {
                this.titleService.setTitle(title);
            });
        this.isDebuggerEnabled =
            this.settingsService.isCartFeatureEnabled('debugger');

        this.stylingService.appendCSS();

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.isDataLoading = true;
            }
            if (event instanceof NavigationEnd) {
                this.isDataLoading = false;
                this.analyticsService.trackPage();
            }
        });

        // Manual testing of segment library
        this.analyticsService.getUserDetails = () => {
            return new BehaviorSubject(testUserDetailsFactory());
        };
        this.analyticsService.getUserStatus = () => {
            return new BehaviorSubject('Anonymous');
        };
    }

    trackClick() {
        const trackingData = {
            location: 'mainContent',
            type_of_change: 'proceed to checkout',
            source: 'url param',
        };
        this.analyticsService.reportEvent('Button Clicked', trackingData);
    }
}
