import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly storage: Storage | undefined;

    constructor() {
        this.storage = localStorage;
    }

    /**
     * Parse value from local storage, and provide optional default value if
     * no key is found in the local storage.
     */
    getItem<T>(key: string, defaultValue: T = null): T | null {
        const data = this.storage?.getItem(key);
        if (!this.storage || !data) {
            return defaultValue;
        }
        return JSON.parse(data);
    }

    /**
     * Stores a value to localStorage with the provide key value pair
     */
    setItem<T>(key: string, value: T): void {
        this.storage?.setItem(key, JSON.stringify(value));
    }

    /**
     * Removes a value from localStorage
     */
    removeItem(key: string): void {
        this.storage?.removeItem(key);
    }

    /**
     * Clears the localStorage
     */
    clear(): void {
        this.storage?.clear();
    }
}
