import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    Addon,
    BootstrapStatus,
    CartItem,
    DeliveryShippingOptions,
    FulfillmentPartnerCodes,
    Product,
    ScheduleWindows,
    ShippingOption,
} from '@common-models';
import { CmsService } from '@core/cms/cms.service';
import { SettingsService } from '@core/settings/settings.service';
import _ from 'lodash';
import { RebateEligibilityStatus } from '@core/rebate/rebate-eligibility-status.model';
import { CartService } from '@core/cart/cart.service';
import { SwiftypeService } from '@core/swiftype/swiftype.service';

@Component({
    selector: 'item-details',
    templateUrl: './item-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailsComponent implements OnInit {
    readonly BootstrapStatus = BootstrapStatus;
    @Input() cartItem: CartItem;
    @Input() cartRebateEligibilityStatus: RebateEligibilityStatus;
    @Input() isFetchingScheduleWindows: boolean;
    @Input() scheduleWindows: ScheduleWindows;
    @Input() isUpdatingCart: boolean;
    @Input() isEditable: boolean;
    @Input() isFetchingAddons: boolean;
    @Input() addons: Addon[];
    @Output() updateItemAddons = new EventEmitter<CartItem>();

    utilityRebateTitle: string;
    utilityRebateText: string;

    private utilityName: string;

    constructor(
        private settingsService: SettingsService,
        private cartService: CartService,
        private swiftypeService: SwiftypeService,
        private cmsService: CmsService,
    ) {
        this.utilityName = this.settingsService.getSiteSetting(
            'title',
            '',
        ) as string;
    }

    ngOnInit(): void {
        this.cmsService
            .getMarketPlaceText('cart_page:cart_item_rebate_title', {
                utilityName: this.utilityName,
            })
            .subscribe((text) => {
                this.utilityRebateTitle = text;
            });
        this.cmsService
            .getMarketPlaceText('cart_page:cart_item_rebate_text', {
                utilityName: this.utilityName,
            })
            .subscribe((text) => {
                this.utilityRebateText = text;
            });
    }

    /**
     * Get product from swiftype for cart item.
     */
    get product(): Product {
        return this.swiftypeService.getProductForCartItem(this.cartItem.id);
    }

    /**
     * Using fulfillment partner name, replaces '.com' and any spaces to
     * set marketplace text key
     */
    get fulfillmentPartnerKey(): string {
        const fulfillmentPartner = _.lowerCase(
            this.cartItem.fulfillment_partner_name.replace('.com', ''),
        ).replace(/\s/g, '_');
        const prefix = 'cart:';
        const key = `${fulfillmentPartner}_ship_message`;
        return prefix + key;
    }

    /**
     * Delivery Options for a cart item
     */
    get deliveryOptions():
        | DeliveryShippingOptions['delivery_options']
        | undefined {
        if (!this.scheduleWindows) {
            return undefined;
        }
        return this.scheduleWindows[this.cartItem.id]?.delivery_options;
    }

    /**
     * ShippingOptions for a cart item
     */
    get shippingOptions(): ShippingOption[] | undefined {
        if (!this.scheduleWindows) {
            return undefined;
        }
        return this.scheduleWindows[this.cartItem.id]?.shipping_options;
    }

    /**
     * Checks if deliverable product
     */
    get isDeliverable(): boolean {
        return !Boolean(_.isEmpty(this.deliveryOptions));
    }

    /**
     * Value to check if a product is shippable based just off the cart item
     * and not off any scheduleWindows data
     */
    get isShippable(): boolean {
        return !Boolean(
            this.cartItem.addons?.find(
                (addon: Addon) => addon.name === 'installation',
            ),
        );
    }

    get isLightbulbs(): boolean {
        return (
            this.cartItem.fulfillment_partner_id ===
            FulfillmentPartnerCodes.lightbulbs
        );
    }

    get isWaterHeaterWarehouse(): boolean {
        return (
            this.cartItem.fulfillment_partner_id ===
            FulfillmentPartnerCodes.water_heater_warehouse
        );
    }

    get isUnavailable(): boolean {
        return this.cartService
            .getUnavailableProducts(this.scheduleWindows)
            .some((item) => item.id === this.cartItem.id);
    }

    /**
     * Returns the shipping cost of the item if any shipping has been selected
     */
    get shippingAmount(): number {
        if (this.shippingOptions && this.cartItem.shipping_id) {
            const selectedShipping = this.shippingOptions.find(
                (opt: ShippingOption) =>
                    opt.shipping_id === this.cartItem.shipping_id,
            );
            return selectedShipping?.price || 0;
        } else {
            return 0;
        }
    }

    /**
     * Returns the total value of the item after all addons, accessories, or
     * shipping has been selected factoring in the eligible discount amounts
     * and item quantities where applicable
     */
    get itemTotal(): number {
        const total = this.cartItem.totalFullPrice;
        const discountAmount = this.cartItem.totalAppliedDiscountAmount;
        const addonsAmount = this.cartItem.addonsAmount;
        const shippingAmount = this.shippingAmount;
        return total + addonsAmount + shippingAmount - discountAmount;
    }

    /**
     * Returns an eid for the item's addons
     */
    createAddonSelector(addon: Addon): string {
        // Spaces are invalid in selectors so we replace them.
        return _.kebabCase(`item-details-addon-${addon.name}`);
    }

    /**
     * Emits event to update addons from a cart item.
     */
    updateAddons(cartItem: CartItem): void {
        this.updateItemAddons.emit(cartItem);
    }
}
