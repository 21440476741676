/**
 * NO_REBATES: ALL products in cart has NO rebates available regardless of if the
 * customer has or has not filled out the rebate form
 *
 * MISSING_DETAILS: Cart has rebates available but has not completed rebate form
 *
 * NOT_ELIGIBLE: Customer has completed rebate form AND customer is NOT ELIGIBLE
 *               for rebates on ANY products on the cart
 *
 * PARTIALLY_ELIGIBLE: Customer has completed rebate form AND customer is PARTIALLY ELIGIBLE
 *                     for SOME rebates for the products on the cart
 *
 * FULLY_ELIGIBLE: Customer has completed rebate form AND customer is FULLY ELIGIBLE
 *                 for ALL rebates for the products within the cart
 */
export enum RebateEligibilityStatus {
    NO_REBATES = 'no_rebates',
    MISSING_DETAILS = 'missing_details',
    NOT_ELIGIBLE = 'not_eligible',
    PARTIALLY_ELIGIBLE = 'partially_eligible',
    FULLY_ELIGIBLE = 'fully_eligible',
}
