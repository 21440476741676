<wa-modal-wrapper
    [id]="'editZipcodeModal'"
    [confirmButton]="{ label: 'Confirm', action: onFormSubmit }"
    [closeButton]="{ label: 'Close' }"
    [metadata]="{ zipcodeMetadata, products: [product] }">
    <wa-cms-content
        header
        class="fw-bold fs-3"
        cmsKey="base:modals:update_zipcode_title">
    </wa-cms-content>
    <div body>
        <wa-cms-content
            class="fs-5"
            cmsKey="base:modals:update_zipcode_description">
        </wa-cms-content>
        <form
            class="mt-3 needs-validation"
            novalidate
            #form="ngForm"
            [formGroup]="zipcodeForm"
            (ngSubmit)="onSubmit()">
            <input
                type="tel"
                id="edit-zipcode-input"
                data-eid="edit-zipcode-input"
                class="form-control input-group has-validation"
                [ngClass]="{
                    'is-invalid': form.submitted && zipcodeForm.invalid
                }"
                placeholder="Zip code"
                formControlName="zipcode" />
            <div class="invalid-feedback">
                <wa-cms-content
                    data-eid="edit-zipcode-error-message"
                    cmsKey="base:modals:update_zipcode_error_message">
                </wa-cms-content>
            </div>
        </form>
    </div>
</wa-modal-wrapper>
