export interface HpfCallback {
    key: HpfCallbackName;
    cb: (data: any) => void; // TODO: Updates args typings
}

export enum HpfCallbackName {
    hpfReady = 'hpfReady',
    startPayment = 'startPayment',
    completePayment = 'completePayment',
    cancelPayment = 'cancelPayment',
    handlePaymentErrors = 'handlePaymentErrors',
    whatsThis = 'whatsThis',
    whatCVV2 = 'whatCVV2',
}

export interface HpfErrorData {
    errorCode?: string[];
    gatewayCode?: number;
    gatewayMessage?: string;
}

export interface WhatsThisModalImageConfig {
    cloudinary_id: string;
    alt: string;
}
