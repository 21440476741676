<div
    class="d-grid"
    *ngIf="{
        cart: cart$ | async,
        context: cmsContext$ | async,
        isCheckoutDisabled: isCheckoutDisabled$ | async,
        isNavigating: isNavigating$ | async
    } as states">
    <wa-button
        class="rounded-1"
        [attr.data-eid]="'payment-method-button-' + paymentMethod"
        (click)="continueToPayment(states.cart.id)"
        [isDisabled]="
            states.isCheckoutDisabled ||
            hasUnavailableProduct ||
            states.isNavigating
        "
        [isLoading]="states.isNavigating"
        [buttonStyle]="isFinance ? 'default' : 'dark'"
        [isFullWidth]="true">
        <wa-cms-content
            [cmsKey]="'cart:payment_method_button_' + paymentMethod">
        </wa-cms-content>
    </wa-button>
</div>
