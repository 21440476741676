import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import {
    AddonForUI,
    Cart,
    DeliveryShippingOptions,
    Product,
    ScheduleWindows,
} from '@common-models';
import { CAPI_PROXY_URL_v2 } from '@core/constants';
import { PRODUCT_API_URL_SETTING } from '@server/constants';
import { SettingsService } from '@core/settings/settings.service';
import { GeoService } from '@core/geo/geo.service';
import { SimpleStore } from '@core/simple-store/simple-store.service';
import { AddonService } from '@core/addon/addon.service';
import { AddonsPayload } from '@core/schedule-window/addons-payload.model';
import { handleGenericHttpError } from '@utils/handle-http-error';
import { CartService } from '@core/cart/cart.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleWindowService {
    private baseProductApiUrl: string;
    private _isFetchingScheduleWindows$ = new BehaviorSubject(false);
    private utilityIds: string[];

    constructor(
        private addonService: AddonService,
        private cartService: CartService,
        private http: HttpClient,
        private geoService: GeoService,
        private settingsService: SettingsService,
        private simpleStore: SimpleStore,
    ) {
        this.baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.utilityIds = this.settingsService.getSiteSetting(
            'utility_include_list',
        ) as string[];
    }

    get isFetchingScheduleWindows$(): Observable<boolean> {
        return this._isFetchingScheduleWindows$.asObservable();
    }

    /**
     * Gets the earliest arrival date for a product. Includes the
     * addons & any selected addons if passed in that may affect the
     * earliest arrival date.
     */
    getEarliestArrivalDateForProduct(
        product: Product,
        addons?: AddonForUI,
        selectedAddons?: Array<AddonForUI>,
    ): Observable<string> {
        let addonPayload: AddonsPayload | undefined;
        if (addons) {
            const combinedAddons = this.addonService.combineAddons(
                addons,
                selectedAddons,
            );
            addonPayload = this.formatAddonsForRequest(
                {},
                product.id,
                combinedAddons,
            );
        }

        return this.getScheduleWindows(
            [product.id],
            product.fulfillmentPartnerId,
            addonPayload,
        ).pipe(
            map((scheduleWindow: ScheduleWindows) => {
                return this.calculateEarliestDateForProduct(
                    scheduleWindow[product.id],
                );
            }),
        );
    }

    /**
     * Calculates what the earliest arrival date is for a product given
     * the available schedule windows.
     *
     * If a delivery option is available then we use the first delivery
     * option provided by the backend.
     * If there is only shipping options then we use standard shipping
     * option which is the last option provided by the backed.
     * If there are no delivery or shipping options then we return null.
     */
    calculateEarliestDateForProduct(
        deliveryShippingOptions: DeliveryShippingOptions,
    ): string {
        const deliveryOptions = deliveryShippingOptions?.delivery_options;
        const shippingOptions = deliveryShippingOptions?.shipping_options;

        if (deliveryOptions && Object.keys(deliveryOptions).length) {
            return Object.keys(deliveryOptions)[0];
        } else if (shippingOptions?.length) {
            const standardShipping =
                shippingOptions[shippingOptions.length - 1];
            return standardShipping.estimated_date;
        } else {
            return null;
        }
    }

    /**
     * Gets the schedule windows for any number of products and it's addons
     * Makes the schedule windows request based on the zipcode that is
     * determined to be used by the GeoService.
     *
     * Caches the response that we get back from the request, unless the
     * cache is bypassed via bypassCache boolean
     */
    getScheduleWindows(
        productIds: Array<number>,
        fulfillmentPartnerId: number,
        addonsPayload?: AddonsPayload,
        bypassCache = false,
    ): Observable<ScheduleWindows> {
        this._isFetchingScheduleWindows$.next(true);
        return this.geoService.getZipcode().pipe(
            switchMap((zipcode) => {
                return bypassCache
                    ? this.requestScheduleWindows(
                          productIds,
                          zipcode,
                          fulfillmentPartnerId,
                          addonsPayload,
                      )
                    : this.requestScheduleWindowsWithCache(
                          productIds,
                          zipcode,
                          fulfillmentPartnerId,
                          addonsPayload,
                      );
            }),
            take(1),
            finalize(() => this._isFetchingScheduleWindows$.next(false)),
        );
    }

    /**
     * Uses the simpleStore to cache the request for a given list of
     * productIds, addons, and zipcode.
     * If there is no zipcode provided then avoids making a request to
     * fetch the schedule windows.
     */
    requestScheduleWindowsWithCache(
        productIds: Array<number>,
        zipcode: string,
        fulfillmentPartnerId: number,
        addonsPayload?: AddonsPayload,
    ): Observable<ScheduleWindows> {
        if (!zipcode) {
            return of({});
        }
        return this.simpleStore.get<ScheduleWindows>(
            [
                'products',
                productIds,
                'addons',
                addonsPayload,
                'zipcode',
                zipcode,
                'scheduleWindows',
            ],
            () =>
                this.requestScheduleWindows(
                    productIds,
                    zipcode,
                    fulfillmentPartnerId,
                    addonsPayload,
                ),
        );
    }

    /**
     * Makes request to fetch the schedule-windows based on a list of
     * products, zipcode, and addons
     *
     */
    requestScheduleWindows(
        products: number[],
        zipcode: string,
        fulfillmentPartnerId: number,
        addons?: AddonsPayload,
    ): Observable<ScheduleWindows> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL_v2}schedule-windows/`;
        const payload = {
            products,
            addons,
            utility_id: this.utilityIds[0],
            zipcode,
            fulfillment_partner_id: fulfillmentPartnerId,
        };
        return this.http
            .post<ScheduleWindows>(url, payload, {
                withCredentials: true,
            })
            .pipe(
                catchError(
                    handleGenericHttpError(`Error post schedule windows`),
                ),
            );
    }

    /**
     * Formats any addons given to be sent to the schedule-windows/ endpoint
     * Payload for addons follows the following structure:
     *
     * { PRODUCT_ID: [ADDON_ID, ADDON_ID] }
     *
     */
    formatAddonsForRequest(
        addonPayload: AddonsPayload,
        productId: number,
        addons: AddonForUI[],
    ) {
        addonPayload[productId] = addons.map((addon) => addon.service_id);
        return addonPayload;
    }

    /**
     * Gets the schedule windows for a cart and formats the addons of the
     * products to be in the expected payload format for the request.
     */
    getScheduleWindowsForCart(
        bypassCache = false,
    ): Observable<ScheduleWindows> {
        return this.cartService.cart$.pipe(
            switchMap((cart: Cart) => {
                const productIds = this.getProductIds(cart);
                const addonPayload = {};
                const partnerId = cart.fulfillment_partner_id;
                cart.products.forEach((item) => {
                    if (item.addons) {
                        this.formatAddonsForRequest(
                            addonPayload,
                            item.id,
                            item.addons,
                        );
                    }
                });
                return this.getScheduleWindows(
                    productIds,
                    partnerId,
                    addonPayload,
                    bypassCache,
                );
            }),
        );
    }

    /**
     * check if delivery options is available from scheduleWindows
     */
    hasDeliveryOptions(scheduleWindows: ScheduleWindows): boolean {
        if (!scheduleWindows) {
            return false;
        }
        return Object.keys(scheduleWindows).some(
            (productId) =>
                Object.keys(scheduleWindows[productId].delivery_options)
                    .length !== 0,
        );
    }

    /**
     * check if shipping options are available from scheduleWindows
     */
    hasShippingOptions(scheduleWindows: ScheduleWindows): boolean {
        if (!scheduleWindows) {
            return false;
        }
        return Object.keys(scheduleWindows).some(
            (productId) =>
                Object.keys(scheduleWindows[productId].shipping_options)
                    .length !== 0,
        );
    }

    getProductIds(cart: Cart): number[] {
        return cart.products.map((item) => item.id);
    }
}
