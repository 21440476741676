<button
    *ngIf="{
        beforePrint: beforePrint$ | async,
        afterPrint: afterPrint$ | async
    } as viewStates"
    data-eid="print-button"
    class="btn btn-link p-0 d-print-none"
    (click)="print()">
    <wa-cms-content cmsKey="{{ cmsKey }}"></wa-cms-content>
</button>
