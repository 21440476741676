import { map, switchMap, first, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CartService } from '@core/cart/cart.service';
import { RebateService } from '@core/rebate/rebate.service';
import { ScheduleWindowService } from '@core/schedule-window/schedule-window.service';
import {
    BootstrapStatus,
    Cart,
    CartItem,
    ScheduleWindows,
} from '@common-models';
import { RebateEligibilityStatus } from '@core/rebate/rebate-eligibility-status.model';
import { GeoService } from '@core/geo/geo.service';
import { AddonService } from '@core/addon/addon.service';
import { AddonResponse } from '@core/addon/addon-response.model';
import { ProductService } from '@core/product/product.service';

/**
 * @description
 *
 * An auto-unsubscribe package.
 * Use with `.pipe(untilDestroyed(this)) before subscribing.
 */
@Component({
    selector: 'cart-details',
    templateUrl: './cart-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartDetailsComponent implements OnInit {
    protected readonly BootstrapStatus = BootstrapStatus;
    addons$: Observable<AddonResponse>;
    cart$: Observable<Cart>;
    cartRebateEligibilityStatus$: Observable<RebateEligibilityStatus>;
    isFetchingScheduleWindows$: Observable<boolean>;
    isFetchingAddons$: Observable<boolean>;
    isUpdatingCart$: Observable<boolean>;
    scheduleWindows$: Observable<ScheduleWindows>;
    unavailableProducts$: Observable<CartItem[]>;

    constructor(
        private cartService: CartService,
        private geoService: GeoService,
        private rebateService: RebateService,
        private scheduleWindowService: ScheduleWindowService,
        private addonService: AddonService,
        private productService: ProductService,
    ) {}

    ngOnInit(): void {
        this.cart$ = this.cartService.cart$;
        this.isFetchingScheduleWindows$ =
            this.scheduleWindowService.isFetchingScheduleWindows$;
        this.isUpdatingCart$ = this.cartService.isUpdatingCart$;
        this.scheduleWindows$ = this.geoService.zipcode$.pipe(
            switchMap(() =>
                this.scheduleWindowService.getScheduleWindowsForCart(),
            ),
            share(), // avoids duplicate calls from subscribers
        );
        this.unavailableProducts$ = this.scheduleWindows$.pipe(
            map((windows) => this.cartService.getUnavailableProducts(windows)),
        );
        this.cartRebateEligibilityStatus$ =
            this.rebateService.getRebateEligibilityForCart();
        this.addons$ = this.fetchAddonsForProducts();
        this.isFetchingAddons$ = this.addonService.isFetchingAddons$;
    }

    getSerializedCommerceDataForProduct(
        id: number,
        categoryId: number,
    ): Observable<CartItem | null> {
        return this.productService.getSerializedCommerceDataForProduct(
            String(id),
            String(categoryId),
        );
    }

    fetchAddonsForProducts(): Observable<AddonResponse> {
        return this.cart$.pipe(
            switchMap((cart: Cart) => {
                if (!cart.products?.length) {
                    return of({});
                }
                const { id, category_id } = cart.products[0];
                return this.getSerializedCommerceDataForProduct(
                    id,
                    category_id,
                ).pipe(
                    switchMap((itemData: CartItem) => {
                        if (!itemData) {
                            return of({});
                        }
                        const productIds = cart.products.map(
                            (item: CartItem) => item.id,
                        );
                        const fulfillmentPartnerCode =
                            itemData.fulfillment_partner_code;
                        return this.addonService.getAddons(
                            productIds,
                            fulfillmentPartnerCode,
                        );
                    }),
                );
            }),
        );
    }

    updateItemAddons(item: CartItem): void {
        this.cartService.updateCartItem(item).pipe(first()).subscribe();
    }
}
