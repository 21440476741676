import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import {
    CustomerData,
    Order,
    OrderApprovalPayload,
    OrderApprovalResponse,
    OrderResponse,
} from '@common-models';
import { CAPI_PROXY_URL } from '@core/constants';
import { SettingsService } from '@core/settings/settings.service';
import { PRODUCT_API_URL_SETTING } from '@server/constants';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import _ from 'lodash';
import { SkepsService } from '@core/skeps/skeps.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    private _order: Order;
    private _orderId: string;
    private baseCheckoutUrl: string;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private settingsService: SettingsService,
        private skepsService: SkepsService,
    ) {
        this.setupOrderService();
    }

    /**
     * Gets the order data
     */
    get order(): Order {
        return this._order;
    }

    /**
     * Sets the order response
     */
    set order(order: Order) {
        this._order = order;
    }

    /**
     * Fetches the orderId from localStorage if it doesn't exist on the service
     */
    get orderId(): string {
        return (
            this._orderId ||
            this.localStorageService.getItem<string>('order_id')
        );
    }

    /**
     * Sets the orderId and stores it in local storage
     */
    set orderId(orderId: string) {
        this._orderId = orderId;
        this.localStorageService.setItem<string>('order_id', orderId);
    }

    /**
     * Sets up the order service and fetches the required site settings
     */
    private setupOrderService(): void {
        const baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.baseCheckoutUrl = `${baseProductApiUrl}/${CAPI_PROXY_URL}`;
    }

    /**
     * Calls POST /checkout/order-confirm to place the order with the fulfillment partner
     */
    placeOrder(): Observable<Order> {
        if (!this.orderId) {
            return throwError('No order id set');
        }
        const url = `${this.baseCheckoutUrl}checkout/order-confirm/`;
        const params = {
            order_id: this.orderId,
            purchase_confirmed: true,
        };
        return this.http
            .post<OrderResponse>(url, params, { withCredentials: true })
            .pipe(
                map((res: OrderResponse) => {
                    this.order = this.formatCustomerFromOrder(res);
                    return this.order;
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                }),
                take(1),
            );
    }

    /**
     * Format the customer data from checkout/order-confirm/ in to CustomerData
     */
    formatCustomerFromOrder(orderResponse: OrderResponse): Order {
        const customerData: CustomerData = _.keys(
            orderResponse.customer,
        ).reduce((customer: CustomerData, key) => {
            const formatKey = key
                .replace('shipping_', '')
                .replace('address_', 'address_line_');

            customer[formatKey] = orderResponse.customer[key];
            return customer;
        }, {});
        return { ...orderResponse, customer: customerData };
    }

    /**
     * Return observable with a null value if there is no stored orderId,
     * otherwise makes the call to verify order approval.
     */
    verifyOrderPaymentStatus(): Observable<OrderApprovalResponse> {
        if (!this.orderId) {
            return new BehaviorSubject(null).asObservable();
        }
        return this.verifyOrderApproval();
    }

    /**
     * Hits the `POST /verify-approval` endpoint with the order_id and application_id
     * if one exists.
     */
    verifyOrderApproval(): Observable<OrderApprovalResponse> {
        const applicationId = this.skepsService.applicationId;
        const payload: OrderApprovalPayload = {};
        if (applicationId) {
            payload.application_id = applicationId;
        }
        return this.http.post<OrderApprovalResponse>(
            `${this.baseCheckoutUrl}verify-approval/?order_id=${this.orderId}`,
            payload,
        );
    }
}
