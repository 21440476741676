<wa-modal-wrapper
    *ngIf="{
        cart: cart$ | async,
        cmsAprContexts: cmsAprContexts$ | async,
        learnMoreHref: learnMoreHref$ | async
    } as viewStates"
    [id]="'ecoFinancingModal'"
    [confirmButton]="{
        label: 'Learn More',
        action: createLearnMoreLinkCallback(viewStates.learnMoreHref)
    }"
    [closeButton]="{
        label: 'Continue Shopping',
        action: continueShopping
    }"
    [metadata]="{ products: viewStates.cart.products }">
    <div header class="fs-3 text-center">
        <div>
            <img
                class="max-w-100 eco-financing-logo"
                data-eid="eco-financing-image"
                [src]="ecoFinanceLogo.src"
                [alt]="ecoFinanceLogo.alt" />
        </div>
        <div class="fw-bold mt-3">
            <wa-cms-content
                data-eid="eco-financing-modal-title"
                cmsKey="eco_financing:modal:title">
            </wa-cms-content>
        </div>
    </div>
    <div body class="text-start">
        <div data-eid="eco-financing-modal-loan-payment">
            <wa-cms-content
                data-eid="eco-financing-modal-subtitle"
                cmsKey="eco_financing:modal:subtitle"
                [context]="{
                    total: viewStates.cart?.displayTotal | currency
                }">
            </wa-cms-content>

            <div data-eid="eco-financing-term-apr-60" class="mt-3">
                <wa-cms-content
                    [cmsKey]="'eco_financing:modal:term_and_apr_amount_bold'"
                    [context]="viewStates.cmsAprContexts[0]"></wa-cms-content>
            </div>
            <hr class="my-2" />
            <div data-eid="eco-financing-term-apr-12">
                <wa-cms-content
                    [cmsKey]="'eco_financing:modal:term_and_apr_amount_bold'"
                    [context]="viewStates.cmsAprContexts[1]"></wa-cms-content>
            </div>
        </div>
        <div data-eid="eco-financing-modal-loan-details">
            <div class="mt-2">
                <wa-cms-content
                    data-eid="eco-financing-modal-financing-description"
                    cmsKey="eco_financing:modal:financing_description">
                </wa-cms-content>
            </div>
            <div
                class="row mt-3"
                [attr.data-eid]="'eco-financing-pro-' + item.cmsKey"
                *ngFor="let item of ecoFinancingModalProList">
                <div class="col-2">
                    <i [class]="'display-6 text-muted v2 v2-' + item.icon"></i>
                </div>
                <div class="col-10">
                    <div class="fw-bold">
                        <wa-cms-content
                            [cmsKey]="
                                'eco_financing:modal:' + item.cmsKey + '_title'
                            "></wa-cms-content>
                    </div>
                    <wa-cms-content
                        [cmsKey]="
                            'eco_financing:modal:' +
                            item.cmsKey +
                            '_description'
                        "></wa-cms-content>
                </div>
            </div>

            <div class="mt-3">
                <wa-cms-content
                    class="small text-muted mt-3"
                    data-eid="eco-financing-modal-financing-disclaimer"
                    cmsKey="base:modals:financing_disclaimer">
                </wa-cms-content>
            </div>
        </div>
    </div>
</wa-modal-wrapper>
