import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleTexts } from '@shared-components/page-title/page-title.model';
import { CookiesService } from '@enervee/webapp-common';
import { MARKETPLACE_PATH } from '@server/constants';

@Component({
    selector: 'rebates-page',
    templateUrl: './rebates-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RebatesPageComponent {
    pageTitleTexts: PageTitleTexts = {
        cmsKeys: {
            title: 'cart:rebates_page_title',
            description: 'cart:rebates_page_description',
        },
    };

    constructor(private cookiesService: CookiesService) {}

    get backRoute(): string {
        return this.cookiesService.get(MARKETPLACE_PATH);
    }
}
