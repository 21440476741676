import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SimpleStore {
    private store: Record<string, ReplaySubject<unknown>> = {};

    constructor() {}

    /**
     * Either returns an observable from the subject stored with the same `key`,
     * or creates a new subject that emits the _first_ value emitted by the
     * observable that the `fetch` argument returns.
     * NOTE: only the first value emitted by the "fetched" observable is emitted
     * by the subject, as this is intended for use primarily with http requests.
     * If an error occurs, this currently requires a re-run of the `#get`
     * function, since that error is the first "value".
     *
     * There are three major usecases covered:
     * 1. If the key does NOT have a subject in the store, a subject is created
     *    for it so that future requests for it can be informed of future values.
     * 2. If the key does have a subject in the store, but the `fetch` observable
     *    hasn't emitted an initial value for it, still returns the subject as
     *    an observable and does NOT call `fetch` another time.
     * 3. If the key does have a subject and the initial "fetch" observable has
     *    already emitted an initial value, still returns the subject as an
     *    observable, since new subscriptions will have the last value replayed
     *    to them on subscription.
     */
    get<T>(key: string | unknown, fetch: () => Observable<T>): Observable<T> {
        const keyStr = this.createKey(key);
        if (!(keyStr in this.store)) {
            const valSubject = new ReplaySubject<T>(1);
            this.store[keyStr] = valSubject;
            // Just pass the next, error, and complete events to the subject
            fetch().pipe(take(1)).subscribe(valSubject);
        }
        return this.store[keyStr].asObservable() as Observable<T>;
    }

    /**
     * If the argument is already a string, returns it.
     * Otherwise, it returns a stringified version of the key.
     */
    private createKey(key: string | unknown): string {
        return typeof key !== 'string' ? JSON.stringify(key) : key;
    }
}
