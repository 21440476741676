<alert
    [status]="BootstrapStatus.success"
    cmsKey="orders:success_confirmation_message">
</alert>
<page-title [cmsKeys]="{ title: 'orders:complete_title' }">
    <div description>
        <wa-cms-content
            data-eid="order-summary-description"
            [context]="{ email: order?.customer.email }"
            cmsKey="orders:confirmation_description">
        </wa-cms-content>
    </div>
</page-title>
<order-details *ngIf="order" [order]="order"></order-details>

<next-best-action
    [nextBestActionConfigs]="nextBestActionConfigs"></next-best-action>
