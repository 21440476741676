import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fromEvent, Observable, tap } from 'rxjs';

@Component({
    selector: 'print-button',
    templateUrl: './print-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintButtonComponent implements OnInit {
    @Input() cmsKey = 'cart:print_button';
    @Input() fileName = 'printed_page';

    private window: Document['defaultView'];
    private documentTitle: string;

    beforePrint$: Observable<unknown>;
    afterPrint$: Observable<unknown>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
    ) {}

    ngOnInit() {
        this.window = this.document.defaultView;
        this.documentTitle = this.titleService.getTitle();
        this.setupPrintEvents();
    }

    /**
     * Because the window print API uses the document title to name the file,
     * we need to update the document title when printing dialogue starts, and then
     * set it back after printing dialogue has ended.
     */
    setupPrintEvents(): void {
        this.beforePrint$ = fromEvent(this.window, 'beforeprint').pipe(
            tap(() => this.setDocumentTitle(this.fileName)),
        );
        this.afterPrint$ = fromEvent(this.window, 'afterprint').pipe(
            tap(() => this.setDocumentTitle(this.documentTitle)),
        );
    }

    setDocumentTitle(title: string) {
        this.titleService.setTitle(title);
    }

    print() {
        this.window.print();
    }
}
