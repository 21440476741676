import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { CategoriesService } from '../categories/categories.service';

@Injectable({
    providedIn: 'root',
})
export class CategoriesResolverService {
    constructor(
        private router: Router,
        private location: Location,
        private categoriesService: CategoriesService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable((observer) => {
            const code = route.paramMap.get('categoryCode');
            const category = this.categoriesService.getCategory(code);
            if (!category) {
                this.redirectTo404(state);
            }
            observer.next(category);
            observer.complete();
        });
    }

    redirectTo404(state: RouterStateSnapshot) {
        this.router.navigate(['404'], {
            skipLocationChange: true,
            queryParamsHandling: 'merge',
        });
        this.location.replaceState(state.url);
    }
}
