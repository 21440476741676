import { TermsAndConditions } from '../models/terms-and-conditions.model';

const termsAndConditionsConfig = {
    fieldName: 'terms_and_conditions',
    form_id: 88,
    value: false,
    disable: true,
};

export const mockTermsAndConditions = {
    additional_text: '',
    additional_verification: false,
    category_id: null,
    help_text: 'some help text',
    key: 'terms_certifications_sce_3pp',
    max_length: null,
    max_value: null,
    min_length: null,
    min_value: null,
    options: [
        {
            help_text: 'moar help text!',
            title: 'I agree to the Terms and Conditions',
            value: 'terms_certifications_sce_3pp',
        },
    ],
    product_id: null,
    regex_pattern: null,
    required: true,
    sort_weight: 99,
    title: 'Scroll to bottom to accept the Terms and Conditions',
    type: 'scroll_box_checkbox',
    value: false,
};

export const testTermsAndConditionsFactory = (
    overrides: Partial<TermsAndConditions> = {},
): TermsAndConditions => {
    return {
        ...mockTermsAndConditions,
        ...termsAndConditionsConfig,
        ...overrides,
    };
};
