import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { RebateForm, FrameHeight } from 'src/app/models/rebate-form.model';

/**
 * IFrame tracker directive to be set on the iframe where a message event is listened to in order
 * to retrieve and use the forms data
 *
 * Ex:
 *  <iframe
 *      cartAppIframeTracker
 *      [src]="incentiveFormUrl | safe"
 *  ></iframe>
 *
 */
@Directive({
    selector: '[cartAppIframeTracker]',
})
export class IframeTrackerDirective {
    private acceptableOrigins = [
        /localhost:\d{1,4}$/,
        /.*\.enervee\.com$/,
        /192\.168\.20\.21:\d{1,4}$/,
    ];
    @Output() formComplete = new EventEmitter<RebateForm>();
    @Output() iframeSizeChange = new EventEmitter<FrameHeight>();

    constructor() {}

    /**
     * Windows message event listener
     */
    @HostListener('window:message', ['$event'])
    onPostMessage(event: MessageEvent): void {
        this.onWindowMessage(event);
    }

    /**
     * A message event reciever, accepts an event, verifies that it
     * came from the IFrame window, and then acts according
     * to the message provided.
     */
    private onWindowMessage(event: MessageEvent): void {
        if (
            !this.originIsAcceptable(event.origin) ||
            !this.messageDataIsCorrect(event.data)
        ) {
            return;
        }
        if (event.data.close) {
            this.formComplete.emit(event.data.data);
        } else if (event.data.reason === 'iframeHeightChange') {
            this.iframeSizeChange.emit(event.data.data);
        }
    }

    /**
     * Returns true if the origin provided is a valid one for the IFrame window
     * to be served from.
     */
    originIsAcceptable(origin: string): boolean {
        const originIsAcceptable = this.acceptableOrigins.some((_origins) =>
            _origins.test(origin),
        );
        return originIsAcceptable;
    }

    /**
     * Returns true if the data is in the correct shape for data sent from the
     * IFrame window.
     *
     * This check is important to make sure that we are not acting on messages sent from
     * the form's iframe that are not related to the form itself.
     */
    messageDataIsCorrect(data: RebateForm): boolean {
        return (
            typeof data === 'object' &&
            data.hasOwnProperty('close') &&
            data.hasOwnProperty('reason') &&
            data.hasOwnProperty('data')
        );
    }
}
