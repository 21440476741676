import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatCurrency, formatDate } from '@angular/common';
import { RadioOption } from '@enervee/webapp-common';

import { CartItem, ShippingOption } from '@common-models';
import { CartService } from '@core/cart/cart.service';
import { CmsService } from '@core/cms/cms.service';
import { combineLatest, map, Observable } from 'rxjs';
import _ from 'lodash';

/**
 * @description
 *
 * An auto-unsubscribe package.
 * Use with `.pipe(untilDestroyed(this)) before subscribing.
 */
@UntilDestroy()
@Component({
    selector: 'shipping-options',
    templateUrl: './shipping-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingOptionsComponent implements OnInit {
    @Input() cartItem: CartItem;
    @Input() shippingOptions: ShippingOption[];
    @Input() isUpdatingCart: boolean;
    radioOptions$: Observable<RadioOption[]>;
    selected: number;

    constructor(
        private cartService: CartService,
        private cmsService: CmsService,
    ) {}

    ngOnInit(): void {
        this.radioOptions$ = this.formatOptions();
        this.initialSelection();
    }

    /**
     * Set initial selection by checking shipping_id in cart,
     * if shipping_id is missing, set the least expansive option.
     */
    initialSelection(): void {
        if (this.cartItem.shipping_id) {
            this.selected = this.cartItem.shipping_id;
        } else {
            const cheapestOption = this.shippingOptions.reduce((a, b) =>
                a.price < b.price ? a : b,
            );
            this.selected = cheapestOption.shipping_id;
            this.selectOption({
                value: cheapestOption.shipping_id,
                labelText: cheapestOption.name,
            });
        }
    }

    /**
     * Formats the shipping options for the radio options component
     */
    formatOptions(): Observable<RadioOption[]> {
        const partner = _.snakeCase(this.cartItem.fulfillment_partner_name);
        const cmsKey = `cart:item:shipping_option_radio_label_${partner}`;
        const optionsObservables = this.shippingOptions.map(
            (option: ShippingOption) => {
                return this.cmsService
                    .getMarketPlaceText(cmsKey, {
                        name: this.getMarketPlaceTextName(option),
                        date: option.estimated_date,
                    })
                    .pipe(
                        map((text) => {
                            if (text) {
                                return {
                                    value: this.getRadioOptionValue(option),
                                    labelText: text,
                                    labelValue: formatCurrency(
                                        option.price,
                                        'en',
                                        '$',
                                    ),
                                };
                            } else {
                                return null;
                            }
                        }),
                    );
            },
        );

        // filters out any null options
        return combineLatest(optionsObservables).pipe(
            map((options) => {
                return options.filter((o) => o !== null);
            }),
        );
    }

    /**
     * Sets the selected shipping option data fields for the cart item and updates
     * the cart
     */
    selectOption(selected: RadioOption): void {
        const selectedShippingOption = this.shippingOptions.find(
            (option: ShippingOption) => {
                return option.shipping_id === selected.value;
            },
        );

        this.cartItem.shipping_id = selectedShippingOption.shipping_id;
        this.cartItem.shipping_method = selectedShippingOption.name;
        this.cartItem.shipping_date = formatDate(
            selectedShippingOption.estimated_date,
            'YYYY-MM-dd',
            'en',
        );

        this.cartService.updateCart().pipe(untilDestroyed(this)).subscribe();
    }

    /**
     * Gets the marketplace text name for the radio options component.
     * With lightbulbs cart, the option name comes as 'lightbulbs-standardground-01'
     * so instead we use the cart item shipping method to standardize with the rest
     */
    getMarketPlaceTextName(option: ShippingOption): string {
        return _.includes(option.name, 'lightbulbs')
            ? this.cartItem.shipping_method
            : option.name;
    }

    /**
     * Gets the shipping option value for the radio options component.
     * If an item only has one shipping option, we set the radio option value
     * to the shipping id of that item in order to select by default.
     */
    getRadioOptionValue(option: ShippingOption): number {
        return _.size(this.shippingOptions) === 1
            ? this.cartItem.shipping_id
            : option.shipping_id;
    }
}
