import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CAPI_PROXY_URL } from '@core/constants';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';

import { CartItem, CartItemData } from '@common-models';
import { SettingsService } from '@core/settings/settings.service';
import { SimpleStore } from '@core/simple-store/simple-store.service';
import { PRODUCT_API_URL_SETTING } from '@server/constants';
import { CommerceProductResponse } from '@core/product/commerce-product-response.model';
import { CartService } from '@core/cart/cart.service';
import { handleGenericHttpError } from '@utils/handle-http-error';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private readonly baseProductApiUrl: string;
    private readonly utilityIds: string[];

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        private simpleStore: SimpleStore,
        private cartService: CartService,
    ) {
        this.baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.utilityIds = this.settingsService.getSiteSetting(
            'utility_include_list',
        ) as string[];
    }

    /**
     * Gets the commerce data from the store if available, otherwise,
     * make a request to fetch the commerce data for a product.
     */
    getCommerceDataForProduct(
        productId: string,
        categoryId: string,
    ): Observable<CartItemData> {
        return this.simpleStore.get<CartItemData>(
            ['products', productId, 'commerceProduct'],
            () =>
                this.requestCommerceProduct(productId, categoryId).pipe(
                    map((res: CommerceProductResponse): CartItemData | null =>
                        _.get(res, 'products[0]', null),
                    ),
                ),
        );
    }

    /**
     * Create a CartItem instance from the commerce product response data.
     */
    getSerializedCommerceDataForProduct(
        productId: string,
        categoryId: string,
    ): Observable<CartItem | null> {
        return this.getCommerceDataForProduct(productId, categoryId).pipe(
            map((cartItemData: CartItemData): CartItem | null => {
                if (!cartItemData) {
                    return null;
                }
                return this.cartService.createCartItem(cartItemData);
            }),
        );
    }

    /**
     * Makes a request to get the commerce data for a given product and utility
     */
    requestCommerceProduct(
        productId: string,
        categoryId: string,
    ): Observable<CommerceProductResponse> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}products/`;
        const params = {
            category_id: categoryId,
            products: productId,
            utility_id: this.utilityIds[0],
        };
        return this.http
            .get<CommerceProductResponse>(url, {
                withCredentials: true,
                params,
            })
            .pipe(
                catchError(handleGenericHttpError('Error posting checkout')),
            );
    }
}
