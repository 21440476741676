import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent implements OnInit {
    /**
     * Cloudinary public id of the image
     */
    @Input() imagePublicId!: string;

    /**
     * The alt text associated with the image
     */
    @Input() alt?: string;

    constructor() {}

    ngOnInit(): void {}
}
