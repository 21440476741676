<ng-container *ngIf="{ cart: cart$ | async, isBreadcrumbPage } as states">
    <nav
        *ngIf="states.isBreadcrumbPage"
        aria-label="breadcrumbs"
        data-eid="breadcrumbs"
        class="breadcrumb mb-0">
        <ng-container
            *ngFor="let config of configs; index as i; last as isLast">
            <button
                *ngIf="isBreadcrumbItemDisplayed(config, states.cart)"
                (click)="handleLinkClick(config, i)"
                class="breadcrumb-item btn btn-link ps-0 pe-1"
                [ngClass]="{
                    'active fw-bold': config.isActive
                }"
                [attr.disabled]="isBreadcrumbDisabled(i) ? true : null"
                [attr.data-eid]="
                    config.path
                        ? 'breadcrumb-item-' + config.path
                        : 'breadcrumb-item-cart'
                "
                [attr.aria-current]="config.isActive ? 'page' : null">
                <wa-cms-content
                    *ngIf="config.cmsKey; else displayText"
                    data-eid="breadcrumb-cms-content"
                    cmsKey="{{ config.cmsKey }}">
                </wa-cms-content>
                <ng-template #displayText>
                    <span>{{ config.displayText }}</span>
                </ng-template>
            </button>
            <button
                *ngIf="
                    !isLast && isBreadcrumbItemDisplayed(config, states.cart)
                "
                class="breadcrumb-item btn btn-link ps-0 pe-1"
                data-eid="breadcrumb-divider"
                disabled>
                <i class="v2 v2-arrow-right" aria-hidden="true"></i>
            </button>
        </ng-container>
    </nav>
</ng-container>
