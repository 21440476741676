import { Injectable } from '@angular/core';
import { AnalyticsService } from '@core/analytics/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsResolverService {
    constructor(private analyticsService: AnalyticsService) {}

    /**
     * Triggers the initialization of the segment library script.
     */
    async resolve(): Promise<void> {
        try {
            this.analyticsService.initializeSegment();
        } catch (e) {
            console.warn(e);
        }
    }
}
