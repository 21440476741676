import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { CartItem, Product } from '@common-models';
import { SettingsService } from '@core/settings/settings.service';
import { QuantityDropdownSelectorSetting } from '@shared-components/product-row/quantity-dropdown-selector-setting.model';
import { CartService } from '@core/cart/cart.service';
import { take } from 'rxjs';
import { RebateService } from '@core/rebate/rebate.service';
import { EventListService } from '@core/event-list/event-list.service';

@Component({
    selector: 'product-row',
    templateUrl: './product-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRowComponent implements OnInit {
    /**
     * Swiftype data of a product to be able to fetch specific data
     * like the image url and display name for a product.
     */
    @Input() product: Product;

    /**
     * Cart item where the data comes from commerce
     */
    @Input() cartItem: CartItem;

    /**
     * Cart updating state
     */
    @Input() isUpdatingCart: boolean;

    /**
     * Configuration for if the quantity should be editable
     */
    @Input() hasEditableQuantity?: boolean;

    /**
     * Should the product quantity be completely hidden.
     */
    @Input() hasHiddenQuantity?: boolean;

    maxQuantity: number;

    constructor(
        private settingsService: SettingsService,
        private cartService: CartService,
        private rebateService: RebateService,
        private eventListService: EventListService,
    ) {}

    ngOnInit(): void {
        this.setMaxQuantity();
    }

    get title(): string | undefined {
        return (
            this.cartItem?.title ||
            (this.product
                ? `${this.product.brand} ${this.product.model_number}`
                : undefined)
        );
    }

    get description(): string | undefined {
        return this.product?.display_name;
    }

    get imageId(): string | undefined {
        return this.product?.thumbnail_image;
    }

    /**
     * Triggers when a dropdown is selected
     * To get the amount of quantity change, we need to subtract the select quantity
     * with the current quantity in cart.
     * Sends analytic event for when quantity selector is clicked
     */
    dropdownSelected(value: string): void {
        this.cartService
            .addProduct(
                this.cartItem,
                this.cartItem.addons,
                Number(value) - this.cartItem.quantity,
            )
            .pipe(take(1))
            .subscribe(() =>
                this.rebateService.pushTriggerRebateEligibility(true),
            );
        this.trackCartItemQuantitySelection(value);
    }

    /**
     * Sets the max quantity available to be selected for a given product
     */
    setMaxQuantity(): void {
        if (!this.cartItem) {
            return;
        }
        const quantityConfig = this.settingsService.getCategorySetting(
            'quantity_dropdown_selector',
            this.cartItem.category_id,
            null,
        ) as QuantityDropdownSelectorSetting;
        this.maxQuantity = quantityConfig?.update.max_value || 1;
    }

    trackCartItemQuantitySelection(value: string): void {
        this.eventListService.sendQuantitySelectorClicked(value, this.product);
    }
}
