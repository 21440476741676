import { SettingsData } from '@server/utils/site-settings';
import { StylesData } from '@server/utils/site-styles';

type SiteSettings = SettingsData['siteSettings'];
type SiteStyles = StylesData['siteStyles'];
type CategorySettings = SettingsData['categorySettings'];

const testSiteSettings: SiteSettings = {
    known_bot_user_agents: ['234', '5678'],
    known_user_ip: ['234.1234.24'],
    segment_organization_name: 'test-org',
    segment_tracking_product_details: [],
    segment_write_key: 'some_write_key',
    title: 'some title',
    cms_base_url: 'https://cms-test.enervee.com/api/v2/',
    product_api_base_url: 'https://test.enervee.com',
    ga_cookie_name: '_ga',
    sort_by_options: {
        choices: {
            energy_score: {
                display_name: 'Energy Score',
                asc_des_display_name: 'highest',
            },
            '-energy_score': {
                display_name: 'Energy Score',
                asc_des_display_name: 'lowest',
            },
            relevance: {
                display_name: 'Relevance',
                asc_des_display_name: '',
            },
        },
        default: 'relevance',
    },
    geo_ip_key: '123456789',
    map_image_url: 'https://images.enervee.com/test.png',
    efficiency_levels_list: [
        {
            label: 'very low',
            description: 'low efficiency',
            comparative: 'the most',
            max: 50,
        },
        {
            label: 'medium',
            description: 'very good efficiency',
            comparative: 'less',
            max: 90,
        },
        {
            label: 'high',
            description: 'ideal efficiency',
            comparative: 'the least',
            max: 100,
        },
        {
            label: 'low',
            description: 'good efficiency',
            comparative: 'more',
            max: 75,
        },
    ],
    commerce_fulfillment_retailers: {
        'Best Buy': {
            display_name: 'Best Buy',
            external_order_link: {
                helper_text:
                    'Use the guest order option to find your order status.',
                label: 'Best Buy Order ID:',
                link: 'https://www.bestbuy.com/profile/ss/guestorderlookup',
            },
            hide_shipping_availability: false,
            logo: {
                alt: 'Best Buy',
                src: 'https://images.enervee.com/image/upload/logos/bestbuy.png',
            },
            privacy_policy: {
                label: 'Privacy Policy',
                src: 'https://www.bestbuy.com/site/help-topics/privacy-policy/pcmcat204400050062.c?id=pcmcat204400050062',
                text: 'See how your data is managed.',
            },
            reschedule_url:
                'https://www.bestbuy.com/profile/ss/verification/user-input-verification?vt=a595e753-b4ef-11eb-9100-0e3068751907',
            return_policy: {
                label: 'Return Policy',
                src: 'https://www.bestbuy.com/site/help-topics/return-exchange-policy/pcmcat260800050014.c?id=pcmcat260800050014',
                text: 'See return policy.',
            },
            support: {
                label: 'Support',
                src: 'https://www.bestbuy.com/profile/ss/guestorderlookup',
            },
        },
        'Lightbulbs.com': {
            display_name: 'Lightbulbs.com',
            external_order_link: {
                label: 'Order ID:',
            },
            hide_shipping_availability: true,
            logo: {
                alt: 'Lightbulbs.com',
                src: 'https://images.enervee.com/image/upload/logos/lightbulbscom-logo.png',
            },
            privacy_policy: {
                label: 'Privacy Policy',
                src: 'https://www.lightbulbs.com/privacy-policy',
            },
            return_policy: {
                label: 'Return Policy',
                src: 'https://www.lightbulbs.com/returns',
                text: 'See return policy.',
            },
        },
        preferred_partners: ['lightbulbs.com', 'Best Buy'],
        sort_by: 'price',
    },
    utility_include_list: ['12345'],
    sale_discount_retailer_list: ['Best Buy'],
    quantity_dropdown_selector: {
        add: {
            default_value: 1,
            is_enabled: true,
            max_value: 1,
            min_value: 1,
        },
        update: {
            is_enabled: true,
            max_value: 1,
            min_value: 0,
        },
    },
    card_acceptance_mark: {
        alt: 'We accept Visa, Mastercard, American Express, and Discover.',
        cloudinary_id: 'graphics/card-acceptance-mark.jpg',
    },
    card_security_code_helper: {
        alt: 'The CVC number (also known as a verification code or security code) is a 3 digit number on the back of your card or a 4 digit number on the front of you card.',
        cloudinary_id: 'graphics/card-security-code.jpg',
    },
    one_finance_logo: {
        alt: 'Eco Financing',
        cloudinary_id:
            'logos/Eco-financing%20-New/eco-financing-logo-102022.png',
        height: '26',
        src: 'https://images.enervee.com/image/upload/v1668475963/logos/Eco-financing%20-New/eco-financing-logo-102022.png',
    },
    chase_hpf_parent_script_url:
        'https://www.chasepaymentechhostedpay-var.com/hpf/js/hpfParent.min.js',
    skeps_script_url:
        'https://enervee-test.skeps.dev/application/plugins/sdk/v2/scripts/skeps.js',
    cart_payment_options: ['finance', 'credit_card'],
    payment_finance_config: {
        enabled: ['skeps'],
        mock_finance: {
            url: 'https://enervee-static-checkout-dev.s3.us-west-2.amazonaws.com/payment-test-pages/redirect.html',
        },
        skeps: {
            dev_api_host: 'https://skeps.dev.kube.enervee.com',
            merchant_id: '3HCWFVKW',
        },
    },
    next_best_action_order_summary_v2: [
        {
            icon: 'v2-delivery',
            isVisible: true,
            key: 'track_order',
            link: '/test-link',
            isBackToMarketplace: true,
        },
        {
            isVisible: true,
            icon: 'v2-support',
            key: 'support',
            external: false,
            link: '/support',
        },
        {
            isVisible: true,
            icon: 'v2-shopping-bag',
            key: 'continue-shopping',
            external: true,
            link: '/',
        },
    ],
    skeps_application_id_cookie_expiration: {
        minutes: 60,
    },
    finance_eligibility_parameters: {
        max_financeable_amount: 5000,
        min_financeable_precentage: 0.7,
    },
    cartapp_blocked_routes: ['/**/**/addons', `/customer-details/account`],
    ocr_polling_config: {
        startDelayMS: 500,
        pollingRateMS: 200,
        pollingDurationMS: 60000,
    },
    hubspot_form: {
        bill_review_form_id: 'a-big-ol-string',
        endpoint_url: 'https://test.com/submissions/v3/integration/submit/',
        form_id: 'another-big-ol-string',
        hubspot_cookie_name: 'itsacookie',
        portal_id: 123456,
        utility_unavailable_form_id: 'whoa-a-form-id',
    },
    rebate_api_host: 'https://test.rebate.enervee.com',
};

export const testSiteSettingsFactory = (
    overrides: Partial<SiteSettings> = {},
): SiteSettings => {
    return {
        ...testSiteSettings,
        ...overrides,
    };
};

const testSiteStyles: SiteStyles = {
    accent_colors: {
        primary_color: '171, 89, 178',
        primary_hover_color: '220, 136, 227',
        primary_button_text_color: '255, 255, 255',
        discount_color: '30, 143, 214',
        discount_hover_color: '98, 183, 223',
        discount_button_text_color: '255, 255, 255',
        link_color: '171, 89, 178',
        link_hover_color: '220, 136, 227',
    },
    header_styling: {
        header_text_color: '255, 255, 255',
        header_search_field_border_color: '255, 255, 255',
        header_hover_color: '220, 136, 227',
        header_background_color: '171, 89, 178',
    },
    font_family: {
        name: 'arial, sans-serif',
        code: '',
    },
};

export const testSiteStylesFactory = (
    overrides: Partial<SiteStyles> = {},
): SiteStyles => {
    return {
        ...testSiteStyles,
        ...overrides,
    };
};

const testCategorySettings: CategorySettings = {
    14: {
        category_icon: 'test',
        recommended_products_disabled: true,
        search_term: 'test',
    },
    16: {},
};

export const testCategorySettingsFactory = (
    overrides: Partial<CategorySettings> = {},
): CategorySettings => {
    return {
        ...testCategorySettings,
        ...overrides,
    };
};
