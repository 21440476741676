import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'add-product-page',
    templateUrl: './add-product-page.component.html',
})
export class AddProductPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
