import { Component, Input, OnInit } from '@angular/core';

import { CmsKeys } from '@shared-components/text-label/cms-keys.model';
import { DisplayConfig } from '@shared-components/text-label/display-config.model';
import { DirectTexts } from '@shared-components/text-label/direct-texts.model';

/**
 * @description
 *
 * Displays a text label and description.
 *
 * @ngModule SharedModule
 */
@Component({
    selector: 'text-label',
    templateUrl: './text-label.component.html',
})
export class TextLabelComponent implements OnInit {
    @Input() cmsKeys: CmsKeys;
    @Input() context: Record<string, string | number | boolean>;
    @Input() displayConfig?: DisplayConfig = {
        isHorizontal: true,
        wideValueColumn: false,
        largeLineHeight: false,
    };
    @Input() directTexts?: DirectTexts;

    constructor() {}

    ngOnInit(): void {}
}
