import {
    DefaultTrackingParams,
    IncentiveTrackingParams,
    ProductTrackingParams,
} from '@common-models';

export const defaultProductParams: ProductTrackingParams[] = [
    { key: 'brand', default: null },
    { key: 'category', source: 'category_name', default: null },
    {
        key: 'category_id',
        source: 'category_id',
        default: null,
        include_in_event: true,
    },
    { key: 'enervee_id', source: 'id', default: null },
    { key: 'enervee_score', default: null },
    { key: 'fuel_type', source: 'fuel_units', default: '' },
    { key: 'image_url', default: null },
    { key: 'key_attributes', source: 'top_specs_map', default: {} },
    { key: 'lowest_price', source: 'price', default: null },
    { key: 'mpn', source: 'model_number', default: null },
    { key: 'offer_count', source: 'offers_json.length', default: 0 },
    { key: 'price_worthiness', source: 'price_level_number', default: 0 },
    { key: 'quantity', default: 1 },
    { key: 'retailer1', source: 'top_retailer', default: {} },
    { key: 'reviews_rating', source: 'review_score', default: null },
    { key: 'reviews_count', source: 'review_count', default: 0 },
    { key: 'savings', source: 'energy_savings_fit_enervee', default: null },
    { key: 'short_name', source: 'display_name', default: null },
];

export const defaultIncentiveParams: IncentiveTrackingParams[] = [
    { key: 'id', default: null },
    { key: 'amount', default: 0 },
    { key: 'type', source: 'business_rule_codes', default: [] },
    { key: 'end_date', default: null },
    { key: 'start_date', default: null },
];

export const defaultTrackingParams: DefaultTrackingParams = {
    eventType: 'interaction',
    location: 'mainContent',
};

export const defaultGetUserStatusMessage =
    "AnalyticsService's internal getUserStatus has not been configured! Please set getUserStatus to a function for getting the user status";

export const defaultGetUserDetailsMessage =
    "AnalyticsService's internal getUserDetails has not been configured! Please set getUserDetails to a function for getting user details";
