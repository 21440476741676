import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    defer,
    forkJoin,
    Observable,
    of,
    throwError,
} from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    catchError,
    finalize,
    first,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { isEqual, isEmpty, cloneDeep } from 'lodash';
import { Router } from '@angular/router';

import { PRODUCT_API_URL_SETTING } from '@server/constants';
import { CAPI_PROXY_URL } from '@core/constants';
import { SettingsService } from '@core/settings/settings.service';
import {
    AddonForUI,
    Cart,
    CartData,
    CartItem,
    CartItemData,
    CartItemRebate,
    Checkout,
    PaymentMethod,
    Product,
    ScheduleWindows,
} from '@common-models';
import { OrderService } from '@core/order/order.service';
import { handleGenericHttpError } from '@utils/handle-http-error';
import { GeoService } from '@core/geo/geo.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    private _checkoutData$ = new BehaviorSubject<Checkout>(null);
    private _cart$ = new BehaviorSubject<Cart>(null);
    private _isUpdatingCart$ = new BehaviorSubject<boolean>(false);
    private _checkoutError$ = new BehaviorSubject<boolean>(false);
    private baseProductApiUrl: string;
    private _cartItemRebates: CartItemRebate[];
    private cartData: Cart;
    private _isChaseIframeLatest: boolean;

    constructor(
        private http: HttpClient,
        private orderService: OrderService,
        private settingsService: SettingsService,
        private geoService: GeoService,
        private router: Router,
    ) {
        this.baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
    }

    /**
     * Get cart observable.
     */
    get cart$(): Observable<Cart> {
        return this._cart$;
    }

    get checkoutData$(): Observable<Checkout> {
        return this._checkoutData$;
    }

    private set checkoutData(checkoutData: Checkout) {
        this._checkoutData$.next(checkoutData);
    }

    get isUpdatingCart$(): Observable<boolean> {
        return this._isUpdatingCart$;
    }

    private set isUpdatingCart(value: boolean) {
        this._isUpdatingCart$.next(value);
    }

    get isCreateOrderError$(): Observable<boolean> {
        return this._checkoutError$;
    }

    set isCreateOrderError(value: boolean) {
        this._checkoutError$.next(value);
    }

    /**
     * Returns the itemRebates for a cart if this has been fetched.
     */
    get cartItemRebates(): CartItemRebate[] {
        return this._cartItemRebates;
    }

    /**
     * Whenever new rebates are retrieved via the backend rebate endpoints,
     * this sets the cartItemRebates to a property to add back the
     * rebates whenever new carts are retrieved.
     */
    set cartItemRebates(value: CartItemRebate[]) {
        this._cartItemRebates = value;
    }

    /**
     * Set cart and create new instance of cart item if cartData differs
     * Force is used to still update the cart in instances like when itemRebates
     * are added to the cartItem since itemRebates are private variables
     * and private variables are not checked via the equality check condition.
     */
    setCartData(cartData: CartData, force = false): void {
        if (!isEqual(this.cartData, cartData) || force) {
            const cart = this.createCartObject(cartData);
            this.serializeCartChildren(cart);
            this._cart$.next(cart);
            this.cartData = cart;
            if (cart.shipping_zip) {
                this.geoService.zipcode = cart.shipping_zip;
            }
        }
    }

    /**
     * Serializes the child objects within a cart.
     *
     * Creates CartItem objects from the cart's products as well as
     * adds any cartItemRebates that have been fetched for the cartItem.
     * This is needed so that the rebates are added back to the cartItem
     * whenever there have been new updates to the cart.
     */
    private serializeCartChildren(cart: Cart): void {
        const serializedProducts = [];
        cart.products.forEach((item: CartItem): void => {
            const serializedItem = this.createCartItem(item);
            if (this.cartItemRebates) {
                this.setRebateDataForItem(serializedItem);
            }
            serializedProducts.push(serializedItem);
        });
        cart.products = serializedProducts;
    }

    /**
     * Sets the itemRebate to a cartItem if there are any rebates
     * that have been fetched.
     */
    private setRebateDataForItem(item: CartItem) {
        const cartItemRebate = this.cartItemRebates?.find(
            (rebate: CartItemRebate) => {
                return item.id === rebate.product_id;
            },
        );
        if (cartItemRebate) {
            item.itemRebate = cartItemRebate;
        }
    }

    /**
     * Create a new cart and get its initial details
     */
    createCart(): Observable<Cart> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/`;
        return this.http.post<Cart>(url, {}, { withCredentials: true }).pipe(
            tap((cartData: CartData) => {
                this.setCartData(cartData);
            }),
            map((cartData: CartData) => {
                return this.createCartObject(cartData);
            }),
            catchError(handleGenericHttpError('Error creating cart')),
        );
    }

    /**
     * Creates a new instance of a Cart from CartData.
     */
    createCartObject(cartData: CartData): Cart {
        return Object.assign(new Cart(), cartData);
    }

    /**
     * Creates a new instance of CartItem from CartItemData.
     */
    createCartItem(cartItemData: CartItemData): CartItem {
        return Object.assign(new CartItem(), cartItemData);
    }

    /**
     * Get the details of an existing cart. The promise resolves if the customer's cart details
     * were found, and rejects if there were no details retrieved (either because the user did
     * not have a cart, or their previous cart had already been submitted).
     */
    requestCart(): Observable<Cart | null> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/`;
        return this.http.get<Cart>(url, { withCredentials: true }).pipe(
            tap((cartData: CartData) => {
                if (cartData) {
                    this.setCartData(cartData);
                }
            }),
            map((cartData: CartData) => {
                return cartData ? this.createCartObject(cartData) : null;
            }),
            catchError(handleGenericHttpError('Error getting cart')),
        );
    }

    /**
     * update cart with new or updated items
     */
    updateCart(updateProducts?: Array<CartItem>): Observable<Cart> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/`;
        this.isUpdatingCart = true;
        return forkJoin([
            this.geoService.getZipcode().pipe(first()),
            this.cart$.pipe(first()),
        ]).pipe(
            switchMap(([zipcode, cart]) => {
                const products = updateProducts || cart.products;
                return this.http.put<Cart>(
                    url,
                    { products, zipcode },
                    { withCredentials: true },
                );
            }),
            tap((cartData: CartData) => {
                this.setCartData(cartData);
            }),
            map((cartData: CartData) => {
                return this.createCartObject(cartData);
            }),
            catchError(handleGenericHttpError('Error updating cart')),
            finalize(() => {
                this.isUpdatingCart = false;
            }),
        );
    }

    /**
     * Update a single cart item.
     */
    updateCartItem(product: CartItem): Observable<Cart> {
        return this.cart$.pipe(
            first(),
            switchMap((cart: Cart) => {
                const itemIndex = cart.products.findIndex(
                    (item: CartItem) => item.id === product.id,
                );
                const clonedProducts = cloneDeep(cart.products);
                clonedProducts[itemIndex] = product;
                return this.updateCart(clonedProducts);
            }),
        );
    }

    /**
     * Add new item to the cart
     * The quantity can be positive or negative number,
     * when negative the endpoint will remove the amount of product in cart.
     */
    addProduct(
        payload: CartItem | Product,
        selectedAddons?: AddonForUI[],
        quantity = 1,
    ): Observable<Cart> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/add-product/`;
        const payloadItem = {
            id: payload.id,
            quantity,
            addons: selectedAddons,
            fulfillment_partner_id: payload.fulfillmentPartnerId,
            fulfillment_partner_name: payload.fulfillmentPartnerName,
            zipcode: '',
        };
        this.isUpdatingCart = true;

        return this.geoService.getZipcode().pipe(
            tap((zipcode) => {
                payloadItem.zipcode = zipcode;
            }),
            switchMap(() => {
                return this.http.post<Cart>(url, payloadItem, {
                    withCredentials: true,
                });
            }),
            tap((cartData: CartData) => {
                this.setCartData(cartData);
            }),
            map((cartData: CartData) => {
                return this.createCartObject(cartData);
            }),
            catchError(handleGenericHttpError('Error fetching add-product')),
            finalize(() => {
                this.isUpdatingCart = false;
            }),
        );
    }

    getCartFinalBill(orderId?: string): Observable<Cart> {
        orderId = orderId || this.orderService.orderId;
        this.isUpdatingCart = true;
        if (!orderId) {
            return throwError(() => 'Missing orderId');
        }
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/final-bill/?order_id=${orderId}`;
        return this.http.get<Cart>(url, { withCredentials: true }).pipe(
            tap((cartData: CartData) => {
                this.setCartData(cartData);
            }),
            map((cartData: CartData) => {
                return this.createCartObject(cartData);
            }),
            catchError((error: HttpErrorResponse) => {
                console.error(`Error fetching final bill`);
                return throwError(() => error);
            }),
            finalize(() => {
                this.isUpdatingCart = false;
            }),
        );
    }

    /**
     * Checkout the cart
     */
    createOrder(paymentMethod: PaymentMethod): Observable<Checkout> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}checkout/`;
        this.isUpdatingCart = true;
        this.isCreateOrderError = false;
        return this.cart$.pipe(
            switchMap((cart) => {
                const payload: Checkout = {
                    payment_method: paymentMethod,
                    cart_id: cart.id,
                };
                return this.http.post<Checkout>(url, payload, {
                    withCredentials: true,
                });
            }),
            tap((res: Checkout) => {
                this.checkoutData = res;
                this.orderService.orderId = res.order_id;
                if (
                    res.payment_method === PaymentMethod.creditCard &&
                    res.iframe &&
                    res.url
                ) {
                    this._isChaseIframeLatest = true;
                }
            }),
            first(),
            finalize(() => {
                this.isUpdatingCart = false;
            }),
            catchError((err: Error) => {
                this.isCreateOrderError = true;
                console.error('Error posting checkout');
                return throwError(() => err);
            }),
        );
    }

    /**
     * Get current cart or create a new cart.
     * This method will first call requestCart() with jwt token from client cookie,
     * to get most up to date cart.
     * If requestCart returns empty, createCart will get call to create a new cart.
     * Most up to date cart object will be available when starts
     */
    getOrCreateCart(): Observable<Cart> {
        return this.requestCart().pipe(
            switchMap((cart: Cart) => {
                if (cart === null) {
                    return this.createCart();
                } else {
                    return this.cart$;
                }
            }),
            take(1),
            catchError(() => {
                this.router.navigate(['404'], {
                    skipLocationChange: true,
                    queryParamsHandling: 'merge',
                });
                return of(null);
            }),
        );
    }

    /**
     * Get the Chase Iframe url from checkout data.
     * Making sure the iframe url is the latest from /checkout
     * Set _isChaseIframeLatest to false each time the iframe url has been used,
     * /checkout will be refetched creating a new order and a new iframe url.
     */
    getChaseIframeUrl(): Observable<string> {
        return of(this._isChaseIframeLatest).pipe(
            switchMap(
                (iframeUrl: boolean): Observable<Checkout> =>
                    defer(() =>
                        iframeUrl
                            ? this.checkoutData$
                            : this.createOrder(PaymentMethod.creditCard),
                    ),
            ),
            map((checkoutData: Checkout) => checkoutData.url),
            finalize(() => {
                this._isChaseIframeLatest = false;
            }),
            take(1),
        );
    }

    /**
     * check if all products have either shipping or delivery options
     */
    getUnavailableProducts(scheduleWindows: ScheduleWindows): CartItem[] {
        if (!scheduleWindows) {
            return [];
        }
        return this.cartData.products.filter(
            (product) =>
                isEmpty(scheduleWindows[product.id]?.shipping_options) &&
                isEmpty(scheduleWindows[product.id]?.delivery_options) &&
                Boolean(
                    scheduleWindows[product.id]
                        ?.available_for_manual_scheduling,
                ) === false,
        );
    }
}
