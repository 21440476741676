import { OrderLine } from '../models/order-line.model';

export const baseOrderLine: OrderLine = {
    id: 165180961,
    partner_name: 'Best Buy',
    category_id: 79,
    title: 'LG DLGX7801VE',
    quantity: 1,
    retail_price: '1449.99',
    price: '1249.99',
    sku: '6329776',
    rebate: null,
    addons: [],
    additional_discounts: null,
    shipping_id: 3,
    shipping_date: '2022-03-15',
    delivery_datetime: '2022-04-06T07:00:00Z',
};

export const testOrderLineFactory = (
    overrides: Partial<OrderLine> = {},
): OrderLine => {
    return {
        ...baseOrderLine,
        ...overrides,
    };
};
