import {
    CustomerDetailResponse,
    AddressData,
    CustomerData,
    OrderCustomerData,
} from '@common-models';

export const customerAddress: AddressData = {
    customer: 5397,
    address_line_1: '1746 Abbot Kinney Blvd',
    address_line_2: '',
    city: 'Venice',
    state: 'CA',
    zip_code: '90291',
    country: 'US',
    address_type: 'home',
    lmi_eligible: false,
    median_family_income_as_pct: 259.28,
    state_code: '06',
    county_code: '037',
    census_tract: '273902',
};

export const customerDetail: CustomerDetailResponse = {
    first_name: 'remy',
    last_name: 'chonk',
    email: 'test@test.com',
    phone_number: '+22222222222',
    address: [customerAddress],
};

/**
 * Mock data from /customer-detail endpoint
 */
export const testCustomerDetailFactory = (
    overrides: Partial<CustomerDetailResponse> = {},
): CustomerDetailResponse => {
    return {
        ...customerDetail,
        ...overrides,
    };
};

export const customerData: CustomerData = {
    first_name: 'remy',
    last_name: 'chonk',
    email: 'test@test.com',
    phone_number: '+22222222222',
    address_line_1: '1746 Abbot Kinney Blvd',
    address_line_2: '',
    city: 'Venice',
    state: 'CA',
    zip_code: '90291',
    country: '',
};

/**
 *
 */
export const testCustomerDataFactory = (
    overrides: Partial<CustomerData> = {},
): CustomerData => {
    return {
        ...customerData,
        ...overrides,
    };
};

export const customerFromOrder: OrderCustomerData = {
    first_name: 'remy',
    last_name: 'chonk',
    email: 'test@test.com',
    phone_number: '+22222222222',
    shipping_address_1: '1746 Abbot Kinney Blvd',
    shipping_address_2: '',
    shipping_city: 'Venice',
    shipping_country: '',
    shipping_state: 'CA',
    shipping_zip_code: '90291',
};

export const testCustomerFromOrderFactory = (
    overrides: Partial<OrderCustomerData> = {},
): OrderCustomerData => {
    return {
        ...customerFromOrder,
        ...overrides,
    };
};
