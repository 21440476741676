<navbar-header></navbar-header>
<main class="page-wrapper flex-shrink-0">
    <section class="container-fixed-md py-4 pb-md-5">
        <router-outlet></router-outlet>
        <div
            *ngIf="isDataLoading"
            class="container-fluid position-absolute top-50 start-50 translate-middle-x">
            <div class="d-flex justify-content-center text-muted pt-4 mt-0">
                <div
                    data-eid="product-options-loader"
                    class="spinner-border"
                    role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </section>
    <debugger *ngIf="isDebuggerEnabled"></debugger>
</main>
<nrv-footer class="mt-auto"></nrv-footer>
