import { Injectable, Renderer2 } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScriptService {
    private loadedScripts: {
        [url: string]: ReplaySubject<HTMLScriptElement>;
    } = {};

    constructor() {}

    /**
     * Append script to the Document body, return the script as an Observable
     */
    loadScript(
        renderer: Renderer2,
        url: string,
    ): Observable<HTMLScriptElement> {
        if (this.loadedScripts[url]) {
            return this.loadedScripts[url].asObservable();
        }

        this.loadedScripts[url] = new ReplaySubject();

        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = () => {
            this.loadedScripts[url].next(script);
            this.loadedScripts[url].complete();
        };
        script.onerror = () => {
            this.loadedScripts[url].error(
                'An error has occurred with the script',
            );
            delete this.loadedScripts[url];
        };
        renderer.appendChild(document.body, script);

        return this.loadedScripts[url].asObservable();
    }
}
