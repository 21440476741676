import { CartItem } from '../models/cart-item/cart-item.model';
import { SkepsApplication } from '../models/skeps.model';
import { testCartDetailFactory } from './cart';
import { testCustomerDataFactory } from './customer';
import { testOrderFactory } from './order';
import { testSiteSettingsFactory } from './settings';

const {
    first_name,
    last_name,
    email,
    phone_number,
    address_line_1,
    city,
    state,
    zip_code,
} = testCustomerDataFactory();

const customerDetails = {
    firstName: first_name,
    lastName: last_name,
    email,
    phoneNumber: phone_number,
    streetAddress: address_line_1,
    city,
    state,
    zipcode: zip_code,
};

const testSkepsApplication: SkepsApplication = {
    mode: 'modal',
    merchantId: '1234',
    cartAmount: testCartDetailFactory().total,
    cartDetails: {
        items: testCartDetailFactory().products.map((product: CartItem) => ({
            itemName: product.title,
            itemValue: product.price,
            SKU: product.id.toString(),
        })),
        shipping: testCartDetailFactory().shipping_total,
        taxes: testCartDetailFactory().tax,
    },
    invoiceId: testOrderFactory().order_id,
    customerDetails,
    billingDetails: customerDetails,
    shippingDetails: customerDetails,
    metadata: {
        enervee_order_id: testOrderFactory().order_id,
        cartId: testCartDetailFactory().id,
        userId: '1234',
        anonymousId: '4321',
        writeKey: testSiteSettingsFactory().segment_write_key as string,
    },
};

export const testSkepsApplicationFactory = (
    overrides?: Partial<SkepsApplication>,
): SkepsApplication => {
    return { ...testSkepsApplication, ...overrides };
};
