<div class="debugger d-print-none">
    <button (click)="createCart()">create cart</button>
    <button (click)="addProduct()">addProduct</button>
    <button (click)="applicationCreateOrUpdate()">
        applicationCreateOrUpdate
    </button>
    <button (click)="getCustomerId()">getCustomerId</button>
    <button (click)="openIncentiveForm()">openIncentiveForm</button>
    <button (click)="closeIncentiveForm()">closeIncentiveForm</button>
    <button (click)="getScheduleWindows()">getScheduleWindows</button>
    <button (click)="updateCart()">updateCart</button>
    <button (click)="getCart()">get cart</button>
    <button (click)="checkout()">checkout</button>
    <button (click)="getFinalBill()">get final bill</button>
    <button (click)="getZipcode()">getZipcode</button>
    <button (click)="initSkepsFlow()">getSkepsApplication</button>
    <input
        type="number"
        id="productId"
        [value]="productId"
        placeholder="product id" />
    <div *ngIf="incentiveForm" class="incentiveForm">
        <iframe [src]="incentiveFormUrl | safe" frameborder="0"></iframe>
    </div>
    <span *ngIf="loading">loading ...</span>
    <div>
        <span>Financeable: </span>
        <span *ngIf="isCartFinanceable$ | async; else noBlock">Yes</span>
        <ng-template #noBlock>No</ng-template>
    </div>
</div>
