import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Cart, ExternalOrderLink, Order } from '@common-models';
import { SettingsService } from '@core/settings/settings.service';
import { TitleCasePipe } from '@angular/common';
import { EventListService } from '@core/event-list/event-list.service';
import { CartService } from '@core/cart/cart.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'order-details',
    templateUrl: './order-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent implements OnInit {
    @Input() order: Order;
    cart$: Observable<Cart>;

    constructor(
        private eventListService: EventListService,
        private settingsService: SettingsService,
        private titleCasePipe: TitleCasePipe,
        private cartService: CartService,
    ) {}

    ngOnInit() {
        this.cart$ = this.cartService.cart$;
    }

    get externalPartnerDetails(): ExternalOrderLink | undefined {
        const fulfillmentInfo = this.settingsService.getSiteSetting(
            'commerce_fulfillment_retailers',
        );
        return fulfillmentInfo[this.order.fulfillment_partner_name]
            ?.external_order_link;
    }

    get externalPartnerOrderLink(): string | undefined {
        return this.externalPartnerDetails?.link;
    }

    get orderReceiptFileName(): string {
        const utilityName = this.settingsService.getSiteSetting('title');
        return `${utilityName}_order`;
    }

    /**
     * Formatted shipping status of an order to remove '_' and titleize
     * the status
     *
     * Ex: fulfillment_pending => Fulfillment Pending
     */
    get statusContext(): Record<string, string> {
        const status = this.order.shipping_status || this.order.status;
        const spacedStatus = status?.replace('_', ' ');
        return { status: this.titleCasePipe.transform(spacedStatus) };
    }

    /**
     * Sends event when the external order lookup link has been clicked
     */
    onExternalOrderClick(): void {
        this.eventListService.sendRetailerOrderLinkClickedEvent(
            this.externalPartnerDetails?.link,
        );
    }
}
