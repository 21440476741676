import { Offer, Product, ProductData } from '@common-models';
import { testCartItemFactory } from './cart-item';

const offer: Offer = {
    created: '"2021-05-21T06:49:45.199684-07:00"',
    is_active: true,
    link: 'https://www.dpbolvw.net/click-5623602-13489155?url=https%3A%2F%2Fwww.abt.com%2FGoogle-Nest-Polished-Steel-3rd-Gen.-Learning-Smart-Thermostat-T3019US%2Fp%2F146727.html',
    no_price_info: false,
    price: '199.00',
    regular_price: '250.00',
    required_accessories: [],
    shipping: 'See Store for Shipping',
    site_affiliate_links_map: [],
    sponsored: false,
    store_logo:
        'https://res.cloudinary.com/enervee/image/upload/v1502302038/kaos7aa1ydab1sa3ugcn.jpg',
    store_logo_cloudinary: 'kaos7aa1ydab1sa3ugcn',
    store_name: 'Abt',
    total_price: '199.00',
};

export const baseProduct: ProductData = {
    active_offer_retailers: [],
    aep_ohio_2019_thermostat_qpl: 1,
    aps_ranking: 1,
    aps_thermostat_qpl: 1,
    avista_or_qpl: 1,
    brand: 'Google Nest',
    brand_id: '',
    category_id: 1,
    category_name: '',
    certifications_json: {
        all: [
            {
                badge_text: '',
                description: 'ENERGY STAR Certified',
                id: 232,
                image: 'https://s3.enervee.com/media/certs/estar.png',
                is_badge: false,
                use_image: true,
            },
        ],
    },
    child_ids: null,
    color_category: '',
    coned_storefront_qpl: 1,
    coned_storefront_qpl_2020: 1,
    curated: ['sdge'],
    description_summary: '',
    display_name: 'ecobee3 lite Smart Thermostat - Black',
    dlc_thermostats_qpl: 1,
    ect_thermostat_qpl: 1,
    enabled: 1,
    energy_star_10_connected_thermostats: 1,
    has_active_offer: 1,
    has_enervee_score: 1,
    highlight: {},
    id: 1,
    image_url:
        '//images.enervee.com/image/upload/v1587512920/products/us/img_2134808160.png',
    is_leaf: '1',
    is_root: '1',
    link: '',
    link_from_name: '',
    model: '',
    model_number: 'GA01334-US',
    name: '',
    offers_json: [{ ...offer }],
    offers_updated_date: '',
    partner_sites: [],
    platforms: null,
    popularity_score: 91.4,
    price: 236.43,
    price_level_number: 3,
    proposition_65_warning_message:
        'CALIFORNIA RESIDENTS ONLY - WARNING: Cancer and Reproductive Harm -www.P65Warnings.ca.gov',
    programmable: 'Yes',
    rebate_providers: [],
    recency_factor: 0.89,
    regress_flagged: 0,
    review_count: 405,
    review_score: 4.63,
    reviews_rank: 0.9095131109750161,
    sort: null,
    swiftype_id: '',
    tags_json: [],
    thumbnail_image: 'products/us/img_2134808160',
    top_specs_json: [],
    touch_screen: 'Yes',
    updated: '',
    updated_at: '',
    variation_group_json: [],
    clear_cost: 10,
    enervee_score: 99,
    model_baseline_enervee: '',
    pge_smart_choice_advanced_tier: '',
    checkoutEnabled: null,
    checkoutInfo: testCartItemFactory(),
    varying_features_json: [
        {
            variations: [
                {
                    id: 2,
                    price: 22,
                    rebate: {
                        amount: 20,
                    },
                },
            ],
        },
    ],
    total_additional_discounts: 40,
};

export const testProductDataFactory = (
    overrides: Partial<ProductData> = {},
): ProductData => {
    return { ...baseProduct, ...overrides };
};

export const testProductFactory = (
    overrides: Partial<ProductData> = {},
): Product => {
    return Object.assign(new Product(), testProductDataFactory(overrides));
};

export const testOfferFactory = (overrides: Partial<Offer> = {}): Offer => {
    return {
        ...offer,
        ...overrides,
    };
};
