import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category, CategoryFacets } from '@common-models';
import { ObjectsResponse } from '@server/utils/site-objects';
import { SettingsData } from '@server/utils/site-settings';
import { StylesData } from '@server/utils/site-styles';
import { Observable, of } from 'rxjs';
import { catchError, finalize, share, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private siteObjects: ObjectsResponse = null;
    private siteObjectsObservable: Observable<ObjectsResponse> = null;

    constructor(private http: HttpClient) {}

    /**
     * Gets the value of a site setting or the default value. If a default
     * isn't provided, null is returned.
     *
     * @example
     * this.siteName = SettingsService.getSiteSetting('site_name', '');
     */
    getSiteSetting<T>(
        key: string,
        defaultValue: T = null,
    ): SettingsData['siteSettings']['value'] {
        const siteSettings = this.siteObjects?.siteSettings;
        if (siteSettings && key in siteSettings) {
            return siteSettings[key];
        } else {
            return defaultValue;
        }
    }

    /**
     * Gets whether a feature is enabled in site settings.
     *
     * @example
     * this.prop65Enabled = SettingsService.isFeatureEnabled('prop_65');
     */
    isFeatureEnabled(featureKey: string): boolean {
        const enabledFeatures = this.getSiteSetting<Array<string>>(
            'enabled_features',
            [],
        ) as Array<string>;
        return enabledFeatures.includes(featureKey);
    }

    /**
     * Gets whether a feature is enabled specifically for cart in site settings.
     *
     * @example
     * this.debuggerEnabled = SettingsService.isCartFeatureEnabled('debugger');
     */
    isCartFeatureEnabled(featureKey: string): boolean {
        const cartEnabledFeatures = this.getSiteSetting<Array<string>>(
            'cart_enabled_features',
            [],
        ) as Array<string>;
        return cartEnabledFeatures.includes(featureKey);
    }

    /**
     * Gets the value of a category setting or the default value. If a default
     * isn't provided, null is returned.
     *
     * @example
     * this.categorySettingValue = SettingsService.getCategorySetting(
     *     'product_recommendation_parameters',
     *     14,
     *     {}
     * );
     */
    getCategorySetting<T>(
        key: string,
        category: number,
        defaultValue: T = null,
    ): SettingsData['siteSettings']['value'] {
        const categorySettings = this.siteObjects.categorySettings;
        if (!(category in categorySettings)) {
            return defaultValue;
        } else if (key in categorySettings[category]) {
            return categorySettings[category][key];
        } else {
            return defaultValue;
        }
    }

    /**
     * Gets the site styles associated with the current site.
     *
     * @example
     * this.siteStyles = SettingsService.getSiteStyles();
     */
    getSiteStyles(): StylesData['siteStyles'] | undefined {
        return this.siteObjects?.siteStyles;
    }

    /**
     * Gets the categories for the active site from the site objects.
     *
     * @example
     * this.categories = SettingsService.getCategories();
     */
    getCategories(): Array<Category> | undefined {
        return this.siteObjects?.categories;
    }

    /**
     * Gets all of the category facets for the active site from the site objects.
     *
     * @example
     * this.categoryFacets = SettingsService.getCategoryFacets();
     */
    getCategoryFacets(): Record<string, CategoryFacets> | undefined {
        return this.siteObjects?.categoryFacets;
    }

    /**
     * Makes the request to get site objects from the node backend. Site objects
     * includes site settings, category settings, nav links, categories, and
     * category facets.
     *
     * NOTE: you shouldn't need to call this anywhere, since the main route
     * uses SettingsResolverService to request settings before loading
     * any other child routes, including their resolvers.
     */
    requestSettings(): Observable<ObjectsResponse> {
        const url = 'api/settings';
        let objects: Observable<ObjectsResponse>;
        if (this.siteObjects) {
            objects = of(this.siteObjects);
        } else if (this.siteObjectsObservable) {
            objects = this.siteObjectsObservable;
        } else {
            this.siteObjectsObservable = this.http
                .get<ObjectsResponse>(url)
                .pipe(
                    tap((res: ObjectsResponse) => {
                        this.siteObjects = res;
                        if (environment.environmentName === 'local') {
                            this.siteObjects.siteSettings.product_api_base_url =
                                environment.backendUrl;
                        }
                    }),
                    catchError((error: HttpErrorResponse) => {
                        console.error(`Error fetching site objects`, error);
                        return of(null as ObjectsResponse);
                    }),
                    share(),
                    finalize(() => {
                        this.siteObjectsObservable = null;
                    }),
                );
            objects = this.siteObjectsObservable;
        }
        return objects;
    }
}
