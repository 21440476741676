import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { kebabCase } from 'lodash';

import {
    API_V2,
    CAPI_PROXY_URL,
    PRODUCT_ADDON_ENDPOINT,
    PRODUCT_API_URL_SETTING,
} from '@server/constants';
import { Addon, AddonForUI, CartItem, Product } from '@common-models';
import { SimpleStore } from '@core/simple-store/simple-store.service';
import { GeoService } from '@core/geo/geo.service';
import { SettingsService } from '@core/settings/settings.service';
import { AddonResponse } from '@core/addon/addon-response.model';
import { handleGenericHttpError } from '@utils/handle-http-error';

@Injectable({
    providedIn: 'root',
})
export class AddonService {
    private readonly baseCheckoutEndpointUrl: string;
    private productAddonApiUrl: string;
    private _isFetchingAddons$ = new BehaviorSubject(false);

    constructor(
        private geoService: GeoService,
        private http: HttpClient,
        private settingsService: SettingsService,
        private simpleStore: SimpleStore,
    ) {
        this.baseCheckoutEndpointUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.productAddonApiUrl = `${this.baseCheckoutEndpointUrl}${CAPI_PROXY_URL}${API_V2}${PRODUCT_ADDON_ENDPOINT}`;
    }

    get isFetchingAddons$(): Observable<boolean> {
        return this._isFetchingAddons$;
    }

    /**
     * Gets an addons with the zipcode for products.
     */
    getAddons(
        productIds: number[],
        fulfillmentPartnerCode: string,
    ): Observable<AddonResponse> {
        return this.geoService.getZipcode().pipe(
            switchMap((zipcode) => {
                if (!zipcode) {
                    return of({});
                }
                return this.simpleStore.get<AddonResponse>(
                    [
                        'products',
                        productIds,
                        'product-addons',
                        zipcode,
                        'zipcode',
                    ],
                    () =>
                        this.requestAddons(
                            productIds,
                            fulfillmentPartnerCode,
                            zipcode,
                        ).pipe(catchError((_) => of({}))),
                );
            }),
        );
    }

    /**
     * Groups any selected services with addons
     */
    combineAddons(
        addon: AddonForUI,
        selectedAddons: AddonForUI[] = [],
    ): AddonForUI[] {
        return [...selectedAddons, addon];
    }

    /**
     * POST request to retrieve product addon data
     */
    requestAddons(
        productIds: Array<number>,
        fulfillmentPartnerCode: string,
        zipcode: string,
    ): Observable<AddonResponse> {
        this._isFetchingAddons$.next(true);
        return this.http
            .post<AddonResponse>(
                this.productAddonApiUrl,
                {
                    products: productIds,
                    fulfillment_partner_code: fulfillmentPartnerCode,
                    zipcode,
                },
                {
                    withCredentials: true,
                },
            )
            .pipe(
                catchError(
                    handleGenericHttpError(
                        `Error getting product addons ${productIds}`,
                    ),
                ),
                finalize(() => this._isFetchingAddons$.next(false)),
            );
    }

    /**
     * Group any addons that require each other.  For instance, the
     * haul-away addon required installation, so it is group under the
     * installation addon.
     */
    groupNestedAddons(addons: AddonForUI[]): AddonForUI[] {
        const haulawayAddon = addons.find(
            (addon: AddonForUI) => addon.name === 'haulaway',
        );
        if (haulawayAddon) {
            return addons
                .filter((addon: AddonForUI) => addon.name !== 'haulaway')
                .reduce((acc: AddonForUI[], curr: AddonForUI) => {
                    if (curr.name === 'installation') {
                        curr.services = [haulawayAddon];
                    }
                    acc.push(curr);
                    return acc;
                }, []);
        }
        return addons;
    }

    /**
     * CSS selectors can't have spaces, so we convert them to kebab
     * case using a lodash method.
     */
    addDataSelectorName(addon: AddonForUI) {
        addon.selectorName = kebabCase(addon.name);
    }

    /**
     * Decouples any nested services from an AddonForUI and returns an
     * array of addons.
     */
    decoupleAddonsForUI(addons: AddonForUI[] | undefined): Addon[] {
        return addons?.reduce((acc, addonForUI) => {
            const { services, ...addon } = addonForUI;
            acc.push(addon, ...(services ?? []));
            return acc;
        }, []);
    }
}
