import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@core/navigation/navigation.service';
import { NextBestAction } from '@common-models';

@Component({
    selector: 'next-best-action',
    templateUrl: './next-best-action.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextBestActionComponent {
    @Input() nextBestActionConfigs: NextBestAction[];

    constructor(
        private router: Router,
        private navigationService: NavigationService,
    ) {}

    /**
     * Determines what to do based on an action.
     * When navigating to a link, it either navigates to a
     * route in the cart app, or a url back to the marketplace, depending
     * on the action configuration.
     */
    onNextBestAction(action: NextBestAction) {
        //TODO: APP-14803: implement event list service to report selected action item
        if (action.isBackToMarketplace) {
            this.navigationService.goToMarketplace(action.link);
        } else {
            this.router.navigate([action.link]);
        }
    }
}
