import { v4 as uuidv4 } from 'uuid';

const getUniqueId = () => uuidv4();
/**
 * Takes an array of objects and a property name. Iterates over all objects in the array and creates a
 * new array of just the property values if the property exists on the object.
 */
const reduceToProperty = (arr: object[], propName: string): any[] => {
    const forcedArr = forceArray(arr);
    return forcedArr.reduce((acc, curr) => {
        if (typeof curr === 'object' && curr.hasOwnProperty(propName)) {
            acc.push(curr[propName]);
        }
        return acc;
    }, []);
};

/**
 * Resolves a path into the last accessible property and the object we're attempting to
 * access it on.
 */
const resolvePath = (
    obj: object,
    path: string | string[],
    options: any,
): resolvePathtype => {
    const defaultOptions = {
        breakOnNull: true,
        makeMissingProps: false,
    };
    options = Object.assign(defaultOptions, options || {});
    path = normalizePath(path) as string[];
    let key: string;
    // Drill down through the nested objects until we're out of keys or we break
    while (path.length > 0) {
        key = String(path[0]);
        let nestedObj = obj[key];
        const isLastKey = path.length === 1;
        // If the value of the key we're currently on is undefined:
        if (nestedObj === undefined) {
            /**
             * If we're getting and not setting, then just break out of the while loop
             * right here.
             */
            if (!options.makeMissingProps) {
                break;
            }
            /**
             * If we are setting and this isn't the last key, we should create an empty
             * object as this key's value so that we can reach the last key.
             */
            if (!isLastKey) {
                obj[key] = {};
                nestedObj = obj[key];
            }
        }
        /**
         * If the value of the key we're currently on is null and the options specify
         * breaking on null values, then just break out of the while loop.
         */
        if (nestedObj === null && options.breakOnNull) {
            break;
        }
        /**
         * If we're not on the last key, reassign nestedObj so that we can keep
         * drilling down into the object tree and reach the last key.
         */
        if (!isLastKey) {
            obj = nestedObj;
        }
        // Remove a key from the beginning of the path to progress the loop
        path.shift();
    }
    // Return a key + object pair so that we can set the property if need be
    return {
        prop: key,
        parent: obj,
        value: obj[key],
    };
};

type resolvePathtype = {
    prop: string;
    parent: object;
    value: object;
};

/**
 * Helper function to flatten potentially complex paths into a standard format.
 *
 * @example
 * normalizePath('foo.bar'); // ['foo', 'bar']
 * @example
 * normalizePath(['foo', 'bar']); // ['foo', 'bar']
 * @example
 * normalizePath(['foo', 'bar.fizz']); // ['foo', 'bar', 'fizz']
 * @example
 * normalizePath(['foo', ['bar', 'fizz.buzz']]); // ['foo', 'bar', 'fizz', 'buzz']
 */
const normalizePath = (path: string | string[]): string[] => {
    if (typeof path === 'string') {
        return path.split('.');
    } else if (!Array.isArray(path)) {
        path = [String(path)];
    }
    /**
     * If the argument was already array, we should make sure that any nested
     * arrays are already flattened to a list of strings, then flatten _this_
     * array.
     */
    return path.reduce((newPath, subPath) => {
        return newPath.concat(normalizePath(subPath));
    }, []);
};

const forceArray = (item: any): any[] => {
    if (Array.isArray(item)) {
        return item;
    }
    return item !== undefined ? [item] : [];
};

/**
 * Retrieves the name of the current view for the Tag Manager
 */
const getPageType = (): string => {
    return 'other';
};

export const utilsFactory = (overrides: Partial<any> = {}): any => {
    return {
        reduceToProperty,
        resolvePath,
        normalizePath,
        forceArray,
        getPageType,
        getUniqueId,
        ...overrides,
    };
};
