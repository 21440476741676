<ng-container
    *ngIf="{
        isCartFinanceable: isCartFinanceable$ | async
    } as states">
    <div class="mt-4 pb-2 d-flex justify-content-between">
        <ng-container
            *ngFor="
                let paymentMethod of getAvailablePaymentOptions(
                    states.isCartFinanceable
                );
                index as i
            ">
            <payment-method-v2
                class="w-100"
                [ngClass]="
                    getPaymentMethodStyling(states.isCartFinanceable, i)
                "
                [paymentMethod]="paymentMethod"
                [hasUnavailableProduct]="hasUnavailableProduct">
            </payment-method-v2>
        </ng-container>
    </div>
    <div *ngIf="states.isCartFinanceable" class="small">
        <wa-cms-content
            [cmsKey]="'cart:payment:eco_financing_info_text'"
            [context]="financeCMSContext">
        </wa-cms-content>
        <a
            data-eid="eco-financing-learn-more"
            class="ps-1 text-decoration-underline"
            role="button"
            (click)="openEcoFinancingModal()">
            <wa-cms-content
                cmsKey="cart:payment:eco_financing_info_link"></wa-cms-content>
        </a>
        <eco-financing-modal></eco-financing-modal>
    </div>
</ng-container>
