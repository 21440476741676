import { cloneDeep } from 'lodash';
import { testRebateFactory } from '@common-mocks';
import { CartItemRebate } from '@common-models';

const baseRebateEligibilityPartiallyEligible: CartItemRebate = {
    product_id: 166950666,
    product_quantity: 1,
    total_incentive_amount: '30.00',
    incentives: [
        testRebateFactory({ id: 12, eligible: true }),
        testRebateFactory({ id: 34, eligible: false }),
    ],
};

const baseRebateEligibilityFullyEligible: CartItemRebate = {
    product_id: 166135558,
    product_quantity: 2,
    total_incentive_amount: '170.00',
    incentives: [testRebateFactory({ id: 12, eligible: true })],
};

const baseRebateEligibilityNotEligible: CartItemRebate = {
    product_id: 166950666,
    product_quantity: 1,
    total_incentive_amount: '30.00',
    incentives: [testRebateFactory({ id: 12, eligible: false })],
};

const baseRebateEligibilityNoRebates: CartItemRebate = {
    product_id: 166573492,
    product_quantity: 1,
    total_incentive_amount: '0',
    incentives: [],
};

const baseRebateEligibilityAllHidden: CartItemRebate = {
    product_id: 166950666,
    product_quantity: 1,
    total_incentive_amount: '30.00',
    incentives: [
        testRebateFactory({ id: 12, eligible: true, hidden: true }),
        testRebateFactory({ id: 34, eligible: true, hidden: true }),
    ],
};

const baseRebateEligibilityPartiallyHidden: CartItemRebate = {
    product_id: 166950666,
    product_quantity: 1,
    total_incentive_amount: '30.00',
    incentives: [
        testRebateFactory({ id: 12, eligible: true, hidden: true }),
        testRebateFactory({ id: 34, eligible: true, hidden: false }),
    ],
};

const baseRebateEligibilityBusinessCodeFI: CartItemRebate = {
    product_id: 166950666,
    product_quantity: 1,
    total_incentive_amount: '30.00',
    incentives: [
        testRebateFactory({
            id: 12,
            eligible: true,
            business_rule_codes: ['FI'],
        }),
    ],
};

/**
 * Abstract factory to create eligibility factories
 */
const createEligibilityFactory =
    (eligibilityData: CartItemRebate) =>
    (overrides: Partial<CartItemRebate> = {}): CartItemRebate => {
        return cloneDeep({
            ...eligibilityData,
            ...overrides,
        });
    };

export const testRebateEligibilityNotEligibleFactory =
    createEligibilityFactory(baseRebateEligibilityNotEligible);
export const testRebateEligibilityNoRebatesFactory = createEligibilityFactory(
    baseRebateEligibilityNoRebates,
);
export const testRebateEligibilityPartiallyEligibleFactory =
    createEligibilityFactory(baseRebateEligibilityPartiallyEligible);
export const testRebateEligibilityFullyEligibleFactory =
    createEligibilityFactory(baseRebateEligibilityFullyEligible);
export const testRebateEligibilityAllHiddenFactory = createEligibilityFactory(
    baseRebateEligibilityAllHidden,
);
export const testRebateEligibilityPartiallyHiddenFactory =
    createEligibilityFactory(baseRebateEligibilityPartiallyHidden);
export const testRebateEligibilityBusinessCodeFI = createEligibilityFactory(
    baseRebateEligibilityBusinessCodeFI,
);

const baseRebateEligibilityResponseNoRebates: Array<CartItemRebate> = [
    testRebateEligibilityNoRebatesFactory({ product_id: 166573492 }),
    testRebateEligibilityNoRebatesFactory({ product_id: 166573493 }),
];

const baseRebateEligibilityResponsePartiallyEligible: Array<CartItemRebate> = [
    testRebateEligibilityPartiallyEligibleFactory({ product_id: 165180961 }),
    testRebateEligibilityFullyEligibleFactory({ product_id: 166135558 }),
    testRebateEligibilityNoRebatesFactory({ product_id: 166573492 }),
];

const baseRebateEligibilityResponseFullyEligible: Array<CartItemRebate> = [
    testRebateEligibilityFullyEligibleFactory({ product_id: 165180961 }),
    testRebateEligibilityFullyEligibleFactory({ product_id: 166135558 }),
];

const baseRebateEligibilityResponseNotEligible: Array<CartItemRebate> = [
    testRebateEligibilityNotEligibleFactory({ product_id: 165180961 }),
];

const baseRebateEligibilityResponseAllHidden: Array<CartItemRebate> = [
    testRebateEligibilityAllHiddenFactory({ product_id: 165180961 }),
];

const baseRebateEligibilityResponsePartiallyHidden: Array<CartItemRebate> = [
    testRebateEligibilityAllHiddenFactory({ product_id: 165180961 }),
    testRebateEligibilityPartiallyEligibleFactory({ product_id: 166135558 }),
];

/**
 * Abstract factory to create eligibility response factories
 */
const createEligibilityResponseFactory =
    (eligibilityData: CartItemRebate[]) =>
    (overrides: Partial<CartItemRebate[]> = []): CartItemRebate[] => {
        return cloneDeep([...eligibilityData, ...overrides]);
    };

export const testRebateEligibilityResponseNoRebatesFactory =
    createEligibilityResponseFactory(baseRebateEligibilityResponseNoRebates);

export const testRebateEligibilityResponsePartiallyEligibleFactory =
    createEligibilityResponseFactory(
        baseRebateEligibilityResponsePartiallyHidden,
    );

export const testRebateEligibilityResponseFullyEligibleFactory =
    createEligibilityResponseFactory(
        baseRebateEligibilityResponseFullyEligible,
    );

export const testRebateEligibilityResponseNotEligibleFactory =
    createEligibilityResponseFactory(baseRebateEligibilityResponseNotEligible);

export const testRebateEligibilityResponseAllHiddenFactory =
    createEligibilityResponseFactory(baseRebateEligibilityResponseAllHidden);

export const testRebateEligibilityResponsePartiallyHiddenFactory =
    createEligibilityResponseFactory(
        baseRebateEligibilityResponsePartiallyEligible,
    );
