import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerData, GeoInfo, GeoIp, UserIP } from '@common-models';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { SettingsService } from '@core/settings/settings.service';
import { GEOIP_ENDPOINT, IPIFY_URL } from '@server/constants';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    finalize,
    map,
    share,
    switchMap,
    tap,
} from 'rxjs/operators';
import { CustomerDetailService } from '@core/customer-detail/customer-detail.service';
import { handleGenericHttpError } from '@utils/handle-http-error';

@Injectable({
    providedIn: 'root',
})
export class GeoService {
    private _isEditable$ = new BehaviorSubject<boolean>(false);
    private geoInfoByIp: Record<string, GeoIp> = {};
    private geoInfoByIpMap: Record<string, Observable<GeoIp>> = {};
    private _zipcode: string;
    private _zipcode$ = new BehaviorSubject<string>(this.zipcode);

    constructor(
        private http: HttpClient,
        private customerDetailService: CustomerDetailService,
        private settingsService: SettingsService,
        private localStorageService: LocalStorageService,
    ) {}

    private get geoIpKey(): string {
        return this.settingsService.getSiteSetting(
            'ipstack_access_key',
        ) as string;
    }

    /**
     * Gets the zipcode value that can be edited by the user. It uses the
     * property value of the zipcode if available, otherwise, fetches the
     * zipcode from localstorage
     */
    get zipcode(): string {
        return (
            this._zipcode || this.localStorageService.getItem<string>('zip')
        );
    }

    /**
     * Sets the zipcode value to a property and localstorage then emits the
     * new zipcode to the zipcode observable stream
     */
    set zipcode(zipcode: string) {
        this.localStorageService.setItem<string>('zip', zipcode);
        this._zipcode = zipcode;
        this._zipcode$.next(zipcode);
    }

    get zipcode$(): Observable<string> {
        return this._zipcode$.asObservable().pipe(distinctUntilChanged());
    }

    /**
     * Returns an observable to know if the zipcode is editable.
     */
    get isZipcodeEditable$(): Observable<boolean> {
        return this._isEditable$.asObservable();
    }

    /**
     * An observable that gets the geoInfo for a user.
     */
    getGeoInfo(): Observable<GeoInfo> {
        return this.requestIpAddress().pipe(
            switchMap((response: UserIP) =>
                this.requestGeoInfo(response.ip).pipe(
                    map((geo: GeoIp) => {
                        return {
                            latitude: geo.latitude,
                            longitude: geo.longitude,
                            zip: geo.zip,
                        };
                    }),
                ),
            ),
        );
    }

    /**
     * Makes a request to get the geo info of a user. Sets up some local
     * caching to fetch the details via the geoInfoByIpMap property if we
     * have already fetched the data, otherwise, makes an http call to
     * request the data.
     */
    requestGeoInfo(ip: string): Observable<GeoIp> {
        const url = `${GEOIP_ENDPOINT}${this.geoIpKey}`;
        let geoInfo$: Observable<GeoIp>;
        if (ip in this.geoInfoByIp) {
            geoInfo$ = of(this.geoInfoByIp[ip]);
        } else if (ip in this.geoInfoByIpMap) {
            geoInfo$ = this.geoInfoByIpMap[ip];
        } else {
            this.geoInfoByIpMap[ip] = this.http.get<GeoIp>(url).pipe(
                tap((response: GeoIp) => {
                    this.geoInfoByIp = Object.assign(
                        {},
                        this.geoInfoByIp,
                        response,
                    );
                }),
                catchError(
                    handleGenericHttpError('Error fetching GeoIp data'),
                ),
                share(),
                finalize(() => {
                    delete this.geoInfoByIpMap[ip];
                }),
            );
            geoInfo$ = this.geoInfoByIpMap[ip];
        }
        return geoInfo$;
    }

    /**
     * Request to fetch the ip address of a user.
     */
    requestIpAddress(): Observable<UserIP> {
        return this.http.get<UserIP>(IPIFY_URL);
    }

    /**
     * Returns an observable stream to know what the zipcode value is.
     *
     * We make a call to the customer-details to check if the user has
     * filled out their shipping details. If so, then we emit the customer
     * zipcode value to be the zipcode and set the zipcode to not
     * be editable.
     *
     * If there are no customer details, we check to see if the zipcode
     * localStorage value has been set and if not then we make a call
     * to get the zipcode value via their geo info
     */
    getZipcode(): Observable<string> {
        return this.customerDetailService.customer$.pipe(
            tap((customer: CustomerData) => {
                if (!customer?.zip_code) throw new Error('missing zipcode');
            }),
            switchMap((customer: CustomerData) => {
                this._isEditable$.next(false);
                this.zipcode = customer.zip_code;
                return this.zipcode$;
            }),
            catchError(() => {
                this._isEditable$.next(true);
                if (this.zipcode) {
                    // get zipcode from local storage
                    return this.zipcode$;
                } else {
                    // if /customer-detail fail or missing zipcode, then get ip and zipcode from ipify and ipstack
                    return this.getGeoInfo().pipe(
                        switchMap((data: GeoInfo) => {
                            this.zipcode = data.zip;
                            return this.zipcode$;
                        }),
                    );
                }
            }),
        );
    }
}
