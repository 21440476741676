import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * DomSanitizer Safe pipe to prevent Cross Site Scripting Security bugs (XSS) by sanitizing
 * values to be safe to use in the different DOM contexts.
 *
 * Ex: src="incentiveFormUrl | safe"
 */
@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Sanitizes a value for use in a given SecurityContext.
     * Bypass security and trust the given value to be a safe resource URL, i.e. a location that may
     * be used to load executable code from, like `<script src>`, or `<iframe src>`.
     */
    transform(url: SafeResourceUrl): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.sanitizer.sanitize(SecurityContext.URL, url),
        );
    }
}
