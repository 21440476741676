import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { SettingsService } from '@core/settings/settings.service';
import { SimpleStore } from '@core/simple-store/simple-store.service';
import {
    Application,
    CustomerData,
    CustomerDetailResponse,
    ExtendedApplication,
    Metadata,
    ShippingInfoPayload,
    ShippingInfoResponse,
} from '@common-models';
import {
    API_V1,
    CAPI_PROXY_URL,
    INCENTIVE_PROXY_URL,
    PRODUCT_API_URL_SETTING,
    REBATE_APPLICATION_ID,
} from '@server/constants';
import { handleGenericHttpError } from '@utils/handle-http-error';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { CookiesService } from '@enervee/webapp-common';

@Injectable({
    providedIn: 'root',
})
export class CustomerDetailService {
    private applicationId: string;
    private baseProductApiUrl: string;
    private customerId: string;
    private incentiveFormBaseUrl: string;
    private utilityIds: string[];
    private _customer$ = new BehaviorSubject<CustomerData>(null);

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        private simpleStore: SimpleStore,
        private analyticsService: AnalyticsService,
        private cookiesService: CookiesService,
    ) {
        this.baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.utilityIds = this.settingsService.getSiteSetting(
            'utility_include_list',
        ) as string[];
        this.applicationCreateOrUpdate();
    }

    /**
     * Call endpoint to get application id and iframe url for incentive service form.
     */
    applicationCreateOrUpdate(): Observable<Application> {
        const url = `${this.baseProductApiUrl}${CAPI_PROXY_URL}/api/carts/application/create-or-update/`;
        return this.http
            .post<Application>(
                url,
                { context: { source: 'checkout' } },
                { withCredentials: true },
            )
            .pipe(
                tap((res) => {
                    this.applicationId = res.application_id;
                    this.incentiveFormBaseUrl = res.url;
                    this.cookiesService.set(
                        REBATE_APPLICATION_ID,
                        this.applicationId,
                    );
                }),
                take(1),
                catchError(
                    handleGenericHttpError('Error fetching createOrUpdate'),
                ),
            );
    }

    set customer(customer: CustomerData) {
        this._customer$.next(customer);
    }

    /**
     * Get customer observable.
     * if customer is empty call endpoint to get customer info.
     */
    get customer$(): Observable<CustomerData> {
        return this._customer$.getValue() === null
            ? this.retrieveCustomerDetails()
            : this._customer$;
    }

    private get metadata(): Metadata {
        const userId = this.analyticsService.user.id();
        const metadata: Metadata = {
            anonymous_id: this.analyticsService.user.anonymousId(),
            location: 'cart',
        };
        if (userId) {
            metadata.user_id = userId;
        }
        return metadata;
    }

    /**
     * Get customer name and address, returns customer observable
     */
    retrieveCustomerDetails(): Observable<CustomerData | null> {
        return this.requestCustomerDetails().pipe(
            map((customer: CustomerDetailResponse) =>
                this.formatCustomerObj(customer),
            ),
            tap((formattedCustomer) => {
                this.customer = formattedCustomer;
            }),
            catchError(() => {
                return of(null);
            }),
        );
    }

    /**
     * Call customer-details endpoint.
     */
    requestCustomerDetails(): Observable<CustomerDetailResponse> {
        const url = `${this.baseProductApiUrl}${CAPI_PROXY_URL}/api/carts/customer-details/`;

        return this.http
            .get<CustomerDetailResponse>(url, { withCredentials: true })
            .pipe(
                catchError(
                    handleGenericHttpError('Error get customer detail'),
                ),
            );
    }

    /**
     * Call endpoint to POST Shipping Form data.
     * Update customer detail
     */
    addShippingAddress(
        payload: CustomerData,
    ): Observable<ShippingInfoResponse> {
        const addressPayload: ShippingInfoPayload = {
            metadata: this.metadata,
            customer: {
                first_name: payload.first_name,
                last_name: payload.last_name,
                phone_number: payload.phone_number,
                email: payload.email,
            },
            address: {
                address_line_1: payload.address_line_1,
                address_line_2: payload.address_line_2 || '',
                city: payload.city,
                state: payload.state,
                zip_code: payload.zip_code,
            },
        };
        const url = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}applications/shipping-info/${this.applicationId}`;
        return this.http.post<ShippingInfoResponse>(url, addressPayload).pipe(
            tap((shippingInfoRes: ShippingInfoResponse) => {
                this.customer = {
                    ...shippingInfoRes.customer,
                    ...shippingInfoRes.address,
                };
            }),
            take(1),
            catchError(
                handleGenericHttpError('Error fetching createOrUpdate'),
            ),
        );
    }

    /**
     * Convert response object from customer-detail to CustomerData
     */
    formatCustomerObj(customer: CustomerDetailResponse): CustomerData {
        const customerObj = { ...customer, ...customer.address[0] };
        delete customerObj.address;
        return customerObj;
    }

    /**
     * Call endpoint to POST UtilityValidation
     * This lets us know if the user was able to validate with just their
     * shipping info or if their account information is required for
     * additional validation.
     *
     * This endpoint can also be used to submit their account number to
     * validate they are a customer of that utility.
     */
    getUtilityValidation(accountNumber?: string): Observable<null> {
        const url = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}applications/utility-validation/${this.applicationId}`;

        return this.http
            .post<any>(
                url,
                accountNumber ? { account_number: accountNumber } : {},
            )
            .pipe(
                catchError(handleGenericHttpError('Error utility-validation')),
                take(1),
            );
    }

    /**
     * Helper function to generate proper iframe url for incentive service form
     */
    generateIframeUrl(
        cartId: string,
        fullScreen: boolean = true,
        watchFrameHeight: boolean = true,
    ): Observable<string> {
        const suid = this.metadata.anonymous_id;
        const getApplicationUrl = this.applicationCreateOrUpdate().pipe(
            map((app: Application) => app.url),
        );
        return forkJoin([this.getCustomerId(), getApplicationUrl]).pipe(
            map(([customerId, appUrl]) => {
                const urlParameter = [
                    `customerId=${customerId}`,
                    `suid=${suid}`,
                    `trackingCartId=${cartId}`,
                    `fullScreen=${fullScreen}`,
                    `watchFrameHeight=${watchFrameHeight}`,
                    // Adding ngsw-bypass as a parameter at the iframe src url
                    // prevent Angular router catch any route change in the iframe.
                    `ngsw-bypass=true`,
                ].join('&');
                return `${appUrl}&${urlParameter}`;
            }),
        );
    }

    /**
     * Get a customer id for generate incentive service form iframe url
     */
    getCustomerId(): Observable<string> {
        const url = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}customer/external-id/`;
        return this.http.get<string>(url).pipe(
            tap((res) => {
                this.customerId = res;
            }),
            catchError(handleGenericHttpError('Error getting customer id')),
        );
    }

    /**
     * Fetch the full application details based on the application id
     */
    getApplication(applicationId?: string): Observable<ExtendedApplication> {
        const id = applicationId ? applicationId : this.applicationId;
        const url = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}applications/${id}/`;

        return this.http
            .get<ExtendedApplication>(url, {})
            .pipe(
                catchError(
                    handleGenericHttpError('Error getting application id'),
                ),
            );
    }

    /**
     * Fetch the application details and check if application was validated
     */
    isApplicationValid(): Observable<boolean> {
        return this.applicationCreateOrUpdate().pipe(
            switchMap((partialApp: Application) => {
                return this.getApplication(partialApp.application_id);
            }),
            map(
                (applicationData: ExtendedApplication) =>
                    applicationData?.is_valid === true,
            ),
        );
    }
}
