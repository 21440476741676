import { Component, OnInit } from '@angular/core';
import { GeoService } from '@core/geo/geo.service';
import { Observable } from 'rxjs';
import { Modal } from 'bootstrap';

@Component({
    selector: 'zipcode',
    templateUrl: './zipcode.component.html',
})
export class ZipcodeComponent implements OnInit {
    zipcode$: Observable<string>;
    isEditable$: Observable<boolean>;

    constructor(private geoService: GeoService) {}

    ngOnInit(): void {
        this.isEditable$ = this.geoService.isZipcodeEditable$;
        this.zipcode$ = this.geoService.getZipcode();
    }

    openEditZipcodeModal() {
        const ecoFinancingModal = new Modal(
            document.getElementById('editZipcodeModal'),
        );
        ecoFinancingModal.show();
    }
}
