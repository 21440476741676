<div
    data-eid="cart-text-label-row"
    class="row justify-content-between"
    [ngClass]="{ 'lh-lg': displayConfig.largeLineHeight }">
    <div
        class="col"
        [ngClass]="
            displayConfig.wideValueColumn
                ? 'col-sm-3 col-md-4 '
                : 'col-sm-9 col-md-8 '
        ">
        <div
            data-eid="cart-text-label-label"
            class="cart-text-label"
            [ngClass]="displayConfig.labelStyles">
            <wa-cms-content
                *ngIf="cmsKeys?.label; else directLabel"
                cmsKey="{{ cmsKeys.label }}"
                [context]="context">
            </wa-cms-content>
            <ng-template #directLabel>
                <ng-container
                    *ngIf="directTexts?.label as label; else contentLabel">
                    {{ label }}
                </ng-container>
                <ng-template #contentLabel>
                    <ng-content select="[label]"></ng-content>
                </ng-template>
            </ng-template>
        </div>
        <div
            data-eid="cart-text-label-helper"
            class="cart-text-helper small text-muted">
            <wa-cms-content
                *ngIf="cmsKeys?.helper; else directHelper"
                cmsKey="{{ cmsKeys.helper }}"
                [context]="context">
            </wa-cms-content>
            <ng-template #directHelper>
                <ng-container
                    *ngIf="directTexts?.helper as helper; else contentHelper">
                    {{ helper }}
                </ng-container>
                <ng-template #contentHelper>
                    <ng-content select="[helper]"></ng-content>
                </ng-template>
            </ng-template>
        </div>
    </div>
    <div
        data-eid="cart-text-label-value"
        class="cart-text-value"
        [ngClass]="{
            'col text-end': displayConfig.isHorizontal
        }">
        <wa-cms-content
            *ngIf="cmsKeys?.value; else directValue"
            class="cart-text-display-key"
            cmsKey="{{ cmsKeys.value }}"
            [context]="context">
        </wa-cms-content>
        <ng-template #directValue>
            <ng-container *ngIf="directTexts?.value as value; else content">
                {{ value }}
            </ng-container>
            <ng-template #content>
                <ng-content select="[value]"></ng-content>
            </ng-template>
        </ng-template>
    </div>
</div>
