import { Component, Input, OnInit } from '@angular/core';
import { PageTitleTexts } from './page-title.model';

@Component({
    selector: 'page-title',
    templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit {
    @Input() directTexts?: PageTitleTexts['directTexts'];
    @Input() cmsKeys?: PageTitleTexts['cmsKeys'];

    constructor() {}

    ngOnInit(): void {}
}
