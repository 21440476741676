<header class="navbar bg-body-tertiary bg-white shadow-sm text-center">
    <div
        class="container py-2 d-flex justify-content-center align-items-center">
        <a routerLink="/" data-eid="header-logo">
            <wa-image-wrapper
                class="enervee-logo"
                imagePublicId="logos/enervee-logo-2024"
                [classList]="'position-relative justify-content-start'"
                alt="Enervee Logo">
            </wa-image-wrapper>
        </a>
    </div>
    <div class="container d-flex justify-content-center align-items-center">
        <breadcrumbs></breadcrumbs>
    </div>
</header>
