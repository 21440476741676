import _ from 'lodash';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    CartItem,
    DeliveryShippingOptions,
    DeliveryTime,
} from '@common-models';
import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { CmsService } from '@core/cms/cms.service';
import { CustomNgbDateParserFormatter } from '@core/custom-ngb-providers/custom-ngb-parser-formatter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartService } from '@core/cart/cart.service';
import { EventListService } from '@core/event-list/event-list.service';
import { tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'delivery-options',
    styleUrls: ['delivery-options.component.scss'],
    templateUrl: './delivery-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: CustomNgbDateParserFormatter,
        },
    ],
})
export class DeliveryOptionsComponent implements OnInit {
    @Input() cartItem: CartItem;
    @Input() isUpdatingCart: boolean;
    @Input() isFetchingScheduleWindows: boolean;
    @Input() deliveryWindows: DeliveryShippingOptions['delivery_options'];

    deliveryTimes$: ReplaySubject<DeliveryTime[]> = new ReplaySubject();
    datepickerPlaceholderText$: Observable<string>;
    timepickerPlaceholderText$: Observable<string>;

    dateModel: NgbDateStruct | undefined;
    timeFrom: string | undefined;

    constructor(
        private dateParserFormatter: NgbDateParserFormatter,
        private cartService: CartService,
        private cmsService: CmsService,
        private eventListService: EventListService,
    ) {}

    ngOnInit() {
        this.setupTexts();
        this.setInitialDateTime();
    }

    /**
     * Flag for if the cart is in a loading state or if awaiting scheduleWindows
     */
    get isLoading() {
        return this.isUpdatingCart || this.isFetchingScheduleWindows;
    }

    /**
     * Flag to determine if the time dropdown should be disabled or not.
     */
    get isTimeDisabled() {
        return !this.dateModel || this.isLoading;
    }

    /**
     * This sets the date and time for a previously selected datetime for an item
     * Sets the dateModel and timeFrom and finalizes the selection
     */
    setInitialDateTime() {
        let selectedDate;
        const dateSaved =
            this.cartItem.delivery_datetime && this.isValidDate();
        if (dateSaved) {
            selectedDate = this.cartItem.delivery_datetime;
        } else if (Object.keys(this.deliveryWindows).length === 0) {
            return;
        } else {
            const firstDate = Object.keys(this.deliveryWindows)[0];
            const firstTime = this.deliveryWindows[firstDate][0].from;
            selectedDate = formatDate(
                `${firstDate} ${firstTime}`,
                'yyyy-MM-ddThh:mm:ssZ',
                'en-US',
            );
        }
        this.dateModel = this.dateParserFormatter.parse(selectedDate);
        this.dateSelected();
        this.timeFrom = selectedDate.split('T')[1].substring(0, 5);
        if (!dateSaved) {
            // the date was automatically prefilled. update the cart.
            this.saveDeliverySelection();
        }
    }

    /**
     * A datetime string, eg: "2022-10-31T15:00"
     */
    private get datetime(): string {
        const { day, month, year } = this.dateModel;
        const dateStr = new Date(year, month - 1, day)
            .toISOString()
            .split('T')[0];
        return `${dateStr}T${this.timeFrom}`;
    }

    private setupTexts() {
        this.datepickerPlaceholderText$ = this.cmsService.getMarketPlaceText(
            'cart:delivery_installation:datepicker_placeholder',
        );
        this.timepickerPlaceholderText$ = this.cmsService.getMarketPlaceText(
            'cart:delivery_installation:timepicker_placeholder',
        );
    }

    /**
     * Validate that the selected date is listed within the delivery windows
     */
    isValidDate(): boolean {
        return Boolean(
            this.deliveryWindows[
                this.dateParserFormatter.format(
                    this.dateParserFormatter.parse(
                        this.cartItem.delivery_datetime,
                    ),
                )
            ],
        );
    }

    isDateDisabled = (date: NgbDateStruct): boolean => {
        const formattedDate = this.dateParserFormatter.format(date);
        return !_.has(this.deliveryWindows, formattedDate);
    };

    /**
     * When a date is selected, the `deliveryTimes` observable is
     * updated to contain the relevant times for that date.
     */
    dateSelected() {
        this.timeFrom = undefined;
        const dateStr = this.dateParserFormatter.format(this.dateModel);
        const times = this.deliveryWindows[dateStr];
        this.deliveryTimes$.next(times);
    }

    /**
     * Save the delivery selection
     */
    saveDeliverySelection() {
        this.cartItem.delivery_datetime = this.datetime;
        this.cartService
            .updateCart()
            .pipe(
                tap((cart) => {
                    this.eventListService.sendDeliveryDatetimesChosen(cart.id);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
