<div class="d-flex align-items-center bg-light rounded-top mt-4 p-2">
    <div>
        <div data-eid="fulfillment-partner-text" class="small">
            <wa-cms-content
                cmsKey="cart:fulfillment_partner_text"
                [context]="{
                    retailer: partnerInfo.display_name
                }">
            </wa-cms-content>
            <a
                *ngIf="partnerInfo.return_policy"
                class="text-decoration-underline"
                (click)="trackReturnPolicyClicked()"
                data-eid="fulfillment-partner-return-policy-link"
                [href]="partnerInfo.return_policy.src">
                {{ partnerInfo.return_policy.text }}
            </a>
        </div>
    </div>
</div>
