import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION } from './environments/version';
import { UAT, PRODUCTION, SENTRY_DSN } from '@server/constants';

if (
    environment.environmentName === UAT ||
    environment.environmentName === PRODUCTION
) {
    Sentry.init({
        environment: environment.environmentName,
        dsn: SENTRY_DSN,
        release:
            'cart-' +
            environment.environmentName +
            '@' +
            VERSION.hash.substring(0, 8), // first 7 chars of the commit hash
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.production ? 0.2 : 1,
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
