import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { SharedModule } from '@shared-module';
import { OrderSummaryPageComponent } from './order-summary-page.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { WebappCommonModule } from '@enervee/webapp-common';

@NgModule({
    declarations: [OrderSummaryPageComponent, OrderDetailsComponent],
    imports: [CommonModule, SharedModule, WebappCommonModule],
    exports: [OrderDetailsComponent],
    providers: [TitleCasePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderSummaryPageModule {}
