import _ from 'lodash';
import {
    DiscountName,
    Discounts,
    Rebate,
    VaryingFeature,
    VaryingFeatures,
} from '@common-models';
import { Accessories } from '../accessories.model';
import { ProductItem } from '../product-item/product-item.model';
import { CartItem } from '../cart-item/cart-item.model';

export enum FulfillabilityStatus {
    yes = 'Yes',
    no = 'No',
}

export interface Offer {
    created: string;
    is_active: boolean;
    link: string;
    no_price_info: boolean;
    price: string;
    regular_price: string | null;
    required_accessories: Accessories[];
    shipping: string;
    site_affiliate_links_map: Record<string, unknown>[];
    sponsored: boolean;
    store_logo: string;
    store_logo_cloudinary: string;
    store_name: string;
    total_price: string;
}

interface Certification {
    badge_text?: string;
    description: string;
    id: number;
    image: string;
    is_badge: boolean;
    use_image: boolean;
}

interface FulfillmentPartner {
    id: number;
    name: string;
    price: string;
    in_stock: boolean;
}

export class Product extends ProductItem {
    id: number;
    brand: string;
    brand_id: string;
    category_id: number;
    category_name: string;
    certifications_json: {
        all: Certification[];
    };
    child_ids?: Record<string, unknown>[] | null;
    clear_cost: number;
    color_category?: string;
    curated?: string[];
    display_name: string;
    description_summary: string;
    dlc_thermostats_qpl?: number;
    ect_thermostat_qpl?: number;
    enabled: number;
    enervee_score: number;
    active_offer_retailers?: string[];
    has_active_offer: number;
    has_enervee_score: number;
    height?: number;
    highlight?: Record<string, unknown>;
    image_url: string;
    is_leaf: string;
    is_root: string;
    link: string;
    link_from_name: string;
    lowest_fulfillment_price?: number;
    model: string;
    model_baseline_enervee: string;
    model_number: string;
    name: string;
    offers_json?: Offer[];
    offers_updated_date: string;
    partner_sites: number[];
    aep_ohio_2019_thermostat_qpl?: number;
    energy_star_10_connected_thermostats?: number;
    coned_storefront_qpl?: number;
    coned_storefront_qpl_2020?: number;
    avista_or_qpl?: number;
    aps_thermostat_qpl?: number;
    aps_ranking?: number;
    pge_smart_choice_advanced_tier?: string;
    platforms?: Record<string, unknown> | null;
    popularity_score: number;
    price: number;
    price_level_number: number;
    proposition_65_warning_message?: string;
    programmable?: string;
    rebate_providers: number[];
    #rebates?: Rebate[];
    recency_factor: number;
    review_count: number;
    review_score: number;
    reviews_rank: number;
    regress_flagged?: number;
    sort: Record<string, unknown> | null;
    swiftype_id: string;
    tags_json: Record<string, unknown>[];
    thumbnail_image: string;
    top_specs_json: Record<string, unknown>[];
    touch_screen?: string;
    updated: string;
    updated_at: string;
    variation_group_json?: Record<string, unknown>[];
    varying_features_json?: VaryingFeatures[];
    total_additional_discounts?: number;
    lowest_priced_child?: VaryingFeature;
    checkoutEnabled?: boolean | null;
    checkoutInfo?: CartItem | null;
    checkoutInStock?: boolean;
    checkoutMSRP?: string;
    checkoutHasRebate?: boolean;
    product_id?: number;
    in_stock?: boolean;
    finance_providers?: Array<number>;
    incentives?: Rebate[];
    _parentEventId?: string;
    rebatesLoading?: boolean;
    fulfillment_partners?: FulfillmentPartner[] | string[];
    default_retailer?: FulfillmentPartner | null;
    fulfillment_providers?: number[];
    is_fulfillable?: FulfillabilityStatus;

    protected get totalAdditionalDiscounts(): number {
        return (
            this.checkoutInfo?.appliedDiscounts.additional ||
            this.total_additional_discounts ||
            0
        );
    }

    get fullPrice(): number {
        return Number(
            this.checkoutInfo?.fullPrice || this.price || this.offersFullPrice,
        );
    }

    /**
     * The full price from the from offers_json array
     * if it exists, otherwise null.
     */
    get offersFullPrice(): number | null {
        const offerWithRegularPrice = this.offers_json?.find(
            (offer: Offer) => offer.regular_price !== null,
        );
        const regularPrice = Number(offerWithRegularPrice?.regular_price);
        return regularPrice >= 0 ? regularPrice : null;
    }

    get salePrice(): number {
        return (
            this.checkoutInfo?.salePrice ||
            this.lowest_fulfillment_price ||
            this.price
        );
    }

    get rebatesList(): Rebate[] {
        return this.checkoutInfo?.rebatesList.length
            ? this.checkoutInfo.rebatesList
            : this.#rebates;
    }

    set rebatesList(rebates: Rebate[]) {
        this.#rebates = rebates;
    }

    /**
     * Gets the highest rebates using the rebate.highestInType property.
     */
    get highestRebates(): Rebate[] {
        return (
            this.rebatesList?.filter(
                (rebate: Rebate) => rebate.highestInType,
            ) || []
        );
    }

    /**
     * Get the sum of all available rebates by highest of their type.
     */
    get totalAvailableRebateAmount(): number {
        const rebates = this.highestRebates;
        return (
            Number(_.sumBy(rebates, (item: Rebate) => Number(item.amount))) ||
            0
        );
    }

    /**
     * Get the available discounts for a product including total_additional_discounts,
     * sales discounts, and rebates that are available for the product
     */
    get availableDiscounts(): Discounts {
        const { additional, sales } = this.discountsWithoutRebates;
        return {
            rebates: this.totalAvailableRebateAmount,
            additional,
            sales,
        };
    }

    /**
     * Create a new discount object with specified discount types excluded.
     */
    filterDiscounts(omissions: DiscountName[] = []): Discounts {
        return _.omit(this.availableDiscounts, omissions);
    }

    get isFulfillable(): boolean {
        return this.is_fulfillable === FulfillabilityStatus.yes;
    }

    /**
     * The required accessories from the offers_json array or
     * null if no array exists.
     */
    get requiredAccessories(): Accessories[] | null {
        return (
            this.offers_json?.reduce((acc: Accessories[], offer: Offer) => {
                return [...acc, ...offer.required_accessories];
            }, []) || null
        );
    }

    /**
     * The total amount of all required accessories.
     */
    get requiredAccessoriesTotal(): number {
        return Number(
            _.sumBy(this.requiredAccessories || [], (item: Accessories) =>
                Number(item.price),
            ),
        );
    }

    get fulfillmentPartnerId(): number {
        return (
            this.checkoutInfo?.fulfillment_partner_id ??
            this.default_retailer?.id
        );
    }

    get fulfillmentPartnerName(): string {
        return (
            this.checkoutInfo?.fulfillment_partner_name ??
            this.default_retailer?.name
        );
    }
}

export type ProductData = Omit<
    Product,
    | 'discounts'
    | 'totalAdditionalDiscounts'
    | 'fullPrice'
    | 'offersFullPrice'
    | 'salePrice'
    | 'rebatesList'
    | 'isFulfillable'
    | 'saleDiscountAmount'
    | 'availableDiscounts'
    | 'discountsWithoutRebates'
    | 'highestRebates'
    | 'totalAvailableRebateAmount'
    | 'filterDiscounts'
    | 'isOnSale'
    | 'requiredAccessories'
    | 'requiredAccessoriesTotal'
    | 'rebates'
    | 'fulfillmentPartnerId'
    | 'fulfillmentPartnerName'
>;
