/**
 * List of constants for the application
 */

/**
 * List of environment variable constants that are used for configuring
 * the webapp project
 */
export const ENVIRONMENT = 'ENVIRONMENT';
export const UTILITY = 'UTILITY';
export const DISABLE_CACHE = 'DISABLE_CACHE';

/**
 * List of setting name constants
 */
export const PRODUCT_API_URL_SETTING = 'product_api_base_url';
export const CMS_URL_SETTING = 'cms_base_url';
export const KNOWN_BOT_USER_AGENTS = 'known_bot_user_agents';
export const KNOWN_USER_IP = 'known_user_ip';
export const SEGMENT_ORGANIZATION_NAME = 'segment_organization_name';
export const SEGMENT_TRACKING_PRODUCT_DETAILS =
    'segment_tracking_product_details';
export const SEGMENT_WRITE_KEY = 'segment_write_key';
export const TITLE = 'title';
export const SKEPS_SCRIPT_URL = 'skeps_script_url';
export const PAYMENT_FINANCE_CONFIG = 'payment_finance_config';
export const FINANCE_APPLICATION_ID = 'finance_application_id';
export const REBATE_APPLICATION_ID = 'application_id';
export const OCR_POLLING_CONFIG = 'ocr_polling_config';

/**
 * List of constant variables that are used as default values
 */

/**
 * Used for setting a default UTILITY value
 */
export const ENERVEE = 'enervee';

/**
 * Used for setting a default ENVIRONMENT variable
 */
export const UAT = 'uat';
export const PRODUCTION = 'production';

/**
 * Base Url to access the Settings Service
 */
export const SETTINGS_SERVICE_BASE_URL =
    'https://settings-service.enervee.com/';

/**
 * Endpoint to get settings data from the Settings Service
 */
export const SETTINGS_ENDPOINT = 'settings/';

/**
 * Endpoint to get styles data from the Settings Service
 */
export const STYLES_DATA = 'site-styles/';

/**
 * Endpoint to get categories data from Root
 */
export const CATEGORIES_ENDPOINT = 'categories/';

/**
 * Endpoint to get category facets from Root
 */
export const CATEGORY_FACETS_ENDPOINT = 'swiftype/category-facets/';

/**
 * Endpoint to get JWT token from root
 */
export const JWT_TOKEN_ENDPOINT = 'token/';

/**
 * Endpoint to get product data from Root
 */
export const PRODUCT_ENDPOINT = 'swiftype/product/';

export const ANALYTICS_ENDPOINT = 'log-segment/';
export const ANALYTICS_ID_COOKIE = 'segment_anon_user_id';

/**
 * Endpoint to get checkout data
 */
export const CAPI_PROXY_URL = '/checkout-api';

/**
 * Endpoint to get incentive data
 */
export const INCENTIVE_PROXY_URL = '/rebate-api';

/**
 * API version 1 to use for ROOT endpoints
 */
export const API_V1 = '/api/1.0/';

/**
 * API version 2 to use for ROOT endpoints
 */
export const API_V2 = '/api/2.0/';

/**
 * Endpoint to get geo ip data
 */
export const GEOIP_ENDPOINT = 'https://api.ipstack.com/check?access_key=';

export const IPIFY_URL = 'https://api.ipify.org?format=json';

/**
 * Sentry DSN for configuring Sentry
 */
export const SENTRY_DSN =
    'https://1a0ec49d0ac74a7d92d9bb0452fb10bd@sentry.enervee.com/9';

/**
 * Endpoint to get results data from Root
 */
export const RESULTS_ENDPOINT = 'swiftype/search/';

/**
 * Endpoint to get product features data from Root
 */
export const FEATURES_ENDPOINT = 'product-features/';

/**
 * Endpoint to get product details data from Root
 */
export const PRODUCT_DETAILS_ENDPOINT = 'product-details/';

/**
 * Endpoint to get product images data from Root
 */
export const PRODUCT_IMAGES_ENDPOINT = 'product-images/';

/**
 * Endpoint to get product addons data from Root
 */
export const PRODUCT_ADDON_ENDPOINT = 'product-addons/';

/**
 * Name of cookie for marketplace path
 */
export const MARKETPLACE_PATH = 'webapp_recent_path';

/**
 * Message when OCR is not done processing the image. Also is returned in regular incentive calls
 * when customer details have not been specified yet.
 */
export const WITHOUT_CUSTOMER_MESSAGE =
    'Application not associated with a customer yet.';
