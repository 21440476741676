<div
    *ngIf="{
        installationTooltipText: installationTooltipText$ | async
    } as viewStates">
    <div class="mt-1 mb-0 fw-bold">
        <wa-cms-content
            cmsKey="cart:addon:title_installation"></wa-cms-content>
    </div>
    <div
        *ngFor="let addon of addons"
        class="d-flex justify-content-between mt-3"
        [attr.data-eid]="createAddonSelector(addon)">
        <div class="d-flex justify-content-start">
            <input
                [disabled]="isUpdatingCart || isDisabled(addon)"
                [name]="addon.name"
                [id]="addon.service_id"
                type="checkbox"
                class="form-check-input"
                [checked]="isAddonSelected(addon)"
                (change)="toggleCartItemAddon(addon)" />
            <label class="form-check-label d-block ps-2">
                {{ addon.name | titlecase }}
            </label>
            <span
                *ngIf="
                    this.isInstallationAddon(addon) &&
                    requiredAccessories.length
                "
                data-eid="addon-tootip">
                <i
                    placement="bottom"
                    triggers="hover"
                    [autoClose]="true"
                    [ngbTooltip]="viewStates.installationTooltipText"
                    data-eid="required-accessories-text-tooltip"
                    tooltipClass="tooltip"
                    class="v2 v2-info ps-2">
                </i>
            </span>
        </div>
        <div>
            <span>{{ getAddonPrice(addon) | currency }}</span>
        </div>
    </div>
</div>
