import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    Cart,
    CartItem,
    PaymentMethod,
    ScheduleWindows,
} from '@common-models';
import { CmsKeys } from '@shared-components/text-label/cms-keys.model';
import { CartService } from '@core/cart/cart.service';
import { ScheduleWindowService } from '@core/schedule-window/schedule-window.service';

@Component({
    selector: 'total-details',
    templateUrl: './total-details.component.html',
    styleUrls: ['./total-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalDetailsComponent implements OnInit {
    @Input() scheduleWindows: ScheduleWindows;

    cart$: Observable<Cart>;
    totalCMSKeys$: Observable<CmsKeys>;
    isShippableCart$: Observable<boolean>;

    constructor(
        private cartService: CartService,
        private scheduleWindowService: ScheduleWindowService,
    ) {}

    ngOnInit(): void {
        this.cart$ = this.cartService.cart$;
        this.setupViewTexts();
        this.setupViewStates();
    }

    get hasDeliveryOptions(): boolean {
        return this.scheduleWindowService.hasDeliveryOptions(
            this.scheduleWindows,
        );
    }

    get hasShippingOptions(): boolean {
        return this.scheduleWindowService.hasShippingOptions(
            this.scheduleWindows,
        );
    }

    private setupViewStates() {
        this.isShippableCart$ = this.cart$.pipe(
            map((cart: Cart) => this.checkIsCartShippable(cart)),
        );
    }

    private setupViewTexts() {
        this.totalCMSKeys$ = this.cart$.pipe(
            map((cart: Cart) => this.createTotalTextKeys(cart.payment_method)),
        );
    }

    private createTotalTextKeys(paymentMethod: PaymentMethod): CmsKeys {
        return {
            label: 'commerce:total',
            helper:
                paymentMethod === PaymentMethod.finance
                    ? 'cart:one_finance_transaction_method'
                    : undefined,
        };
    }

    /**
     * Checks to see if the cart is shippable via if there are shippingOptions
     * passed in or if the cart has any shipping options selected
     */
    private checkIsCartShippable(cart: Cart): boolean {
        const hasShippingSelected = cart.products.some((item: CartItem) => {
            return item.shipping_id !== null;
        });
        return this.hasShippingOptions || hasShippingSelected;
    }

    /**
     * Checks if the cart has any deliverable items
     */
    isCartDeliverable(cart: Cart): boolean {
        const hasDeliverySelected = cart.products.some((item: CartItem) => {
            return item.delivery_datetime !== null;
        });
        return this.hasDeliveryOptions || hasDeliverySelected;
    }
}
