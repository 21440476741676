import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { WebappCommonModule } from '@enervee/webapp-common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { DebuggerComponent } from './debugger/debugger.component';
import { SharedModule } from '@shared-module';
import { OrderSummaryPageModule } from './pages/order-summary-page/order-summary-page.module';
import { AddProductPageModule } from './pages/add-product-page/add-product-page.module';
import { CustomerDetailsPageModule } from './pages/customer-details-page/customer-details-page.module';
import { CartPageModule } from './pages/cart-page/cart-page.module';

@NgModule({
    declarations: [AppComponent, DebuggerComponent],
    imports: [
        AppRoutingModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        CoreModule,
        HttpClientModule,
        SharedModule,
        OrderSummaryPageModule,
        AddProductPageModule,
        CustomerDetailsPageModule,
        WebappCommonModule,
        CartPageModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
