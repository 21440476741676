<ng-container
    *ngIf="{
        cart: cart$ | async,
    } as states">
    <div class="pt-3 pb-4">
        <a
            data-eid="back-to-shopping"
            role="button"
            [cartBackButton]="backRoute"
            [goToMarketplace]="true">
            <i class="v2 v2-arrow-left fw-bold pe-1"></i>
            <wa-cms-content
                cmsKey="cart:view_products_button"></wa-cms-content>
        </a>
    </div>
    <h2 class="mb-3">
        <wa-cms-content cmsKey="cart:cart_page_title"></wa-cms-content>
    </h2>
    <ng-container *ngIf="states.cart.products.length; else emptyCart">
        <cart-details></cart-details>
    </ng-container>
    <ng-template #emptyCart>
        <div
            data-eid="empty-cart"
            class="container-fluid empty-cart-container bg-white d-flex justify-content-center align-items-center">
            <wa-cms-content
                class="fw-bold fs-3"
                cmsKey="cart:empty_cart_label"></wa-cms-content>
        </div>
    </ng-template>
</ng-container>
