import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddProductPageComponent } from './add-product-page.component';
//import { ShippingPageComponent } from '../customer-details-page/shipping-page/shipping-page.component';
import { RebatesPageComponent } from '../customer-details-page/rebates-page/rebates-page.component';
//import { AccountPageComponent } from '../customer-details-page/account-page/account-page.component';

const routes: Routes = [
    {
        path: '',
        component: AddProductPageComponent,
        children: [
            { path: '', redirectTo: 'addons', pathMatch: 'full' },
            // Commented since not used currently but might need in future
            //{ path: 'shipping', component: ShippingPageComponent },
            //{ path: 'account', component: AccountPageComponent },
            { path: 'rebates', component: RebatesPageComponent },
        ],
    },
];

/**
 * @description
 *
 * Contains routing logic for views within the Add-product Page module.
 *
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AddProductPageRoutingModule {}
