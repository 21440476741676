import { PageTitleTexts } from '@shared-components/page-title/page-title.model';

export interface GeneralErrorPageViewState {
    buttonConfig: {
        goTo: string;
        textKey: string;
    };
    pageTitleTextsConfig: PageTitleTexts;
}
export const PageNotFoundState: GeneralErrorPageViewState = {
    buttonConfig: {
        goTo: '/',
        textKey: 'cart:back_to_cart_button',
    },
    pageTitleTextsConfig: {
        cmsKeys: {
            title: 'error_page:page_404_title',
            description: 'error_page:page_404_text',
        },
    },
};
