import { cloneDeep, last, kebabCase } from 'lodash';
import { CmsService } from '@core/cms/cms.service';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Addon, CartItem, Product, Accessories } from '@common-models';
import { Observable } from 'rxjs';
import { addons } from '@core/constants';

@Component({
    selector: 'addons',
    templateUrl: './addons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonsComponent implements OnInit {
    @Input() addons: Addon[];
    @Input() cartItem: CartItem;
    @Input() product: Product;
    @Input() isUpdatingCart: boolean;
    @Output() updateItemAddons = new EventEmitter<CartItem>();

    installationTooltipText$: Observable<string>;

    constructor(private cmsService: CmsService) {}

    ngOnInit() {
        this.installationTooltipText$ = this.cmsService.getMarketPlaceText(
            'cart:addon:installation_tooltip',
            { accessoriesText: this.requiredAccessoriesText },
        );
    }

    get requiredAccessories(): Accessories[] {
        return this.product.requiredAccessories;
    }

    get requiredAccessoriesTotal(): number {
        return this.product.requiredAccessoriesTotal;
    }

    /**
     * Formats the required accessories to a comma-separated string by name.
     */
    get requiredAccessoriesText(): string {
        const lastAccessory = last(this.requiredAccessories) as Accessories;
        return this.requiredAccessories.reduce((acc, curr) => {
            const text =
                lastAccessory.sku === curr.sku ? curr.name : curr.name + ', ';
            return acc + text;
        }, '');
    }

    /**
     * Check if installation is a chosen cart item addon.
     */
    get isInstallationChosen(): boolean {
        return Boolean(this.cartItem.addons?.find(this.isInstallationAddon));
    }

    /**
     * Check if a specific addon is already chosen for the cart item.
     */
    isAddonSelected(addon: Addon): boolean {
        return Boolean(
            this.cartItem.addons.find(
                (itemAddon: Addon) =>
                    itemAddon.service_id === addon.service_id,
            ),
        );
    }

    /**
     * Determines if an addon should be disabled based.
     * A selected addon is not disabled. Haulaway is disabled if installation
     * has not been chosen.
     */
    isDisabled(addon: Addon): boolean {
        if (this.isAddonSelected(addon)) {
            return false;
        }
        return addon.name === addons.HAULAWAY
            ? !this.isInstallationChosen
            : false;
    }

    isInstallationAddon(addon: Addon): boolean {
        return addon.name === addons.INSTALLATION;
    }

    /**
     * The installation addon price includes all required accessories.
     */
    getAddonPrice(addon: Addon): number {
        return this.isInstallationAddon(addon)
            ? addon.price + this.requiredAccessoriesTotal
            : addon.price;
    }

    /**
     * Emits event to update the cart addons. If the removed addon is installation,
     * all other addons are removed.
     */
    toggleCartItemAddon(addon: Addon): void {
        if (this.isUpdatingCart) return;
        const shouldRemoveSelectedAddon = this.isAddonSelected(addon);
        const isInstallation = this.isInstallationAddon(addon);
        let itemAddons = [...(this.cartItem.addons || [])];
        if (isInstallation && shouldRemoveSelectedAddon) {
            itemAddons = [];
        } else if (shouldRemoveSelectedAddon) {
            itemAddons = itemAddons.filter(
                (itemAddon: Addon) =>
                    itemAddon.service_id !== addon.service_id,
            );
        } else {
            itemAddons = [...itemAddons, addon];
        }
        const newCartItem = cloneDeep(this.cartItem) as CartItem;
        newCartItem.addons = itemAddons;
        this.updateItemAddons.emit(newCartItem);
    }

    /**
     * Use addon name to create valid selector. Spaces are not valid, so
     * they are replaced with hyphens.
     */
    createAddonSelector(addon: Addon): string {
        return kebabCase(`addon-${addon.name}`);
    }
}
