import { Injectable } from '@angular/core';
import {
    API_V1,
    API_V2,
    INCENTIVE_PROXY_URL,
    PRODUCT_API_URL_SETTING,
} from '@server/constants';
import { SettingsService } from '@core/settings/settings.service';
import {
    catchError,
    finalize,
    first,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { handleGenericHttpError } from '@utils/handle-http-error';
import { HttpClient } from '@angular/common/http';
import { RebateResponse } from '@core/customer-detail/rebate-response.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
    Application,
    Cart,
    CartItemRebate,
    ExtendedApplication,
    Rebate,
} from '@common-models';
import { SimpleStore } from '@core/simple-store/simple-store.service';
import _ from 'lodash';
import { RebateEligibilityStatus } from '@core/rebate/rebate-eligibility-status.model';
import { CustomerDetailService } from '@core/customer-detail/customer-detail.service';
import { EligibilityFlag } from 'src/app/models/eligibility-flag.model';
import { CartService } from '@core/cart/cart.service';
import { CAPI_PROXY_URL } from '@core/constants';
import { Form } from 'src/app/models/forms/form.model';

@Injectable({
    providedIn: 'root',
})
export class RebateService {
    private readonly baseProductApiUrl: string;
    private readonly utilityIds: string[];
    private _triggerRebateEligibility$ = new BehaviorSubject<boolean>(false);
    private _isLoadingRebates$ = new BehaviorSubject<boolean>(false);

    constructor(
        private cartService: CartService,
        private customerDetailService: CustomerDetailService,
        private http: HttpClient,
        private settingsService: SettingsService,
        private simpleStore: SimpleStore,
    ) {
        this.baseProductApiUrl = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
        ) as string;
        this.utilityIds = this.settingsService.getSiteSetting(
            'utility_include_list',
        ) as string[];
    }

    /**
     * Observable to listen to in order to know when to re-trigger
     * a check to check the rebate eligibility again
     */
    get triggerRebateEligibility$() {
        return this._triggerRebateEligibility$;
    }

    /**
     * Pushes a new event to let subscribers know when to re-trigger
     * a rebate eligibility check
     */
    pushTriggerRebateEligibility(value: boolean) {
        this._triggerRebateEligibility$.next(value);
    }

    /**
     * Loader to know when the rebates are being fetched
     */
    get isLoadingRebates$() {
        return this._isLoadingRebates$;
    }

    /**
     * Sets loader to know when the rebates are being fetched
     */
    setIsLoadingRebates(value: boolean) {
        this._isLoadingRebates$.next(value);
    }

    /**
     * Gets the rebates that are available for a product
     */
    getRebatesForProduct(productId: string): Observable<Rebate[]> {
        return this.simpleStore.get<Rebate[]>(
            ['products', productId, 'rebateForProduct'],
            () => this.requestRebatesForProduct(productId),
        );
    }

    /**
     * Requests the rebates that are available for a product
     */
    requestRebatesForProduct(productId: string): Observable<Rebate[]> {
        const url = `${this.baseProductApiUrl}${API_V2}rebates/rebates/`;
        const params = {
            product_ids: productId,
            provider_id: this.utilityIds[0],
        };
        return this.http
            .get<RebateResponse>(url, { withCredentials: true, params })
            .pipe(
                map((productResponse) => {
                    return this.setHighestAmountRebates(
                        productResponse[productId],
                    );
                }),
                catchError(
                    handleGenericHttpError(
                        'Error fetching rebates for product',
                    ),
                ),
            );
    }

    /**
     * Sets the highestInType flag for a rebate to find the rebates with
     * the highest amount for a given business rule
     *
     */
    setHighestAmountRebates(rebates: Rebate[]): Rebate[] {
        const rebateByType = {};
        // get rebate for each type with the highest amount, exclude combo
        rebates.forEach((rebate: Rebate) => {
            rebate.highestInType = false;
            if (
                rebate.business_rule_codes !== undefined &&
                rebate.business_rule_codes.length === 1
            ) {
                const rebateType = rebate.business_rule_codes[0] || '';
                if (!(rebateType in rebateByType)) {
                    rebateByType[rebateType] = rebate;
                } else if (
                    Number(rebateByType[rebateType].amount) <
                    Number(rebate.amount)
                ) {
                    rebateByType[rebateType] = rebate;
                }
            }
        });
        _.keys(rebateByType).forEach((rebateType: string) => {
            rebateByType[rebateType].highestInType = true;
        });
        return rebates;
    }

    /**
     * Commerce endpoint that lets commerce know when to add eligible
     * rebate data to the cart items & totals during the cart flow
     *
     * TODO: Replace any type for CartItemRebate once APP-14446 is implemented
     */
    getBestRebates(): Observable<any> {
        const url = `${this.baseProductApiUrl}/${CAPI_PROXY_URL}carts/best-rebates/`;
        return this.http
            .post<Cart>(url, {}, { withCredentials: true })
            .pipe(
                take(1),
                catchError(
                    handleGenericHttpError('Error fetching best-rebates'),
                ),
            );
    }

    /**
     * Observable of endpoint calls in order to get the incentive-eligibility
     * information and application validity states
     */
    runRebateEligibilityCalls$(): Observable<
        [ExtendedApplication, CartItemRebate[], Cart]
    > {
        return this.customerDetailService.applicationCreateOrUpdate().pipe(
            switchMap((partialApp: Application) => {
                return forkJoin([
                    this.customerDetailService
                        .getApplication(partialApp.application_id)
                        .pipe(take(1)),
                    this.fetchRebateEligibility(
                        partialApp.application_id,
                    ).pipe(take(1)),
                    this.cartService.cart$.pipe(first()),
                ]);
            }),
        );
    }

    /**
     * Gets the rebate eligibility status for a cart.
     *
     * First, makes a call to get the applicationId by calling applicationCreateOrUpdate
     * Then, makes a call to:
     * 1. Get the application status via the getApplication endpoint
     * 2. Get the rebate eligibility status via the fetchRebateEligibility endpoint
     * Once the calls have been received. We then find the eligibility status
     * based on the data retrieved.
     *
     * Includes setting the itemRebates to the cartService in order to
     * add back the rebate data after any new cart operations.
     */
    getRebateEligibilityForCart(): Observable<RebateEligibilityStatus> {
        return this.triggerRebateEligibility$.pipe(
            tap(() => {
                this.setIsLoadingRebates(true);
            }),
            switchMap(() => {
                return this.runRebateEligibilityCalls$().pipe(
                    tap(([__, rebates, cart]) => {
                        this.cartService.cartItemRebates = rebates;
                        this.cartService.setCartData(cart, true);
                    }),
                    take(1),
                    map(([application, rebates, __]) => {
                        return this.findRebateEligibilityStatus(
                            application,
                            rebates,
                        );
                    }),
                    finalize(() => {
                        this.setIsLoadingRebates(false);
                    }),
                );
            }),
        );
    }

    /**
     * Fetches the rebate eligibility for a cart based on the applicationId
     */
    fetchRebateEligibility(
        applicationId: string,
    ): Observable<Array<CartItemRebate>> {
        const url = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}incentive-eligibility/${applicationId}`;
        return this.http
            .get<Array<CartItemRebate>>(url, { withCredentials: true })
            .pipe(
                catchError(
                    handleGenericHttpError(
                        'Error fetching rebates for product',
                    ),
                ),
            );
    }

    /**
     * Finds out the rebate eligibility status based on the Application,
     * CartItemRebates, and Rebates
     *
     * NO_REBATES: if the cart has no available rebates for ANY products.
     * We want the NO_REBATES status to have precedence so that we do not
     * display additional information to the user that they cannot effectively
     * act on.
     *
     * MISSING_DETAILS: if the cart has not filled out their rebate form
     *
     * FULLY_ELIGIBLE: if ALL rebates available for the products in cart
     * are eligible for the user
     *
     * NOT_ELIGIBLE: if ALL rebates are NOT eligible for the products in
     * the cart
     *
     * PARTIALLY_ELIGIBLE: if the cart has rebates that are ELIGIBLE AND
     * NOT ELIGIBLE for the products within the cart
     */
    findRebateEligibilityStatus(
        application: ExtendedApplication,
        cartItemRebates: Array<CartItemRebate>,
    ): RebateEligibilityStatus {
        const eligibilityFlags =
            this.determineEligibilityFlags(cartItemRebates);
        return this.findEligibilityStatusFromFlags(
            eligibilityFlags,
            application,
        );
    }

    /**
     * Checks the cartItemRebates to see if all the rebates on the products
     * within the cart are, fully eligible, partially, eligible, or
     * not eligible.
     *
     * If a product has a rebate, then we toggle off the hasNoRebates flag.
     * If a rebate is eligible, then we toggle the isNotEligible flag,
     * otherwise, we toggle the isFullyEligible flag.
     */
    determineEligibilityFlags(
        cartItemRebates: CartItemRebate[],
    ): EligibilityFlag {
        const eligibilityFlags: EligibilityFlag = {
            hasNoRebates: true,
            isFullyEligible: true,
            isNotEligible: true,
        };
        cartItemRebates.forEach((cartItemRebate: CartItemRebate) => {
            if (!cartItemRebate.incentives.length) {
                return;
            }
            cartItemRebate.incentives.forEach((rebate: Rebate) => {
                if (!this.isRebateEligibilityApplicable(rebate)) {
                    return;
                }
                eligibilityFlags.hasNoRebates = false;
                if (rebate.eligible) {
                    eligibilityFlags.isNotEligible = false;
                } else {
                    eligibilityFlags.isFullyEligible = false;
                }
            });
        });
        return eligibilityFlags;
    }

    /**
     * If a rebate is hidden or has an an 'FI' business rule, then eligibility does not apply
     */
    isRebateEligibilityApplicable(rebate: Rebate): boolean {
        return (
            !rebate.hidden &&
            !rebate.business_rule_codes?.some((br: string) => br === 'FI')
        );
    }

    /**
     * Find the RebateEligibilityStatus based on the flags that have been
     * toggled or if the application is valid.
     */
    findEligibilityStatusFromFlags(
        flags: EligibilityFlag,
        application: ExtendedApplication,
    ): RebateEligibilityStatus {
        if (flags.hasNoRebates) {
            return RebateEligibilityStatus.NO_REBATES;
        } else if (!application.is_valid) {
            return RebateEligibilityStatus.MISSING_DETAILS;
        } else if (flags.isFullyEligible) {
            return RebateEligibilityStatus.FULLY_ELIGIBLE;
        } else if (flags.isNotEligible) {
            return RebateEligibilityStatus.NOT_ELIGIBLE;
        } else {
            return RebateEligibilityStatus.PARTIALLY_ELIGIBLE;
        }
    }
    /**
     * Gets forms associated with the given application. Needed to display terms and conditions
     * question for Financing flow.
     */
    getApplicationFormList(applicationId): Observable<Form[]> {
        const path = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}forms/${applicationId}`;

        return this.http.get<Form[]>(path, {
            withCredentials: true,
        });
    }

    submitReportingForm(applicationId, formId, data) {
        const path = `${this.baseProductApiUrl}${INCENTIVE_PROXY_URL}${API_V1}applications/reporting-data/${formId}/${applicationId}`;

        return this.http
            .post(path, data, { withCredentials: true })
            .pipe(
                catchError(handleGenericHttpError('Error reporting-form')),
                take(1),
            );
    }
}
