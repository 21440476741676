import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbConfig } from '@shared-components/breadcrumbs/breadcrumbs.model';
import { CartService } from '@core/cart/cart.service';
import { Observable } from 'rxjs';
import { Cart, PaymentMethod } from '@common-models';

/**
 * @description
 *
 * An auto-unsubscribe package.
 * Use with `.pipe(untilDestroyed(this)) before subscribing.
 */
@UntilDestroy()
@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
    configs: BreadcrumbConfig[] = [
        {
            path: '',
            cmsKey: `cart:breadcrumb:cart`,
            isLink: true,
        },
        {
            path: 'checkout/finance',
            cmsKey: `cart:breadcrumb:ecofinancing`,
            isFinance: true,
        },
        {
            path: 'checkout',
            cmsKey: `cart:breadcrumb:checkout`,
        },
        {
            path: 'order-summary',
            cmsKey: `cart:breadcrumb:complete`,
        },
    ];
    cart$: Observable<Cart>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private changeDetectorRef: ChangeDetectorRef,
        private cartService: CartService,
    ) {}

    ngOnInit(): void {
        this.initializeActivePath();
        this.cart$ = this.cartService.cart$;
        // Keep breadcrumb state in sync with the route in case
        // the browser back/forward buttons are used.
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((route) => route instanceof NavigationEnd),
            )
            .subscribe(this.initializeActivePath.bind(this));
    }

    /**
     * Check if current page is a breadcrumb configured page.
     */
    get isBreadcrumbPage(): boolean {
        return this.isCartPage || Boolean(this.activeConfig);
    }

    /**
     * Check if current page is the cart page.
     */
    get isCartPage(): boolean {
        return Boolean(!this.location.path() || this.location.path() === '/');
    }

    /**
     * Get the active breadcrumb config based on the location path.
     */
    get activeConfig(): BreadcrumbConfig {
        return this.configs.find((config: BreadcrumbConfig) => {
            return !config.path
                ? false
                : this.location.path().includes(config.path);
        });
    }

    /**
     * Update the configs to reflect the decided active config.
     */
    initializeActivePath() {
        if (this.activeConfig) {
            this.setActiveConfig(this.activeConfig);
        } else if (this.isCartPage) {
            // set to cart breadcrumb for base route
            this.setActiveConfig(this.configs[0]);
        }
    }

    /**
     * Set the new active config and then navigate to the config link.
     */
    handleLinkClick(crumbConfig: BreadcrumbConfig, index: number) {
        const isNotClickable =
            crumbConfig.isActive ||
            this.isBreadcrumbDisabled(index) ||
            !crumbConfig.isLink;
        if (isNotClickable) {
            return;
        }
        this.setActiveConfig(crumbConfig);
        this.router.navigate([crumbConfig.path], { relativeTo: this.route });
    }

    /**
     * Set the new active config and deactive other configs.
     */
    setActiveConfig(crumbConfig: BreadcrumbConfig) {
        this.configs.forEach((config) => (config.isActive = false));
        crumbConfig.isActive = true;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Check if breadcrumb item should be displayed.
     * Setup to check if financing related breadcrumbs should be displayed.
     *
     * @param crumbConfig
     * @param cart
     */
    isBreadcrumbItemDisplayed(
        crumbConfig: BreadcrumbConfig,
        cart: Cart,
    ): boolean {
        if (!crumbConfig.isFinance) return true;
        return (
            this.location.path().includes('checkout/finance') ||
            cart?.payment_method === PaymentMethod.finance
        );
    }

    /**
     * Check an index number against the current breadcrumb to see if
     * the index is for a future breadcrumb.
     * Disables breadcrumb if it is not a link.
     * Disables previous link if final breadcrumb.
     */
    isBreadcrumbDisabled(crumbIndex: number) {
        if (!this.configs[crumbIndex].isLink) return true;
        const activeConfigIndex = this.configs.findIndex(
            (config: BreadcrumbConfig) => config.isActive,
        );
        return (
            crumbIndex > activeConfigIndex ||
            activeConfigIndex === this.configs.length - 1
        );
    }
}
