import { Rebate } from '../rebate.model';
import { Addon } from '../addon.model';
import { ProductItem } from '../product-item/product-item.model';
import { Discounts } from '../discounts.model';
import { CartItemRebate } from '../cart-item-rebate.model';
import { addValues } from '@utils/add-values';

/**
 * Data object that represents products in the cart.
 * Properties:
 *
 * #itemRebate: Private frontend specific property that we are using to append
 *              the product and associated rebate data to manually
 */
export class CartItem extends ProductItem {
    id: number;
    category_id?: number;
    title?: string;
    model?: string;
    quantity: number;
    retail_price?: string;
    price: number | string;
    sku?: string;
    rebate?: Rebate;
    #itemRebate?: CartItemRebate;
    addons?: Array<Addon>;
    total_additional_discounts?: number;
    fulfillment_partner_code?: string;
    fulfillment_partner_id: number;
    fulfillment_partner_name: string;
    shipping_id?: number;
    shipping_date?: string;
    shipping_method?: string;
    delivery_datetime?: string;
    image_url?: string;

    /**
     * Total of eligible rebates after applied quantity multipliers.
     */
    private get totalAppliedRebateAmount(): number {
        return Number(this.itemRebate?.total_incentive_amount) || 0;
    }

    /**
     * Whatever total additional discounts are available.
     */
    protected get totalAdditionalDiscounts(): number {
        return this.total_additional_discounts || 0;
    }

    /**
     * The un-discounted price.
     */
    get fullPrice(): number {
        return Number(this.retail_price || this.price);
    }

    /**
     * The un-discounted price factoring in the item quantity
     */
    get totalFullPrice(): number {
        return this.fullPrice * this.quantity || 0;
    }

    /**
     * The price that is assumed to have sale discounts included.
     */
    get salePrice(): number {
        return Number(this.price);
    }

    /**
     * Total sale discounts factoring in the item quantity
     */
    get totalSaleDiscounts(): number {
        return this.saleDiscountAmount * this.quantity || 0;
    }

    /**
     * A list of rebates or an empty list.
     */
    get rebatesList(): Rebate[] {
        return (
            this.#itemRebate?.incentives || (this.rebate ? [this.rebate] : [])
        );
    }

    /**
     * Discounts including rebates the cartItem is eligible for,
     * total_additional_discounts, and sales discounts for the number of
     * items for that product in the user's cart.
     */
    get appliedDiscounts(): Discounts {
        const { additional, sales } = this.discountsWithoutRebates;
        return {
            rebates: this.totalAppliedRebateAmount,
            additional: additional * this.quantity,
            sales: sales * this.quantity,
        };
    }

    /**
     * Total of all eligible discounts added together.
     */
    get totalAppliedDiscountAmount(): number {
        return addValues<Discounts>(this.appliedDiscounts);
    }

    /**
     * Item price including quantity and factoring all eligible savings
     */
    get priceAfterSavings(): number {
        return this.totalFullPrice - this.totalAppliedDiscountAmount;
    }

    /**
     * Returns the frontend itemRebate property for a cartItem
     */
    get itemRebate() {
        return this.#itemRebate;
    }

    /**
     * Sets the frontend itemRebate field for the cartItem
     */
    set itemRebate(cartItemRebate: CartItemRebate) {
        this.#itemRebate = cartItemRebate;
    }

    get fulfillmentPartnerId(): number {
        return this.fulfillment_partner_id;
    }

    get fulfillmentPartnerName(): string {
        return this.fulfillment_partner_name;
    }

    /**
     * Gets the total cost of all the selected addons for an item
     */
    get addonsAmount(): number {
        return (
            this.addons?.reduce((acc, curr) => {
                return acc + this.addonPriceWithAccessories(curr);
            }, 0) || 0
        );
    }

    /**
     * Return cost of one addon with accessories.
     */
    addonPriceWithAccessories(addon: Addon): number {
        return (
            Number(addon.price) +
            (addon.accessories?.reduce((acc, cur) => {
                return acc + Number(cur.price);
            }, 0) || 0)
        );
    }
}

export type CartItemData = Omit<
    CartItem,
    | 'fullPrice'
    | 'salePrice'
    | 'rebatesList'
    | 'totalAppliedRebateAmount'
    | 'totalAdditionalDiscounts'
    | 'totalSaleDiscounts'
    | 'totalFullPrice'
    | 'discounts'
    | 'isFulfillable'
    | 'saleDiscountAmount'
    | 'appliedDiscounts'
    | 'totalAppliedDiscountAmount'
    | 'priceAfterSavings'
    | 'highestRebates'
    | 'discountsWithoutRebates'
    | 'isOnSale'
    | 'itemRebate'
    | 'fulfillmentPartnerId'
    | 'fulfillmentPartnerName'
    | 'addonsAmount'
    | 'addonPriceWithAccessories'
>;
