import {
    CanActivateFn,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { inject } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { map, catchError, of, Observable } from 'rxjs';
import { CookiesService } from '@enervee/webapp-common';

/**
 * This guard makes a request to get all settings and redirect all routes
 * from cartapp_blocked_routes setting to /404.
 */
export const blockUrlGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const cookiesService = inject(CookiesService);
    const params = route.queryParamMap;
    if (params.keys.length) {
        if (params.get('cartId')) {
            cookiesService.set('standalone_cart_id', params.get('cartId'));
        }
        if (params.get('currentUtility')) {
            cookiesService.set('currentUtility', params.get('currentUtility'));
        }
        return of(
            router.createUrlTree([], {
                queryParams: {
                    cartId: null,
                    currentUtility: null,
                },
                queryParamsHandling: 'merge',
            }),
        );
    }

    const settingsService = inject(SettingsService);
    return settingsService.requestSettings().pipe(
        map(() => {
            const patternFromSetting = settingsService.getSiteSetting(
                'cartapp_blocked_routes',
            ) as string[];

            const isBlocked = patternFromSetting.some((pattern) =>
                matchPattern(state.url, pattern),
            );
            return isBlocked
                ? router.createUrlTree(['/404'], {
                      queryParamsHandling: 'merge',
                  })
                : true;
        }),
        catchError(() => {
            return of(
                router.createUrlTree(['/404'], {
                    queryParamsHandling: 'merge',
                }),
            );
        }),
    );
};

/**
 * Compare url with the pattern that includes wildcard
 */
const matchPattern = (url: string, pattern: string): boolean => {
    const patternSegments = pattern.split('/');
    const urlSegments = url.split('/');
    return (
        patternSegments.length === urlSegments.length &&
        patternSegments.reduce((matched, segment, index) => {
            if (segment === '**') return matched;
            return matched && segment === urlSegments[index];
        }, true)
    );
};
