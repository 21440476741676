import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '@core/settings/settings.service';
import {
    ANALYTICS_ENDPOINT,
    ANALYTICS_ID_COOKIE,
    API_V1,
    KNOWN_BOT_USER_AGENTS,
    KNOWN_USER_IP,
    PRODUCT_API_URL_SETTING,
    SEGMENT_ORGANIZATION_NAME,
    SEGMENT_TRACKING_PRODUCT_DETAILS,
    SEGMENT_WRITE_KEY,
    TITLE,
} from '@server/constants';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import {
    defaultGetUserDetailsMessage,
    defaultGetUserStatusMessage,
    defaultIncentiveParams,
    defaultProductParams,
    defaultTrackingParams,
} from './defaultData';
import {
    DefaultTrackingParams,
    IncentiveTrackingParams,
    Product,
    ProductTrackingParams,
    Rebate,
    SegmentIdentificationData,
    TrackingParams,
    UserData,
    UserIP,
} from '@common-models';
import {
    Analytics,
    AnalyticsBrowser,
    SegmentEvent,
    User,
} from '@segment/analytics-next';
import { storageFactory, utilsFactory } from '@common-mocks';
import { GeoService } from '@core/geo/geo.service';
import { CookiesService } from '@enervee/webapp-common';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private analytics: Analytics | undefined;
    private getUserStatusMessage: string;
    private getUserDetailsMessage: string;
    private baseAnalyticsEndpoint: string;
    private baseAnalyticsApiUrl: string;
    private knownBotUserAgents: RegExp[];
    private knownUserIPs: string[];
    private organizationName: string;
    private _getUserStatus: () => BehaviorSubject<string>;
    private _getUserDetails: () => BehaviorSubject<any>;
    private productParamsForTracking: ProductTrackingParams[];
    private window: Document['defaultView'];
    private writeKey: string;
    private defaultTrackingParams: DefaultTrackingParams;
    private defaultIncentiveParams: IncentiveTrackingParams[];

    private utilService = utilsFactory();
    private storageService = storageFactory();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
        private route: ActivatedRoute,
        private injector: Injector,
        private cookiesService: CookiesService,
    ) {
        this.window = this.document.defaultView;
        this.initializeSettings();
        this.getUserStatusMessage = defaultGetUserStatusMessage;
        this.getUserDetailsMessage = defaultGetUserDetailsMessage;
        this.defaultTrackingParams = { ...defaultTrackingParams };
        this.defaultIncentiveParams = [...defaultIncentiveParams];
    }

    /**
     * Replaces the method for getting user details.
     */
    set getUserDetails(func: () => BehaviorSubject<any>) {
        this._getUserDetails = func;
    }

    get getUserDetails() {
        return this._getUserDetails || this.getUserDetailsPlaceholder;
    }

    /**
     * Replaces the method for getting user status.
     */
    set getUserStatus(func: () => BehaviorSubject<string>) {
        this._getUserStatus = func;
    }

    get getUserStatus() {
        return this._getUserStatus || this.getUserStatusPlaceholder;
    }

    /**
     * Service is not injected in the constructor in order to avoid circular
     * dependencies. All of our own services we need in this services should
     * be handled this way to avoid future circular dependency issues.
     */
    private get geoService(): GeoService {
        return this.injector.get<GeoService>(GeoService);
    }

    /**
     * Service is not injected in the constructor in order to avoid circular
     * dependencies. All of our own services we need in this services should
     * be handled this way to avoid future circular dependency issues.
     */
    private get settingsService(): SettingsService {
        return this.injector.get<SettingsService>(SettingsService);
    }

    private getUserDetailsPlaceholder(): BehaviorSubject<object> {
        console.warn(this.getUserDetailsMessage);
        return new BehaviorSubject({});
    }

    private getUserStatusPlaceholder(): BehaviorSubject<string> {
        console.warn(this.getUserStatusMessage);
        return new BehaviorSubject('Anonymous');
    }

    /**
     * Perform all settings configurations.
     */
    private initializeSettings() {
        this.baseAnalyticsEndpoint = this.settingsService.getSiteSetting(
            PRODUCT_API_URL_SETTING,
            '',
        ) as string;
        this.baseAnalyticsApiUrl =
            this.baseAnalyticsEndpoint + API_V1 + ANALYTICS_ENDPOINT;
        const knownBotUserAgentsDirty = this.settingsService.getSiteSetting(
            KNOWN_BOT_USER_AGENTS,
            [],
        ) as string[];
        this.knownBotUserAgents = this.cleanupUserAgents(
            knownBotUserAgentsDirty,
        );
        this.knownUserIPs = this.settingsService.getSiteSetting(
            KNOWN_USER_IP,
            [],
        ) as string[];
        this.organizationName = this.settingsService.getSiteSetting(
            SEGMENT_ORGANIZATION_NAME,
            '',
        ) as string;
        if (!this.organizationName) {
            this.organizationName = this.settingsService.getSiteSetting(
                TITLE,
                '',
            ) as string;
        }
        this.productParamsForTracking = this.settingsService.getSiteSetting(
            SEGMENT_TRACKING_PRODUCT_DETAILS,
            defaultProductParams,
        ) as ProductTrackingParams[];
        this.writeKey = this.settingsService.getSiteSetting(
            SEGMENT_WRITE_KEY,
            '',
        ) as string;
    }

    /**
     * Initializes segment tracking library.
     */
    initializeSegment(): void {
        if (this.analytics) {
            return;
        }
        AnalyticsBrowser.load({ writeKey: this.writeKey })
            .then(([analytics, _context]) => {
                this.analytics = analytics;
                // get user anonymous id from webapp
                const analyticsId =
                    this.cookiesService.get(ANALYTICS_ID_COOKIE);
                if (analyticsId) {
                    this.analytics.setAnonymousId(analyticsId);
                }
            })
            .catch((err) => {
                this.analytics = new Analytics({ writeKey: this.writeKey });
                this.analytics.user = () => new User({ disable: true });
            });
    }

    /**
     * Checks if the segment is disabled or if the
     * user is known bot traffic.
     */
    private checkIfAllowed(): Observable<boolean> {
        return new Observable((observer) => {
            if (this.isSegmentDisabled()) {
                return observer.next(false);
            }
            return this.isKnownBotTraffic()
                .pipe(first())
                .subscribe((knownBotTraffic: boolean) => {
                    return observer.next(!knownBotTraffic);
                });
        });
    }

    /**
     * Calls function from argument if all checks pass.
     */
    private callIfAllowed(func: () => void) {
        this.checkIfAllowed()
            .pipe(first())
            .subscribe((value: boolean) => {
                if (value) func();
            });
    }

    /**
     * Track if user is not known bot traffic.
     */
    private _track(eventName: string, properties: SegmentEvent['options']) {
        this.analytics.track(eventName, properties);
    }

    get track() {
        return (eventName: string, properties: SegmentEvent['options']) =>
            this.callIfAllowed(() => this._track(eventName, properties));
    }

    /**
     * Creates the page event data and the tracks the page with it if the user is not
     * known bot traffic.
     */
    private _trackPage() {
        // format queryParams object to match format of location.search from DOM API e.g. '?foo=bar'
        const params = new HttpParams({
            fromObject: this.route.snapshot.queryParams,
        }).toString();
        const searchParams = params ? '?' + params : params;

        const referrer = this.document.referrer || this.window.location.href;
        this.analytics.page({
            referrer,
            search: searchParams,
            type: this.utilService.getPageType(),
            category_id: this.getResolvedDataByKey('activeCategory.id'),
        });
    }

    get trackPage() {
        return () => this.callIfAllowed(() => this._trackPage());
    }

    /**
     * Gets the user details, identifies the user, and then tracks the account change event.
     */
    private _trackUser(userData: UserData, products?: Product[]) {
        this.getUserDetails()
            .pipe(
                first(),
                tap((user: any) => this.identify(user.id, userData)),
                catchError(() => {
                    this.identify(null, userData);
                    return of();
                }),
            )
            .subscribe(() => {
                this.reportAccountChange(userData, products);
            });
    }

    get trackUser() {
        return (userData: UserData, products?: Product[]) =>
            this.callIfAllowed(() => this._trackUser(userData, products));
    }

    /**
     * Report an account change event if user data exists.
     */
    private reportAccountChange(userData: UserData, products?: Product[]) {
        if (Object.keys(userData).length) {
            this.reportEvent('Account Changed', userData, products);
        }
    }

    /**
     * Track a user with anonymous user data.
     */
    // TODO: type anonymousUserData
    private _trackAnonymousUser(anonymousUserData: any) {
        const userId = anonymousUserData.userId
            ? parseInt(anonymousUserData.userId, 10)
            : null;
        return this.identify(userId, anonymousUserData);
    }

    get trackAnonymousUser() {
        return (anonymousUserData: any) =>
            this.callIfAllowed(() =>
                this._trackAnonymousUser(anonymousUserData),
            );
    }

    /**
     * Apply anonymous user id to the data if necesary and then
     * make call to identification tracking endpoint.
     */
    private _identify(userId: string | number, properties: UserData | object) {
        const data: SegmentIdentificationData = {
            type: 'identify',
            user_id: userId,
            data: properties,
            segment_anon_user_id: null,
        };

        const segment_anon_user_id = this.user.anonymousId();
        if (segment_anon_user_id !== undefined) {
            data.segment_anon_user_id = segment_anon_user_id;
        }
        return this.requestIdentification(data);
    }

    get identify() {
        return (userId: string | number, properties: any) =>
            this.callIfAllowed(() => this._identify(userId, properties));
    }

    get user(): User {
        return this.analytics.user();
    }

    /**
     * Resets the current segment user.
     */
    private _resetSegmentUser() {
        this.analytics.reset();
    }

    get resetSegmentUser() {
        return () => this.callIfAllowed(() => this._resetSegmentUser());
    }

    /**
     * Report an analytics event. If there are multiple products related to the event, they
     * are fired off as "meta" events from our backend followed by the main event.
     */
    reportEvent(
        eventName: string,
        params: TrackingParams,
        products?: Product[],
    ) {
        params.attributes = params.attributes || {};
        params.product_ids = this.utilService.reduceToProperty(products, 'id');
        if (products?.length) {
            // Add any product params that are marked to be included in the event as well as the "meta"
            params = this.createUpdatedProductTrackingParams(params, products);
        }
        params.category_id =
            params.category_id ||
            this.getResolvedDataByKey('activeCategory.id');
        this.applyDefaultParams(params, this.defaultTrackingParams)
            .pipe(first())
            .subscribe((updatedParams) => {
                if (products?.length) {
                    this.scheduleReportProducts(
                        products,
                        updatedParams._eventId,
                    );
                }
                this.track(eventName, updatedParams);
            });
    }

    /**
     * Creates params with product tracking params applied.
     */
    private createUpdatedProductTrackingParams(
        params: TrackingParams,
        products: Product[],
    ): any {
        const paramsByProduct = this.getProductParams(products);

        return this.productParamsForTracking.reduce(
            (updatedParams, trackingParam: TrackingParams) => {
                if (trackingParam.include_in_event) {
                    updatedParams[trackingParam.key] =
                        this.utilService.reduceToProperty(
                            paramsByProduct,
                            trackingParam.key,
                        );
                }
                return updatedParams;
            },
            { ...params },
        );
    }

    /**
     * Replaces user agent string with regular expressions.
     */
    private cleanupUserAgents(userAgents: string[]): RegExp[] {
        return userAgents.map((agent: string) =>
            RegExp(
                agent
                    .replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&')
                    .replace(/\*/g, '.*'),
            ),
        ) as RegExp[];
    }

    /**
     * We want to check for matches in user agents so that we can collectively
     * get every user agent that has Prerender in it. We also want to check for wild cards
     * so we don't have to add new user agents every time a browser is updated.
     */
    private checkBotAgents(currentUserAgent: string): boolean {
        return this.knownBotUserAgents.some((agent: RegExp) =>
            agent.test(currentUserAgent),
        );
    }

    /**
     * Check to see if the current user's User Agent is in the list of known
     * bot user agents or user IP in list of know IPs.
     */
    private isKnownBotTraffic(): Observable<boolean> {
        const currentUserAgent = this.window.navigator.userAgent;
        return new Observable((observer) => {
            // check if the full user agent string exists
            if (this.checkBotAgents(currentUserAgent)) {
                return observer.next(true);
            }
            return this.geoService
                .requestIpAddress()
                .pipe(first())
                .subscribe((data: UserIP) => {
                    return observer.next(
                        data ? this.knownUserIPs.includes(data.ip) : false,
                    );
                });
        });
    }

    /**
     * Post request for back-end user tracking.  Response is an
     * empty object at the time of writing this.
     */
    private requestIdentification(
        data: SegmentIdentificationData,
    ): Observable<object> {
        return this.http.post(this.baseAnalyticsApiUrl, data);
    }

    /**
     * Helper function to normalize event parameters for Segment.
     * Needed because of events previously reported through another
     * method that didn't normalize the params passed.
     * Adds values automatically set by the AnalyticsService.
     */
    private applyDefaultParams(
        params: SegmentEvent['options'],
        defaultParams: TrackingParams,
    ): Observable<SegmentEvent['options']> {
        // Flatten the object by assigning the keys of the attributes
        // property to the main object
        params = Object.assign(params, params.attributes);
        delete params.attributes;
        // Apply params argument _over_ default params
        params = { ...defaultParams, ...params };
        // Add a UUID to the event in case there are related "meta" events
        // we want to associate with it.
        params._eventId = this.utilService.getUniqueId();
        return new Observable((observer) => {
            this.getUserStatus()
                .pipe(first())
                .subscribe((userStatus: string) => {
                    // Add user status to the properties
                    params.userStatus = userStatus;
                    // Add page type to the properties
                    params.pageType = this.utilService.getPageType();
                    observer.next(params);
                });
        });
    }

    /**
     * Helper function to get data from the current state's resolve data by key.
     */
    private getResolvedDataByKey(key: string): string {
        // TODO: where does resolveData come from?  Contains the active category
        // data in webapp v1 and probably other important stuff, so we need to get
        // that from where it will be stored.
        const resolveData = {};
        // TODO: use StorageService.helpers.get when available
        return this.storageService.getStoredData(resolveData, key, {
            defaultValue: null,
        });
    }

    /**
     * Helper function to get default product info to keep things consistent. Adds
     * one or more products to params to be passed to segment.
     */
    // TODO: figure out return type based on types from storageService.getStoredData
    private getIncentiveParams(product: Product): any[] {
        const incentives = product.incentives || product.rebatesList;
        return incentives?.map((incentive: Rebate) => {
            return this.defaultIncentiveParams.reduce(
                (incentiveParams, defaultParam) => {
                    const source = defaultParam.source || defaultParam.key;
                    incentiveParams[defaultParam.key] =
                        this.storageService.getStoredData(incentive, source, {
                            defaultValue: defaultParam.default,
                        });
                    return incentiveParams;
                },
                {},
            );
        });
    }

    /**
     * Helper function to get default product info to keep things consistent. Adds
     * one or more products to params to be passed to segment.
     */
    // TODO: figure out return type based on types from storageService.getStoredData
    private getProductParams(products: Product[]): any[] {
        return products.map((product: Product) => {
            const newProductInfo = this.productParamsForTracking.reduce(
                (updatedProductParams: Product, currentParam) => {
                    const source = currentParam.source || currentParam.key;
                    updatedProductParams[currentParam.key] =
                        this.storageService.getStoredData(product, source, {
                            defaultValue: currentParam.default,
                        });
                    return updatedProductParams;
                },
                product,
            );
            newProductInfo.incentives = this.getIncentiveParams(product);
            return newProductInfo;
        });
    }

    /**
     * Reports separate "meta" events for products, each with a property for reference back
     * to a main event.
     */
    private reportProducts(products: Product[], parentEventId: string) {
        const productParams = this.getProductParams(products);
        productParams.forEach((product: Product) => {
            product._parentEventId = parentEventId;
            this.track('_metaProductEvents', product);
        });
    }

    /**
     * Determine if segment is disabled via url query param or missing library.
     */
    private isSegmentDisabled(): boolean {
        const segmentDisabled =
            this.route.snapshot.queryParamMap.get('segment_enabled') ===
            'false';
        return segmentDisabled || !this.analytics;
    }

    /**
     * Reports separate "meta" events for products, each with a property for reference back
     * to a main event.
     */
    private scheduleReportProducts(
        products: Product[],
        parentEventId: string,
    ) {
        // TODO: try to get Segment's "batch" API endpoint working so that
        // this gets handled in one request instead of 4–20.
        const firstProduct = products[0];
        if (firstProduct.rebatesLoading === true) {
            // TODO: look into alternatives to polling
            // https://gitlab.enervee.com/enervee/cart/-/merge_requests/14#note_58693
            const interval = setInterval(() => {
                if (firstProduct.rebatesLoading === false) {
                    clearInterval(interval);
                    this.reportProducts(products, parentEventId);
                }
            }, 50);
            return;
        }
        this.reportProducts(products, parentEventId);
    }
}
