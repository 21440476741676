import { cloneDeep } from 'lodash';
import { NextBestAction } from '@common-models';
import { testSiteSettingsFactory } from '@common-mocks';

const nextBestActionSetting = testSiteSettingsFactory()
    .next_best_action_order_summary_v2 as NextBestAction[];

export const testNextBestActionFactory = (
    overrides: Partial<NextBestAction[]> = [],
): NextBestAction[] => {
    return cloneDeep([...nextBestActionSetting, ...overrides]);
};
