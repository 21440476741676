import _ from 'lodash';
import { Injectable } from '@angular/core';
import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Used to replaced the provided date parser/formatter class used by ng-bootstrap.
 * See the JSDocs provide in the `NgbDateParserFormatter` class file for more details.
 */
@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
    /**
     * Converts a date string or timestamp into a valid ng-bootstrap date struct.
     * Date object's month starts at a 0 index so the plus one is to get the actual
     * month number. Removes the Z within the timestamp to avoid parsing the
     * time at UTC
     */
    parse(value: string): NgbDateStruct | null {
        if (value.includes('Z')) {
            value = value.slice(0, -1);
        }
        const date = new Date(value);
        const isValidDate = !isNaN(date.getDate());
        if (!isValidDate) {
            return null;
        }

        return {
            month: date.getMonth() + 1,
            day: date.getDate(),
            year: date.getFullYear(),
        };
    }

    /**
     * Converts an ng-bootstrap date struct to a date string.
     * eg: `obj = { month: 10, day: 31, year: 2022 }` is parsed to "10/31/2022"
     *
     * Right now it just returns an empty string if null is passed in, but that
     * probably is intended to return a local date string using the browser date api.
     */
    format(date: NgbDateStruct | null): string {
        if (!date) {
            return '';
        }
        const { month, day, year } = date;
        const paddedMonth = _.padStart(month, 2, 0);
        const paddedDay = _.padStart(day, 2, 0);
        return `${paddedMonth}/${paddedDay}/${year}`;
    }
}
