import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CartService } from '@core/cart/cart.service';
import { CmsService } from '@core/cms/cms.service';
import { GeoService } from '@core/geo/geo.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { SettingsService } from '@core/settings/settings.service';
import { OrderService } from '@core/order/order.service';
import { EventListService } from '@core/event-list/event-list.service';
import throwIfAlreadyLoaded from './import.guard';
import { SwiftypeService } from '@core/swiftype/swiftype.service';
import { AddonService } from '@core/addon/addon.service';
import { CategoriesService } from '@core/categories/categories.service';
import { ProductService } from './product/product.service';
import { RebateService } from '@core/rebate/rebate.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        CartService,
        CmsService,
        GeoService,
        LocalStorageService,
        OrderService,
        SettingsService,
        EventListService,
        SwiftypeService,
        AddonService,
        CategoriesService,
        ProductService,
        RebateService,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
