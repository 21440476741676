import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Cart, PaymentMethod } from '@common-models';
import { CartService } from '@core/cart/cart.service';
import { BehaviorSubject, combineLatestWith, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatNumber } from '@angular/common';
import { FinanceService } from '@core/finance/finance.service';
import { ScheduleWindowService } from '@core/schedule-window/schedule-window.service';
import { EventListService } from '@core/event-list/event-list.service';
import { CmsContextPaymentMethod } from '@shared-components/payment-method-section/payment-method-v2/cms-context-payment-method.model';
import { Router } from '@angular/router';
import { ShippingService } from '@core/shipping/shipping.service';

@Component({
    selector: 'payment-method-v2',
    templateUrl: './payment-method-v2.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodV2Component implements OnInit {
    @Input() paymentMethod: PaymentMethod;
    @Input() hasUnavailableProduct: boolean;

    cart$: Observable<Cart>;
    cmsContext$: Observable<CmsContextPaymentMethod>;
    isCheckoutDisabled$: Observable<boolean>;
    isNavigating$ = new BehaviorSubject<boolean>(false);

    constructor(
        private cartService: CartService,
        private eventListService: EventListService,
        private financeService: FinanceService,
        private scheduleWindowService: ScheduleWindowService,
        private router: Router,
        private shippingService: ShippingService,
    ) {}

    ngOnInit() {
        this.cart$ = this.cartService.cart$;
        this.isCheckoutDisabled$ = this.cartService.isUpdatingCart$.pipe(
            combineLatestWith(
                this.scheduleWindowService.isFetchingScheduleWindows$,
            ),
            map(
                ([isUpdatingCart, isFetchingScheduleWindow]) =>
                    isUpdatingCart || isFetchingScheduleWindow,
            ),
        );
        this.setupCmsContext();
    }

    /**
     * Payment method is for Eco Financing
     */
    get isFinance() {
        return this.paymentMethod === PaymentMethod.finance;
    }

    /**
     * Sets up the context for the cms texts
     */
    setupCmsContext(): void {
        this.cmsContext$ = this.cartService.cart$.pipe(
            map((cart: Cart) => {
                if (this.isFinance) {
                    return {
                        monthlyPayment:
                            this.financeService.calculateMonthlyPayment(
                                cart.displayTotal,
                            ),
                        monthlyLimit: this.financeService.loanDuration,
                        monthlyAPR: this.financeService.APR,
                    };
                } else {
                    return {
                        cartTotal: formatNumber(
                            cart.displayTotal,
                            'en',
                            '1.2-2',
                        ),
                    };
                }
            }),
        );
    }

    /**
     * Continues to the next payment method step
     */
    continueToPayment = (cartId: string) => {
        this.isNavigating$.next(true);
        this.eventListService.sendPaymentMethodClicked(
            this.paymentMethod,
            cartId,
        );
        if (this.paymentMethod === PaymentMethod.finance) {
            this.router.navigate([`/checkout/finance`], {
                queryParamsHandling: 'merge',
            });
        } else if (this.paymentMethod === PaymentMethod.creditCard) {
            this.shippingService.paymentMethod = PaymentMethod.creditCard;
            this.shippingService.successRedirectPath =
                '/checkout/payment/credit-card';
            this.router.navigate([`/checkout/shipping`], {
                queryParamsHandling: 'merge',
            });
        }
    };
}
