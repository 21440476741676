import { Cart, CartData, CartStatus, PaymentMethod } from '@common-models';
import { testCartItemFactory } from './cart-item';

export const testCartDetail: CartData = {
    id: 'EVl0LrVp',
    product_count: 0,
    provider_id: 0,
    initial_params: {},
    status: CartStatus.open,
    address: '',
    subtotal: 1375.99,
    shipping_total: 12.05,
    installation_total: 0,
    installation_date: '',
    is_finalized: false,
    is_financeable: false,
    tax: 102.52,
    total: 1464.56,
    cart_hash: '',
    fulfillment_partner_id: 0,
    fulfillment_partner_name: '',
    fulfillment_partner_checkout_redirect_url: '',
    payment_method: PaymentMethod.finance,
    products: [testCartItemFactory()],
    total_discount: 26,
};

export const testOrderId = '10000-000';
export const testCustomerId = '3a653541-1e1d-44d4-ae49-f2aa31490dd8';

export const testCartDetailFactory = (
    overrides: Partial<CartData> = {},
): CartData => {
    return {
        ...testCartDetail,
        ...overrides,
    };
};

export const testCartFactory = (overrides: Partial<Cart> = {}): Cart => {
    return Object.assign(new Cart(), testCartDetailFactory(overrides));
};
