import { Charge } from '@common-models';

export const baseCharge: Charge = {
    currency: 'USD',
    excluding_tax: '1249.99',
    including_tax: '1368.74',
    tax: '118.75',
};

export const testChargeFactory = (overrides: Partial<Charge> = {}): Charge => {
    return {
        ...baseCharge,
        ...overrides,
    };
};
