import { CookiesService, Times } from '@enervee/webapp-common';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import {
    Cart,
    CustomerData,
    SkepsApplication,
    SkepsEvent,
} from '@common-models';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { ScriptService } from '@core/script/script.service';
import { SettingsService } from '@core/settings/settings.service';
import {
    FINANCE_APPLICATION_ID,
    PAYMENT_FINANCE_CONFIG,
    SKEPS_SCRIPT_URL,
} from '@server/constants';
import { first, Observable, tap } from 'rxjs';
import { PaymentFinanceConfig } from 'src/app/models/payment-finance';

@Injectable({
    providedIn: 'root',
})
export class SkepsService {
    private readonly window = this.document.defaultView as typeof globalThis;

    constructor(
        private settingsService: SettingsService,
        private scriptService: ScriptService,
        private analyticsService: AnalyticsService,
        private cookiesService: CookiesService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    private get skepsMerchantId(): string {
        const paymentFinanceConfig = this.settingsService.getSiteSetting(
            PAYMENT_FINANCE_CONFIG,
            {},
        ) as PaymentFinanceConfig;

        return paymentFinanceConfig.skeps?.merchant_id;
    }

    private get skepsScriptUrl(): string {
        return this.settingsService.getSiteSetting(SKEPS_SCRIPT_URL) as string;
    }

    private get cookieExpirationConfig(): Times {
        return this.settingsService.getSiteSetting(
            'skeps_application_id_cookie_expiration',
        ) as Times;
    }

    /**
     * Fetches the applicationId from cookie.
     */
    get applicationId(): string {
        return this.cookiesService.get(FINANCE_APPLICATION_ID);
    }

    /**
     * Sets the applicationId and stores it in cookie.
     */
    private set applicationId(applicationId: string) {
        this.cookiesService.set(
            FINANCE_APPLICATION_ID,
            applicationId,
            this.cookieExpirationConfig,
        );
    }

    /**
     * Remove applicationId stored in cookie.
     */
    removeApplicationId(): void {
        this.cookiesService.delete(FINANCE_APPLICATION_ID);
    }

    private onSuccess(
        event: SkepsEvent,
        completionCallback: () => unknown,
    ): void {
        this.applicationId = event.data.orderId;
        completionCallback();
    }

    private onFailure = (
        event: SkepsEvent,
        completionCallback: () => unknown,
    ): void => {
        // TODO: Analytics APP-14217
        // We assume that if an orderId exists, then the user
        // completed the application and was denied. Otherwise
        // they closed/canceled the skeps form.
        if (event.data?.orderId) {
            this.applicationId = event.data.orderId;
        }
        completionCallback();
    };

    /**
     * Creates an application object from various data sources.
     */
    private formatApplication(
        cart: Cart,
        customer: CustomerData,
        orderId: string,
    ): SkepsApplication {
        const customerDetails: SkepsApplication['customerDetails'] = {
            firstName: customer.first_name,
            lastName: customer.last_name,
            email: customer.email,
            phoneNumber: customer.phone_number,
            streetAddress: `${customer.address_line_1} ${
                customer.address_line_2 || ''
            }`.trim(), // trim off trailing space if it exists
            city: customer.city,
            state: customer.state,
            zipcode: customer.zip_code,
        };
        return {
            mode: 'modal',
            merchantId: this.skepsMerchantId,
            cartAmount: cart.total,
            cartDetails: {
                items: cart.products.map((product) => ({
                    itemName: product.title,
                    itemValue: product.price,
                    SKU: product.id.toString(),
                })),
                shipping: cart.shipping_total,
                taxes: cart.tax,
            },
            invoiceId: orderId,
            customerDetails,
            billingDetails: customerDetails,
            shippingDetails: customerDetails,
            metadata: {
                enervee_order_id: orderId,
                cartId: cart.id,
                userId: this.analyticsService.user.id(),
                anonymousId: this.analyticsService.user.anonymousId(),
                writeKey: this.settingsService.getSiteSetting(
                    'segment_write_key',
                    '',
                ) as string,
            },
        };
    }

    /**
     * Starts the Skeps application process, by creating an application config,
     * initializing the skeps script, and then calling either a success or failure
     * callback, depending on the state of the application on close.
     */
    startApplication(
        cart: Cart,
        customer: CustomerData,
        orderId: string,
        renderer: Renderer2,
        completionCallback: () => unknown,
    ): Observable<HTMLScriptElement> {
        const applicationConfig = this.formatApplication(
            cart,
            customer,
            orderId,
        );
        return this.scriptService
            .loadScript(renderer, this.skepsScriptUrl)
            .pipe(
                first(),
                tap(() => {
                    this.window.SKEPS_FINANCING.initProcess(
                        applicationConfig,
                        {
                            onSuccess: (event: SkepsEvent) =>
                                this.onSuccess(event, completionCallback),
                            onFailure: (event: SkepsEvent) =>
                                this.onFailure(event, completionCallback),
                        },
                    );
                }),
            );
    }
}
