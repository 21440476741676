import { Component, Input, OnInit } from '@angular/core';
import { BootstrapStatus } from '@common-models';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
    @Input() cssClasses:
        | string
        | string[]
        | Set<string>
        | { [klass: string]: any } = '';
    @Input() cmsContext?: Record<string, string | number | boolean>;
    @Input() cmsKey?: string;
    @Input() display?: string;
    @Input() status = BootstrapStatus.success;

    constructor() {}

    ngOnInit(): void {}

    getAlertClasses(): Array<string> {
        const alertClasses = {
            [BootstrapStatus.success]: [
                'v2-check-circle-filled',
                'text-success',
            ],
            [BootstrapStatus.info]: ['v2-info-filled', 'text-info'],
            [BootstrapStatus.warning]: ['v2-warning-filled', 'text-warning'],
            [BootstrapStatus.danger]: ['v2-warning-filled', 'text-danger'],
        };
        return alertClasses[this.status];
    }
}
