import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from '@core/cms/cms.service';
import { TextLabelComponent } from '@shared-components/text-label/text-label.component';
import { DisplayDiscountComponent } from '@shared-components/display-discount/display-discount.component';
import { PageTitleComponent } from '@shared-components/page-title/page-title.component';
import { BreadcrumbsComponent } from '@shared-components/breadcrumbs/breadcrumbs.component';
import { AlertComponent } from '@shared-components/alert/alert.component';
import { RouterModule } from '@angular/router';
import { ZipcodeComponent } from '@shared-components/zipcode/zipcode.component';
import { ProductImageComponent } from '@shared-components/product-image/product-image.component';
import { WebappCommonModule } from '@enervee/webapp-common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditZipcodeModalComponent } from '@shared-components/zipcode/edit-zipcode-modal/edit-zipcode-modal.component';
import { ProductRowComponent } from '@shared-components/product-row/product-row.component';
import { FulfillmentPartnerComponent } from '@shared-components/fulfillment-partner/fulfillment-partner.component';
import { OnScrollIntoViewDirective } from './directives/on-scroll/on-scroll-into-view.directive';
import { OnScrollOutOfViewDirective } from './directives/on-scroll/on-scroll-out-of-view.directive';
import { ItemDetailsComponent } from '@shared-components/item-details/item-details.component';
import { AutoFillDirective } from './directives/auto-fill/auto-fill.directive';
import { AutoFormErrorComponent } from './components/auto-form-error/auto-form-error.component';
import { CartModalComponent } from './components/cart-modal/cart-modal.component';
import { TotalDetailsComponent } from '@shared-components/total-details/total-details.component';
import { ShippingOptionsComponent } from '@shared-components/item-details/shipping-options/shipping-options.component';
import { IframeTrackerDirective } from 'src/app/shared/directives/iframe/iframe-tracker.directive';
import { SafePipe } from '@shared-pipes/safe.pipe';
import { StandardTime } from '@shared-pipes/standard-time.pipe';
import { CalculateMonthlyPayment } from '@shared-pipes/calculate-monthly-payment.pipe';
import { RebateEligibilityBannerComponent } from '@shared-components/rebate-eligibility-banner/rebate-eligibility-banner.component';
import { ShippingDetailsComponent } from '@shared-components/shipping-details/shipping-details.component';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { RebateListComponent } from '@shared-components/item-details/rebate-list/rebate-list.component';
import { EcoFinancingComponent } from '@shared-components/eco-financing/eco-financing.component';
import { EcoFinancingModalComponent } from '@shared-components/eco-financing/eco-financing-modal/eco-financing-modal.component';
import { PaymentMethodComponent } from '@shared-components/payment-method/payment-method.component';
import { EventListService } from '@core/event-list/event-list.service';
import { GeneralErrorPageComponent } from '@shared-components/general-error-page/general-error-page.component';
import { PrintButtonComponent } from '@shared-components/print-button/print-button.component';
import { PrivacyDisclosureComponent } from '@shared-components/privacy-disclosure/privacy-disclosure.component';
import { NextBestActionComponent } from './components/next-best-action/next-best-action.component';
import { CustomDatePipe } from '@shared-pipes/custom-date.pipe';
import { DefaultErrorSvgComponent } from './components/general-error-page/default-error-svg/default-error-svg.component';
import { NavbarHeaderComponent } from '@shared-components/navbar-header/navbar-header.component';
import { DeliveryOptionsComponent } from '@shared-components/item-details/delivery-options/delivery-options.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AddonsComponent } from '@shared-components/addons/addons.component';
import { PaymentMethodV2Component } from '@shared-components/payment-method-section/payment-method-v2/payment-method-v2.component';
import { PaymentMethodSectionComponent } from '@shared-components/payment-method-section/payment-method-section.component';
import { FooterComponent } from '@shared-components/footer/footer.component';

/**
 * @description
 *
 * Holds all components which need to be shared between other modules.
 */
@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        RouterModule,
        WebappCommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDatepickerModule,
    ],
    exports: [
        TextLabelComponent,
        DisplayDiscountComponent,
        AlertComponent,
        BreadcrumbsComponent,
        ZipcodeComponent,
        PageTitleComponent,
        ProductImageComponent,
        ProductRowComponent,
        FulfillmentPartnerComponent,
        OnScrollIntoViewDirective,
        OnScrollOutOfViewDirective,
        ItemDetailsComponent,
        AutoFillDirective,
        AutoFormErrorComponent,
        CartModalComponent,
        TotalDetailsComponent,
        ShippingOptionsComponent,
        DeliveryOptionsComponent,
        IframeTrackerDirective,
        SafePipe,
        StandardTime,
        CalculateMonthlyPayment,
        RebateEligibilityBannerComponent,
        ShippingDetailsComponent,
        BackButtonDirective,
        RebateListComponent,
        EcoFinancingComponent,
        EcoFinancingModalComponent,
        PaymentMethodComponent,
        PaymentMethodSectionComponent,
        PaymentMethodV2Component,
        PrintButtonComponent,
        NextBestActionComponent,
        PrivacyDisclosureComponent,
        CustomDatePipe,
        DefaultErrorSvgComponent,
        NavbarHeaderComponent,
        AddonsComponent,
        FooterComponent,
    ],
    declarations: [
        TextLabelComponent,
        DisplayDiscountComponent,
        AlertComponent,
        BreadcrumbsComponent,
        ZipcodeComponent,
        PageTitleComponent,
        ProductImageComponent,
        EditZipcodeModalComponent,
        ProductRowComponent,
        FulfillmentPartnerComponent,
        OnScrollIntoViewDirective,
        OnScrollOutOfViewDirective,
        ItemDetailsComponent,
        AutoFillDirective,
        AutoFormErrorComponent,
        CartModalComponent,
        TotalDetailsComponent,
        ShippingOptionsComponent,
        DeliveryOptionsComponent,
        IframeTrackerDirective,
        SafePipe,
        StandardTime,
        CalculateMonthlyPayment,
        RebateEligibilityBannerComponent,
        ShippingDetailsComponent,
        BackButtonDirective,
        RebateListComponent,
        EcoFinancingComponent,
        EcoFinancingModalComponent,
        PaymentMethodComponent,
        PaymentMethodSectionComponent,
        PaymentMethodV2Component,
        GeneralErrorPageComponent,
        PrintButtonComponent,
        NextBestActionComponent,
        PrivacyDisclosureComponent,
        CustomDatePipe,
        DefaultErrorSvgComponent,
        NavbarHeaderComponent,
        AddonsComponent,
        FooterComponent,
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        {
            provide: 'eventListService',
            useClass: EventListService,
        },
        {
            provide: 'cmsService',
            useClass: CmsService,
        },
    ],
})
export class SharedModule {}
