import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShippingPageComponent } from './shipping-page/shipping-page.component';
import { RebatesPageComponent } from './rebates-page/rebates-page.component';
//import { AccountPageComponent } from './account-page/account-page.component';
import { CustomerDetailsPageComponent } from './customer-details-page.component';

const routes: Routes = [
    {
        path: '',
        component: CustomerDetailsPageComponent,
        children: [
            { path: '', redirectTo: 'rebates', pathMatch: 'full' },
            // Commented since not used currently but might need in future
            // { path: 'shipping', component: ShippingPageComponent },
            // { path: 'account', component: AccountPageComponent },
            { path: 'rebates', component: RebatesPageComponent },
        ],
    },
];

/**
 * @description
 *
 * Contains routing logic for views within the Add-product Page module.
 *
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CustomerDetailsPageRoutingModule {}
