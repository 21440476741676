import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FulfillmentPartners, Partner } from '@common-models';
import { EventListService } from '@core/event-list/event-list.service';

@Component({
    selector: 'fulfillment-partner',
    templateUrl: './fulfillment-partner.component.html',
    styleUrls: ['./fulfillment-partner.component.scss'],
})
export class FulfillmentPartnerComponent implements OnInit {
    @Input() fulfillmentPartner: string;
    partnerInfo: Partner;

    constructor(
        private settingsService: SettingsService,
        private eventListService: EventListService,
    ) {}

    ngOnInit(): void {
        const fulfillmentRetailers = this.settingsService.getSiteSetting(
            'commerce_fulfillment_retailers',
        ) as FulfillmentPartners;

        this.partnerInfo = fulfillmentRetailers?.[this.fulfillmentPartner];
    }

    trackReturnPolicyClicked() {
        this.eventListService.sendReturnPolicyClicked();
    }
}
