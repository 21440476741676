import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'customer-details-page',
    templateUrl: './customer-details-page.component.html',
})
export class CustomerDetailsPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
