<div *ngIf="state$ | async as viewStates">
    <page-title
        [cmsKeys]="viewStates.pageTitleTextsConfig.cmsKeys"></page-title>

    <div class="my-5 d-flex">
        <default-error-svg></default-error-svg>
    </div>
    <wa-button
        (click)="redirectTo(viewStates.buttonConfig.goTo)"
        [attr.data-eid]="
            'general-error-' + viewStates.buttonConfig.goTo + '-btn'
        ">
        <wa-cms-content
            [cmsKey]="viewStates.buttonConfig.textKey"></wa-cms-content>
    </wa-button>
</div>
