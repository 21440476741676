import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { SwiftypeService } from '../swiftype/swiftype.service';
import { CartItem, Product, Rebate } from '@common-models';
import { map, take } from 'rxjs/operators';
import { ProductService } from './product.service';
import { RebateService } from '@core/rebate/rebate.service';

@Injectable({
    providedIn: 'root',
})
export class ProductResolverService {
    constructor(
        private rebateService: RebateService,
        private swiftypeService: SwiftypeService,
        private productService: ProductService,
    ) {}

    /**
     * This uses forkJoin which allows you to run multiple observers in parallel
     * and will execute once all the observers have completed, emitting the last
     * emitted value from each observable.
     *
     * This makes simultaneous requests to get the swiftype and commerce data
     * for a product. Once both requests have completed, then it will attach
     * the commerce data to the product.
     */
    resolve(route: ActivatedRouteSnapshot): Observable<Product> | null {
        const category = route.parent.data.category;
        const categoryId = category.id.toString();
        const productId = route.paramMap.get('productId');
        const requests = [
            this.swiftypeService
                .getSerializedProduct(
                    productId,
                    categoryId,
                    category.category_code,
                )
                .pipe(take(1)),
            this.productService
                .getSerializedCommerceDataForProduct(productId, categoryId)
                .pipe(take(1)),
            this.rebateService.getRebatesForProduct(productId).pipe(take(1)),
        ];

        return forkJoin(requests).pipe(
            map(
                ([product, checkoutInfo, rebates]: [
                    Product | null,
                    CartItem | null,
                    Rebate[],
                ]): Product | null => {
                    if (!product) {
                        return null;
                    }
                    if (checkoutInfo) {
                        product.checkoutInfo = checkoutInfo;
                    }
                    if (rebates?.length) {
                        product.rebatesList = rebates;
                    }
                    return product;
                },
            ),
        );
    }
}
