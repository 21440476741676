import { CartItem, CartItemData } from '@common-models';
import { testAddonFactory } from './addon';
import { testRebateFactory } from './rebate';

export const baseCartItem: CartItemData = {
    id: 166135558,
    category_id: 94,
    title: 'ecobee EB-STATE5-01',
    model: 'ecobee SmartThermostat with Voice Control',
    quantity: 1,
    retail_price: '249.99',
    price: 223.99,
    sku: '6339170',
    rebate: testRebateFactory(),
    addons: [testAddonFactory()],
    total_additional_discounts: 10.0,
    fulfillment_partner_id: 1,
    fulfillment_partner_name: 'Best Buy',
    fulfillment_partner_code: 'best-buy',
    shipping_id: 3,
    shipping_date: '2022-03-15',
    shipping_method: 'Two Day',
    delivery_datetime: null,
    image_url:
        'https://images.enervee.com/image/upload/e_trim:6/c_limit,dpr_2.0,h_435,q_auto,w_435/f_auto/v1/products/us/img_2989537340',
};

export const testCartItemDataFactory = (
    overrides: Partial<CartItemData> = {},
): CartItemData => {
    return { ...baseCartItem, ...overrides };
};

export const testCartItemFactory = (
    overrides: Partial<CartItemData> = {},
): CartItem => {
    return Object.assign(new CartItem(), testCartItemDataFactory(overrides));
};
