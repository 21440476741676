import { NgModule } from '@angular/core';
import { AddProductPageComponent } from './add-product-page.component';
import { AddProductPageRoutingModule } from './add-product-page-routing.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-module';
import { WebappCommonModule } from '@enervee/webapp-common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerDetailsPageModule } from '../customer-details-page/customer-details-page.module';

@NgModule({
    declarations: [AddProductPageComponent],
    imports: [
        CommonModule,
        CustomerDetailsPageModule,
        AddProductPageRoutingModule,
        SharedModule,
        WebappCommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
})
export class AddProductPageModule {}
