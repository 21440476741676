import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { pull } from 'lodash';
import { Modal } from 'bootstrap';
import { PaymentMethod } from '@common-models';
import { FinanceService } from '@core/finance/finance.service';
import { SettingsService } from '@core/settings/settings.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'payment-method-section',
    templateUrl: './payment-method-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodSectionComponent implements OnInit {
    @Input() hasUnavailableProduct: boolean;

    isCartFinanceable$: Observable<boolean>;

    constructor(
        private financeService: FinanceService,
        private settingsService: SettingsService,
    ) {}

    ngOnInit() {
        this.isCartFinanceable$ = this.financeService.isCartFinanceable$;
    }

    /**
     * Get payment options from setting
     */
    get cartPaymentOptions(): PaymentMethod[] {
        return this.settingsService.getSiteSetting(
            'cart_payment_options',
        ) as PaymentMethod[];
    }

    /**
     * Context used for Eco Financing text
     */
    get financeCMSContext(): {
        monthlyLimit: number;
        monthlyAPR: number;
    } {
        return {
            monthlyLimit: this.financeService.loanDuration,
            monthlyAPR: this.financeService.APR,
        };
    }

    /**
     * Gets the available payment options based on the cart_payment_options setting
     * and if the cart is financeable
     */
    getAvailablePaymentOptions(isCartFinanceable: boolean): PaymentMethod[] {
        if (
            this.cartPaymentOptions.includes(PaymentMethod.finance) &&
            !isCartFinanceable
        ) {
            return pull(this.cartPaymentOptions, PaymentMethod.finance);
        } else {
            return this.cartPaymentOptions;
        }
    }

    /**
     * Styles the payment options based on the amount of options shown
     * and if the option is the first or second option
     */
    getPaymentMethodStyling(
        isCartFinanceable: boolean,
        index: number,
    ): string | undefined {
        if (this.getAvailablePaymentOptions(isCartFinanceable).length > 1) {
            return index === 0 ? 'border-end pe-2' : 'ps-2';
        } else {
            return undefined;
        }
    }

    /**
     * Opens the learn more Eco Financing Modal
     */
    openEcoFinancingModal(): void {
        const ecoFinancingModal = new Modal(
            document.getElementById('ecoFinancingModal'),
        );
        ecoFinancingModal.show();
    }
}
