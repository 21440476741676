<div class="mt-4 mb-4">
    <div class="large fw-bold" data-eid="page-title-title">
        <wa-cms-content
            *ngIf="cmsKeys?.title; else directTitle"
            cmsKey="{{ cmsKeys.title }}">
        </wa-cms-content>
        <ng-template #directTitle>
            <ng-container
                *ngIf="directTexts?.title as title; else contentTitle">
                {{ title }}
            </ng-container>
            <ng-template #contentTitle>
                <ng-content select="[title]"></ng-content>
            </ng-template>
        </ng-template>
    </div>

    <div class="page-description" data-eid="page-title-description">
        <wa-cms-content
            *ngIf="cmsKeys?.description; else directDescription"
            cmsKey="{{ cmsKeys.description }}">
        </wa-cms-content>
        <ng-template #directDescription>
            <ng-container
                *ngIf="
                    directTexts?.description as description;
                    else contentDescription
                ">
                {{ description }}
            </ng-container>
            <ng-template #contentDescription>
                <ng-content select="[description]"></ng-content>
            </ng-template>
        </ng-template>
    </div>
</div>
