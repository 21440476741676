<fulfillment-partner
    [fulfillmentPartner]="
        cartItem.fulfillment_partner_name
    "></fulfillment-partner>
<div
    class="container-fluid border rounded-bottom bg-white mb-4 px-4 py-4 d-grid gap-1">
    <alert
        *ngIf="isUnavailable"
        cssClasses="py-2"
        display="block"
        cmsKey="cart:product_not_available_remove"
        [status]="BootstrapStatus.danger"
        data-eid="product-not-available-remove-it"></alert>
    <!-- Product Info -->
    <product-row
        data-eid="item-details-product-row"
        [cartItem]="cartItem"
        [product]="product"
        [hasEditableQuantity]="isEditable"
        [isUpdatingCart]="isUpdatingCart">
    </product-row>
    <hr class="my-2" />
    <!-- Addons -->
    <div
        *ngIf="isFetchingAddons; else addonsTemplate"
        data-eid="item-addons-loader"
        class="spinner-border spinner-border-sm mt-0 text-muted"
        role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <ng-template #addonsTemplate>
        <addons
            *ngIf="addons"
            [cartItem]="cartItem"
            [product]="product"
            [addons]="addons"
            [isUpdatingCart]="isUpdatingCart"
            (updateItemAddons)="updateAddons($event)">
        </addons>
    </ng-template>
    <hr *ngIf="addons || isFetchingAddons" class="my-2" />
    <!-- Item Total -->
    <text-label
        data-eid="item-details-total-cost"
        [cmsKeys]="{ label: 'cart:item:total' }"
        [displayConfig]="{ isHorizontal: true, largeLineHeight: false }">
        <span value>{{ itemTotal | currency }}</span>
    </text-label>
    <!-- Schedule Windows -->
    <div
        *ngIf="isFetchingScheduleWindows"
        data-eid="shipping-options-loader"
        class="spinner-border spinner-border-sm mt-0 text-muted"
        role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <delivery-options
        *ngIf="!isFetchingScheduleWindows && isDeliverable"
        [deliveryWindows]="deliveryOptions"
        [cartItem]="cartItem"
        [isUpdatingCart]="isUpdatingCart">
    </delivery-options>
    <shipping-options
        *ngIf="
            !isFetchingScheduleWindows &&
            shippingOptions?.length &&
            isShippable
        "
        [shippingOptions]="shippingOptions"
        [cartItem]="cartItem"
        [isUpdatingCart]="isUpdatingCart">
    </shipping-options>
    <ng-container *ngIf="isLightbulbs || isWaterHeaterWarehouse">
        <alert
            class="pt-2"
            [cssClasses]="'mb-0'"
            [cmsKey]="fulfillmentPartnerKey"
            [status]="BootstrapStatus.info">
        </alert>
    </ng-container>
</div>
