<span
    *ngIf="{
        isEditable: isEditable$ | async,
        zipcode: zipcode$ | async
    } as viewStates">
    <a
        data-eid="editable-zipcode"
        role="button"
        class="ps-1 text-decoration-underline"
        *ngIf="viewStates.isEditable; else elseBlock"
        [routerLink]="[]"
        (click)="openEditZipcodeModal()">
        {{ viewStates.zipcode }}:
    </a>
    <ng-template #elseBlock>
        <span class="ps-1 pe-1" data-eid="zipcode">
            {{ viewStates.zipcode }}:
        </span>
    </ng-template>
</span>
<edit-zipcode-modal></edit-zipcode-modal>
