import { Injectable } from '@angular/core';
import {
    EcoFinancingLogo,
    EcoFinancingText,
    FinanceEligibilityParameters,
} from '@common-models';
import { SettingsService } from '@core/settings/settings.service';
import { CartService } from '@core/cart/cart.service';
import { formatNumber } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwiftypeService } from '@core/swiftype/swiftype.service';

@Injectable({
    providedIn: 'root',
})
export class FinanceService {
    private _loanDuration: number;
    private _loanRate: number;
    private _apr: number;
    private _ecoFinancingText: EcoFinancingText;
    private _ecoFinancingLogo: EcoFinancingLogo;
    private readonly financeSettings: FinanceEligibilityParameters;

    constructor(
        private settingsService: SettingsService,
        private cartService: CartService,
        private swiftypeService: SwiftypeService,
    ) {
        this.setupDefaults();
        this.financeSettings = this.settingsService.getSiteSetting(
            'finance_eligibility_parameters',
        ) as FinanceEligibilityParameters;
    }

    /**
     * Returns the ecoFinancing loan duration
     */
    get loanDuration(): number {
        return this._loanDuration;
    }

    /**
     * Sets the ecoFinancing loan duration
     */
    set loanDuration(duration: number) {
        this._loanDuration = duration;
    }

    /**
     * Returns the ecoFinancing loan APR
     */
    get loanRate(): number {
        return this._loanRate;
    }

    /**
     * Sets the ecoFinancing loan APR
     */
    set loanRate(rate: number) {
        this._loanRate = rate;
    }

    /**
     * Returns the ecoFinancing loan rate percentage
     */
    get APR(): number {
        return this._apr;
    }

    /**
     * Sets the ecoFinancing loan rate percentage
     */
    set APR(value: number) {
        this._apr = value;
    }

    /**
     * Returns the max financeable amount available
     */
    get maxFinanceableAmount(): number {
        return this.financeSettings.max_financeable_amount;
    }

    /**
     * Returns the eco financing texts
     */
    get ecoFinancingText(): EcoFinancingText {
        return this._ecoFinancingText;
    }

    /**
     * Returns the One Finance Logo details
     */
    get ecoFinancingLogo(): EcoFinancingLogo {
        return this._ecoFinancingLogo;
    }

    get isCartFinanceable$(): Observable<boolean> {
        const okStatuses = [true, null];
        return this.cartService.cart$.pipe(
            map((cart) => okStatuses.includes(cart.is_financeable)),
        );
    }

    get isFinanceEnabled(): boolean {
        return this.settingsService.isFeatureEnabled('finance');
    }

    /**
     * Sets the site settings defaults for:
     *   -One Finance duration
     *   -One Finance rate
     *   -EcoFinancingTexts
     *   -ecoFinancingLogo
     */
    setupDefaults(): void {
        this.loanDuration = this.settingsService.getSiteSetting(
            'one_finance_loan_duration',
            12,
        ) as number;
        this.loanRate = this.settingsService.getSiteSetting(
            'one_finance_loan_rate',
            0,
        ) as number;
        this.APR = this.loanRate * 100;
        this._ecoFinancingText = this.settingsService.getSiteSetting(
            'finance_texts',
        ) as EcoFinancingText;
        this._ecoFinancingLogo = this.settingsService.getSiteSetting(
            'one_finance_logo',
        ) as EcoFinancingLogo;
    }

    /**
     * Returns a monthly payment amount given a total price.
     */
    calculateMonthlyPayment(totalAmount: number, duration?: number): number {
        const paymentFactor = this.calculatePaymentFactor(
            duration || this.loanDuration,
        );
        const amount = totalAmount * paymentFactor;
        return Number(formatNumber(amount, 'en', '1.2-2'));
    }

    /**
     * Returns a total amount for all payments. Uses the displayed
     * monthly payment for consistency.
     */
    calculateTotalPayments(totalAmount: number): number {
        return (
            Number(this.calculateMonthlyPayment(totalAmount)) *
            this.loanDuration
        );
    }

    /**
     * Gathers the duration and rate for One Finance loans and calculates the
     * `paymentFactor` for use in `calculateMonthlyPayment`.
     */
    calculatePaymentFactor(duration: number): number {
        const interestDivisor = 365 / 30;
        const z = Math.pow(1 + this.loanRate / interestDivisor, duration);

        const factorNumerator = (this.loanRate / interestDivisor) * z;
        const factorDenominator = z - 1;

        return factorNumerator / factorDenominator;
    }
}
